import { UserIcon } from "@heroicons/react/24/outline";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { BreadCrumb, menuStore } from "store/menu.store";
import { useDistributor } from "api/distributor/distributor.endpoints";
import userStore, {
  getDistributorId,
  getUserId,
  getUserRole,
} from "store/user.store";

import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { DistributorAccountDetails } from "components/distributor/DistributorAccountDetails";
import { DistributorUsersTab } from "components/distributor/DistributorUsersTab";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import TabContainer from "../../../components/tab/TabContainer";

const GENERAL_TAB = "general";
const USERS_TAB = "users";

export const DistributorOrganizationPage = () => {
  const { t } = useTranslation();

  const userRole = userStore(getUserRole);
  const loggedInUserId = userStore(getUserId) || -1;
  const distributorId = userStore(getDistributorId) || -1;

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || GENERAL_TAB;

  /**
   * Breadcrumbs
   */
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs([getOrganizationBreadcrumb()]);
  }, []);

  /**
   * Get distributor
   */
  const {
    data: distributor,
    isLoading,
    isError,
  } = useDistributor(distributorId);

  if (isLoading || isError || !distributor) {
    return (
      <CustomPlaceholder
        isEmpty={!isLoading && !isError && !distributor}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`distributor.Error`)}
        emptyDataMessage={t(`distributor.Empty`)}
        loaderSize="page"
      />
    );
  }

  return (
    <TabPageWrapper
      tabs={[
        {
          param: GENERAL_TAB,
          label: t("distributor.OrganizationTitle"),
          content: (
            <TabContainer key={GENERAL_TAB}>
              <DistributorAccountDetails
                distributor={distributor}
                userRole={userRole}
              />
            </TabContainer>
          ),
          isSelected: currentTab === GENERAL_TAB,
        },
        {
          param: USERS_TAB,
          label: t("distributor.UsersTitle"),
          content: (
            <TabContainer key={USERS_TAB}>
              <DistributorUsersTab
                distributorId={distributor.id}
                userRole={userRole}
                userId={loggedInUserId}
              />
            </TabContainer>
          ),
          isSelected: currentTab === USERS_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
};

const getOrganizationBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Organization,
  to: `${RouteUrl.App}/${RouteUrl.Organization}`,
});
