import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Item, Label, List, Value } from "components/descriptionList";
import { Role } from "enums/role.enum";
import { FlowerHubStatusTag } from "components/tags/FlowerHubStatusTag";
import { getInverterTypeName } from "enums/inverterType.enum";
import { isAssetOwner, isFlower } from "../../helpers/role";
import { GetAssetResponseDTO } from "../../api/asset/asset.dto";

interface Props {
  userRole: Role;
  asset: GetAssetResponseDTO;
}

export const BatteryDetails: FC<Props> = ({ userRole, asset }) => {
  const { t } = useTranslation();

  return (
    <List>
      {isAssetOwner(userRole) && (
        <Item>
          <Label>{t(`flowerHub.Title`)}</Label>
          <FlowerHubStatusTag flowerHubStatus={asset.flowerHubStatus} />
        </Item>
      )}
      <Item>
        <Label>{t(`batteryOverview.Asset model`)}</Label>
        <Value>{`${asset.assetModel.manufacturer} ${asset.assetModel.name}`}</Value>
      </Item>
      <Item>
        <Label>{t(`batteryOverview.BESS power capacity`)}</Label>
        <Value appendix="kW">{asset.bessPowerCapacity}</Value>
      </Item>
      <Item>
        <Label>{t(`batteryOverview.Capacity`)}</Label>
        <Value appendix="kWh">{asset.bessCapacity}</Value>
      </Item>
      <Item>
        <Label>{t(`batteryOverview.InverterPowerCapacity`)}</Label>
        <Value appendix="kW">{asset.inverterPowerCapacity}</Value>
      </Item>
      <Item>
        <Label>{t(`batteryOverview.FuseSize`)}</Label>
        {asset.fuseSize != 0 ? (
          <Value appendix="A">{asset.fuseSize}</Value>
        ) : (
          <Value>-</Value>
        )}
      </Item>
      {isFlower(userRole) && (
        <Item>
          <Label>{t(`batteryOverview.InverterType`)}</Label>
          <Value>{getInverterTypeName(asset.inverterType)}</Value>
        </Item>
      )}
    </List>
  );
};
