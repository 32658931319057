import { LargeLoader } from "components/loaders/LargeLoader";
import { FC, useEffect, useState } from "react";
import { SimpleButton } from "components/buttons/SimpleButton";
import { SignOptions } from "./bankIDSteps/SignOptions";
import { AnimatedQRCode } from "./bankIDSteps/AnimatedQRCode";
import { FailedSignUp } from "./bankIDSteps/FailedSignUp";
import { useTranslation } from "react-i18next";
import {
  useInitiateBankIDSignature,
  usePollBankID,
  usePollBankIDGenerateQRCode,
} from "api/zavann/zavann.endpoints";
import useDevice from "hooks/useDevice";
import { OpenBankIdApp } from "./bankIDSteps/OpenBankIdApp";
import { AssetOwnerDetailsDTO } from "../../../api/assetOwnerInvitation/assetOwnerInvitation.dto";
import { CollectResponseDTO } from "../../../api/zavann/zavann.dto";
import { UserBrowserType } from "../../../api/assetOwnerInvitation/assetOwnerInvitation.type";

interface Props {
  assetOwnerDetails?: AssetOwnerDetailsDTO;
  passwordIsEntered: boolean;
  isCollectCompleted: boolean;
  setIsCollectCompleted: (isCollectCompleted: boolean) => void;
  setCollectResult: (collectResult: CollectResponseDTO) => void;
}

export const BankIDVerification: FC<Props> = ({
  assetOwnerDetails,
  passwordIsEntered,
  isCollectCompleted,
  setIsCollectCompleted,
  setCollectResult,
}) => {
  const { t } = useTranslation();
  const [loaderText, setLoaderText] = useState<string>("");

  /**
   * Get OrderRef from BankID
   */
  const [orderRef, setOrderRef] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [isAutoStart, setIsAutoStart] = useState<boolean>(false);
  const [isWaitingToOpenApp, setIsWaitingToOpenApp] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [retryBankIDSign, setRetryBankIDSign] = useState<boolean>(false);

  const device = useDevice();

  const { mutate: initiateBankIDSignature, data: initiateBankIDSignResponse } =
    useInitiateBankIDSignature();

  useEffect(() => {
    if (initiateBankIDSignResponse?.orderRef) {
      setOrderRef(initiateBankIDSignResponse.orderRef);
    }

    if (isAutoStart && initiateBankIDSignResponse?.autoStartToken) {
      const returnUrl = createReturnUrl(device);

      const autoStartUrl = device.isMobileOrTablet
        ? "https://app.bankid.com/"
        : "bankid:///";

      setUrl(
        `${autoStartUrl}?autostarttoken=${initiateBankIDSignResponse.autoStartToken}&redirect=${returnUrl}`,
      );
    }
  }, [initiateBankIDSignResponse]);

  /**
   * Poll BankID QR Code
   */
  const [qrCode, setQrCode] = useState<string>("");
  const [isGeneratingQrCode, setIsGeneratingQrCode] = useState<boolean>(false);

  const { data: qrCodeBankIDResponse, isFetching: isWaitingForQrCode } =
    usePollBankIDGenerateQRCode(orderRef, {
      enabled: !!orderRef && isGeneratingQrCode,
      refetchInterval: 1000,
      gcTime: 0,
    });

  useEffect(() => {
    if (qrCodeBankIDResponse) {
      setQrCode(qrCodeBankIDResponse);
    }
  }, [qrCodeBankIDResponse]);

  const signWithQrCode = () => {
    initiateBankIDSignature();

    setLoaderText(t("bankID.FetchingQRCode"));
    setIsGeneratingQrCode(true);

    setRetryBankIDSign(false);
    setIsCancelled(false);
  };

  const signOnSameDevice = () => {
    initiateBankIDSignature();

    setLoaderText(t("bankID.StartingBankIDApp"));
    setIsAutoStart(true);

    setRetryBankIDSign(false);
    setIsCancelled(false);
  };

  /**
   * Poll BankID status
   */
  const { data: collectResult } = usePollBankID(orderRef, {
    enabled: !!orderRef && !isCollectCompleted,
    refetchInterval: 1000,
    gcTime: 0,
  });

  // Check the status of the bankID signature
  useEffect(() => {
    if (!!collectResult && collectResult.status === "complete") {
      setIsGeneratingQrCode(false);
      setLoaderText(t("bankID.WaitingCollectCompleted"));
      setIsCollectCompleted(true);
      setCollectResult(collectResult);
    } else if (!!collectResult && collectResult.status === "failed") {
      setFlagsToFalsyValue();
      setRetryBankIDSign(true);
    }
  }, [collectResult]);

  useEffect(() => {
    if (retryBankIDSign || isCancelled) {
      setFlagsToFalsyValue();
    }
  }, [retryBankIDSign, isCancelled]);

  const setFlagsToFalsyValue = () => {
    setOrderRef("");
    setQrCode("");
    setUrl("");
    setIsGeneratingQrCode(false);
    setIsWaitingToOpenApp(false);
    setIsAutoStart(false);
  };
  const displayLoader =
    (isWaitingForQrCode && !qrCode) || isWaitingToOpenApp || isCollectCompleted;

  const displayCancelButton = (qrCode || url) && !isCollectCompleted;

  return (
    <div className="space-y-8 bg-white px-6 py-8">
      <div className="space-y-8 sm:space-y-5 flex flex-col items-center">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {t("bankID.SignWithBankIDTitle")}
        </h3>

        {!assetOwnerDetails || !passwordIsEntered ? (
          <FailedSignUp />
        ) : retryBankIDSign ? (
          <SignOptions
            signWithQrCode={signWithQrCode}
            signOnSameDevice={signOnSameDevice}
            url={url}
            text={t("bankID.RetrySignature")}
          />
        ) : displayLoader ? (
          <div className="flex flex-col items-center">
            <LargeLoader />
            <p className="text-sm text-gray-500 mt-4">{loaderText}</p>
          </div>
        ) : url ? (
          <OpenBankIdApp
            url={url}
            setIsWaitingToOpenApp={() => setIsWaitingToOpenApp(true)}
          />
        ) : qrCode ? (
          <AnimatedQRCode qrCode={qrCode} />
        ) : (
          <SignOptions
            signWithQrCode={signWithQrCode}
            signOnSameDevice={signOnSameDevice}
            url={url}
            text={t("bankID.SignWithBankID")}
          />
        )}

        {displayCancelButton && (
          <div className="sm:col-span-1 sm:mt-0">
            <SimpleButton
              onClick={() => setIsCancelled(true)}
              text={t("Back")}
              style={"secondary"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Define url to redirect from BankID to Flower Hub portal
 */
export const createReturnUrl = (device: UserBrowserType) => {
  // IOS
  if (device.isChromeOnAppleDevice) {
    return encodeURIComponent("googlechrome://");
  }
  if (device.isFirefoxOnAppleDevice) {
    return encodeURIComponent("firefox://");
  }
  if (device.isOperaTouchOnAppleDevice) {
    return encodeURIComponent(
      `${window.location.href.replace("http", "touch-http")}`,
    );
  }

  // Safari: redirect manually from BankID to Flower Hub portal

  // Android
  return null;
};
