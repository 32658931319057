import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AssetInformation } from "./AssetInformationStep";
import { Fuse } from "../bessTypes";
import InverterIcon from "styles/icons/InverterIcon";
import FlowerHubIcon from "styles/icons/FlowerHubIcon";
import HomeIcon from "styles/icons/HomeIcon";
import { ContentCard } from "../ContentCard";
import { ActionButton } from "../../buttons/ActionButton";
import BatteryIcon from "styles/icons/BatteryIcon";

type MetaDataConfirmationProps = {
  changeStep: (step: number) => void;
  assetInformation: AssetInformation;
  assetSerialNumber?: string;
  fuse?: Fuse;
};

export const MetaDataConfirmationStep: FC<MetaDataConfirmationProps> = ({
  changeStep,
  assetInformation,
  assetSerialNumber,
  fuse,
}) => {
  const { t } = useTranslation();
  const bessModelNames = assetInformation.assetModel?.label.split("/") ?? [];

  return (
    <div className="space-y-8">
      <h2 className="text-xl font-bold text-center leading-tight mt-6">
        {t("batteryOverview.One last check we're almost done!")}
      </h2>
      <p className="text-base text-gray-800 text-center">
        {t(
          "batteryOverview.Please review the details you ve entered carefully as you will not be able to make any changes after this step",
        )}
      </p>
      <div className="flex justify-center">
        <div className="max-w-4xl divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 mb-4">
          <ContentCard
            title={t("batteryOverview.Serial number")}
            icon={<FlowerHubIcon color={"#1d4fd8"} />}
            color="blue"
            content={<p className="mt-2 text-sm">{assetSerialNumber}</p>}
          />
          <ContentCard
            title={t("batteryOverview.FuseSize")}
            icon={<HomeIcon color={"#a16107"} />}
            color="yellow"
            content={<p className="mt-2 text-sm">{`${fuse?.ampere} A`}</p>}
          />
          <ContentCard
            title={t("batteryOverview.Inverter")}
            color="green"
            icon={<InverterIcon color="#15803c" />}
            content={
              <div className="grid grid-cols-1 gap-2">
                <DetailItem
                  label={t("assetModel.Model")}
                  value={`${assetInformation.manufacturer?.label} ${bessModelNames[0]}`}
                />
                <DetailItem
                  label={t("batteryOverview.Power capacity")}
                  value={`${assetInformation.inverterPowerCapacity} kw`}
                />
              </div>
            }
          />
          <ContentCard
            title={t("batteryOverview.Battery")}
            icon={<BatteryIcon color="#4438ca" />}
            color="indigo"
            content={
              <div className="grid grid-cols-1 gap-2">
                <DetailItem
                  label={t("assetModel.Model")}
                  value={`${bessModelNames[1]}`}
                />
                <DetailItem
                  label={t("batteryOverview.Energy capacity")}
                  value={`${assetInformation.configuration?.ratedEnergy} kwh`}
                />
                <DetailItem
                  label={t("batteryOverview.Power capacity")}
                  value={`${assetInformation.configuration?.ratedPower} kw`}
                />
              </div>
            }
          />
        </div>
      </div>

      <div className="flex justify-between w-full">
        <ActionButton
          onClick={() => changeStep(3)}
          label={t("Back")}
          variant="black"
        />
        <ActionButton
          onClick={() => changeStep(5)}
          label={t("All details are correct!")}
          variant="primary"
        />
      </div>
    </div>
  );
};

const DetailItem: FC<{ label: string; value: string }> = ({ label, value }) => (
  <p className="text-sm">
    <span className="text-gray-500">{`${label}:`}</span>
    <span>{` ${value}`}</span>
  </p>
);
