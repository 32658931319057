// Fetcher function that implements pagination to handle server-side data fetching
import api from "../axiosInstance";
import qs from "qs";

interface Props {
  url: string;
  pageParam: number;
  queryString?: string; // Query string that is used to search/order/filter data
  extraQueryString?: string; // Query string that is used to filter data by other parameters, e.g. IDs
}

export const fetchPages = async ({
  url,
  pageParam,
  queryString = "",
  extraQueryString = "",
}: Props) => {
  const parsedQueryString = qs.parse(queryString);
  const parsedExtraQueryString = qs.parse(extraQueryString);
  const paginationQueryString = qs.stringify(
    {
      page: pageParam,
      ...parsedQueryString,
      ...parsedExtraQueryString,
    },
    { arrayFormat: "repeat" },
  );
  return await api.get(`${url}?${paginationQueryString}`);
};
