import { useAssetsPagination } from "api/asset/asset.endpoints";

import CustomPlaceholder from "components/CustomPlaceholder";
import { ServerIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAssetRoute } from "../../helpers/assetOwner";
import { FlowerHubTable } from "./FlowerHubTable";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { GetAllAssetResponseDTO } from "../../api/asset/asset.dto";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { FlowerHubList } from "./FlowerHubList";
import {
  FilterDTO,
  FilterWithLabelDTO,
} from "../../api/pagination/pagination.type";

export const FlowerHubs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pagination query string state is defined to store the query string in the parent component
  const [queryString, setQueryString] = useState<string>("");

  // Infinite query to get asset owners with pagination
  const paginationQuery: UseInfiniteQueryResult<
    InfiniteData<GetAllAssetResponseDTO>
  > = useAssetsPagination(queryString);

  // Extract relevant information for this component from the pagination query
  const { isLoading, isError } = paginationQuery;

  const onAssetClick = (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => {
    navigate(getAssetRoute(assetOwnerId, installerId, distributorId));
  };

  // Convert the filter options to the enum values for the installer can invite filter
  const createColumnFilter = (filters: FilterDTO[]): FilterWithLabelDTO[] =>
    filters.map((filter) => {
      return {
        id: filter.id,
        options: filter.options.map((option) => {
          return {
            label: option,
            value: option,
          };
        }),
      };
    });

  if (isLoading || isError) {
    return (
      <div className="flex h-full items-center lg:items-start lg:pt-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError}
          icon={<ServerIcon />}
          errorMessage={t(`flowerHub.UnableToLoadFlowerHubs`)}
          emptyDataMessage={t(`flowerHub.NoFlowerHubs`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {isMobile ? (
        <FlowerHubList
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetClick={onAssetClick}
          createColumnFilter={createColumnFilter}
        />
      ) : (
        <FlowerHubTable
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetClick={onAssetClick}
          createColumnFilter={createColumnFilter}
        />
      )}
    </div>
  );
};
