import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n/i18n";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// This redacts a string after a certain amount of characters, which would hopefully hide secret codes from sentry
const redactLongUrl = (url?: string) => {
  if (url) {
    const replaceAfterUrlLength = 60; // A little longer than on the server side since this includes base url
    return (
      url.substring(0, replaceAfterUrlLength) +
      "*".repeat(Math.max(url.length - replaceAfterUrlLength, 0))
    );
  } else {
    return url;
  }
};

Sentry.init({
  // Does not need to be secret, see https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/#dsn-utilization
  dsn: "https://1b7317b3ffa4c53744d7deae2bd92308@o4506541719945216.ingest.sentry.io/4506671149613056",
  environment: import.meta.env.VITE_NODE_ENV,
  tracePropagationTargets: ["localhost", import.meta.env.VITE_API_URL],
  integrations: [
    // Let's add this if it becomes relevant.
    /*replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    })*/
  ],
  //replaysSessionSampleRate: 0.1, // Comment in if replays are used
  //replaysOnErrorSampleRate: 1.0, // Comment in if replays are used
  tracesSampleRate: 0.2, // Logging only 20% of traces (for performance) as this counts towards our bill
  beforeSend(event) {
    if (event.request?.url) {
      event.request.url = redactLongUrl(event.request.url);
    }
    if (event.user?.ip_address) {
      event.user.ip_address = "REDACTED";
    }
    if (event.breadcrumbs) {
      event.breadcrumbs?.map((breadcrumb) => {
        if (breadcrumb.data?.url) {
          breadcrumb.data.url = redactLongUrl(breadcrumb.data.url);
        }
        return breadcrumb;
      });
    }
    return event;
  },
});

const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
      <Toaster position="top-right" toastOptions={{ duration: 3000 }} />
    </QueryClientProvider>
  </React.StrictMode>,
);
