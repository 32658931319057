import {
  clearRefreshTokenExpirationDate,
  refreshTokenHasExpired,
} from "store/refreshToken.store";
import userStore from "store/user.store";

export const useIsLoggedIn = () => {
  const { user, clearUser } = userStore();
  if (!user) {
    clearRefreshTokenExpirationDate();
    return false;
  } else if (refreshTokenHasExpired()) {
    clearUser();
    clearRefreshTokenExpirationDate();
    return false;
  }
  return true;
};
