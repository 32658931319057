import { FC } from "react";
import { Badge, BadgeColor, BadgeSize } from "./Badge";
import { FlowerHubSystemManagerStatus } from "enums/flowerHubSystemManager.enum";
import { useTranslation } from "react-i18next";

interface Props {
  status: FlowerHubSystemManagerStatus;
  size?: BadgeSize;
}

export const SystemManagerStatusTag: FC<Props> = ({
  status,
  size = "small",
}) => {
  const { t } = useTranslation();
  return (
    <Badge
      color={getColor(status)}
      text={t([
        `flowerHubSystemManager.status.${status}`,
        "flowerHubSystemManager.status.FallbackStatus",
      ])}
      size={size}
    />
  );
};

const getColor = (status: FlowerHubSystemManagerStatus): BadgeColor => {
  switch (status) {
    case FlowerHubSystemManagerStatus.Online:
      return "green";
    case FlowerHubSystemManagerStatus.Inactive:
      return "red";
    case FlowerHubSystemManagerStatus.ConnectionLost:
      return "yellow";
    default:
      return "gray";
  }
};
