import React from "react";
import { StepStatus } from "enums/flowerHubStatus.enum";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "helpers/date";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "helpers/classNames";

export interface Step {
  name: string;
  status: StepStatus;
  isErrored?: boolean;
  completedAt?: Date | null;
}

interface StepItemProps {
  step: Step;
  isLastStep: boolean;
  stepIdx: number;
  isErrored?: boolean;
}

export const StepItem: React.FC<StepItemProps> = ({
  step,
  isLastStep,
  stepIdx,
  isErrored = false,
}) => {
  return (
    <li className="relative lg:flex lg:flex-1" key={stepIdx}>
      <div className="group flex items-center">
        <StepIndicator
          status={step.status}
          stepIdx={stepIdx}
          isErrored={isErrored}
        />
        <StepContent
          name={step.name}
          status={step.status}
          completedAt={step.completedAt}
          isErrored={isErrored}
        />
      </div>
      {!isLastStep && <StepConnector />}
    </li>
  );
};

interface StepIndicatorProps {
  status: StepStatus;
  stepIdx: number;
  isErrored: boolean;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  status,
  stepIdx,
  isErrored,
}) => {
  if (status === StepStatus.Complete) {
    return (
      <span className="flex items-center pl-1 py-4 text-sm font-medium">
        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-500">
          <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
        </span>
      </span>
    );
  }

  if (status === StepStatus.Current) {
    return (
      <span className="flex items-center pl-1 py-4 text-sm font-medium">
        <span
          className={classNames(
            isErrored ? "bg-red-500 border-none" : "border-flowerSkyVivid-500",
            "flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2",
          )}
        >
          {isErrored ? (
            <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
          ) : (
            <span className="text-flowerSkyVivid-500">{stepIdx}</span>
          )}
        </span>
      </span>
    );
  }

  return (
    <span className="flex items-center pl-1 py-4 text-sm font-medium">
      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
        <span className="text-gray-500 group-hover:text-gray-900">
          {stepIdx}
        </span>
      </span>
    </span>
  );
};

interface StepContentProps {
  name: string;
  status: StepStatus;
  completedAt?: Date | null;
  isErrored: boolean;
}

const StepContent: React.FC<StepContentProps> = ({
  name,
  status,
  completedAt,
  isErrored,
}) => {
  const { t } = useTranslation();
  const textColor =
    status === StepStatus.Complete
      ? "text-gray-900"
      : status === StepStatus.Current
        ? isErrored
          ? "text-red-600"
          : "text-flowerSkyVivid-500"
        : "text-gray-500 group-hover:text-gray-900";

  return (
    <span className={`ml-2 text-sm font-medium ${textColor}`}>
      {t(`flowerHubProgress.installationProgressSteps.${name}`)}
      {completedAt && (
        <span className="block text-xs text-gray-400">
          {formatDateTime(completedAt)}
        </span>
      )}
    </span>
  );
};

const StepConnector: React.FC = () => {
  return (
    <div
      aria-hidden="true"
      className="absolute right-0 top-0 invisible lg:visible h-full w-8 md:block"
    >
      <svg
        fill="none"
        viewBox="0 0 22 80"
        preserveAspectRatio="none"
        className="h-full w-full text-gray-300"
      >
        <path
          d="M0 -2L20 40L0 82"
          stroke="currentColor"
          vectorEffect="non-scaling-stroke"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
