import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FilterMenu } from "./FilterMenu";
import { SearchField } from "./SearchField";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { ToolBar } from "./Toolbar";
import { THead } from "./THead";
import { TableStyle } from "./TableStyle";
import { TBody } from "./TBody";
import { Title } from "./Title";
import qs from "qs";
import { LoadingToaster } from "./LoadingToaster";
import { useFetchMoreOnBottomReached } from "../../hooks/useFetchMoreOnBottomReached";
import { useAutoScrollbarWidth } from "../../hooks/useAutoScrollbarWidth";
import useDebounce from "../../hooks/useDebounce";

interface Props<T extends object> {
  paginationQuery: InfiniteQueryResult<T>;
  columns: ColumnDef<T>[];
  onSelectRow?: (data: T) => void;
  columnVisibility?: Record<string, boolean>;
  setPaginationUrl: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
  searchPlaceholder?: string;
  hasToolbar?: boolean;
  offSetToTriggerPagination?: number;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const Table = <T extends { id?: number }>({
  paginationQuery,
  columns,
  onSelectRow,
  columnVisibility,
  setPaginationUrl,
  title = "",
  searchPlaceholder = "",
  hasToolbar = true,
  offSetToTriggerPagination = 300,
  createColumnFilter,
}: Props<T>): React.ReactElement => {
  // State used to store the sorting state of the table
  const [sorting, setSorting] = useState<SortingState>([]);

  // Convert sorting to a string for debouncing
  const debouncedSortingString = useDebounce(JSON.stringify(sorting), 1000);

  // Parse the debounced string back to an array
  const debouncedSorting = JSON.parse(debouncedSortingString);

  // State used to store the visibility of the loading toast when fetching more data
  const [showLoadingToast, setShowLoadingToast] = useState<boolean>(false);

  // Reference to the container of the table's body element for the infinite scrolling logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Destructure the data from the paginationQuery to have relevant data for the table
  const { data, isFetchingNextPage, isRefetching } = paginationQuery;

  // Flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.items) ?? [],
    [data],
  );

  // Getting updated filters, if any, from the server. Checked only first page, because filters are static (same for each page)
  const filters = useMemo(() => data?.pages?.[0]?.filters ?? [], [data]);

  // Called on scroll to fetch more pages as the user scrolls and reaches bottom of the list body
  useFetchMoreOnBottomReached(
    paginationQuery,
    offSetToTriggerPagination,
    setShowLoadingToast,
    tableContainerRef,
  );

  const scrollbarWidth = useAutoScrollbarWidth(tableContainerRef);

  // Update pagination url when sorting changes
  useEffect(() => {
    setPaginationUrl((queryString) => {
      const parsedQueryString = qs.parse(queryString);
      return qs.stringify(
        {
          ...parsedQueryString,
          sortId:
            debouncedSorting.length > 0 ? debouncedSorting[0].id : undefined,
          sortOrder:
            debouncedSorting.length > 0
              ? debouncedSorting[0].desc
                ? "desc"
                : "asc"
              : undefined,
        },
        { arrayFormat: "repeat" },
      );
    });
  }, [debouncedSorting, setPaginationUrl]);

  // Set up table props
  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    manualPagination: true,
    manualFiltering: true,
    debugTable: false,
  });

  // Scroll to the top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0);
    }
  };

  // Since this table option is derived from table row model state, we use the table.setOptions utility
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: (updater) => {
      if (isRefetching) return;
      handleSortingChange(updater);
    },
  }));

  const { rows } = table.getRowModel();

  // Virtualizer for the table rows to improve performance
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48,
    getScrollElement: () => tableContainerRef.current,
    overscan: 5, // number of rows to render above and below the visible area
  });

  return (
    <div className="w-full p-4 rounded-2xl flex flex-col bg-white h-max-full min-h-12 shadow">
      {(title || hasToolbar) && (
        <div className="flex-shrink-0 h-8 justify-between items-center flex mb-4">
          <Title title={title} />
          {hasToolbar && (
            <ToolBar>
              <SearchField
                placeholder={searchPlaceholder}
                setPaginationUrl={setPaginationUrl}
              />
              {filters.length !== 0 && (
                <FilterMenu
                  filters={createColumnFilter(filters)}
                  setPaginationUrl={setPaginationUrl}
                />
              )}
            </ToolBar>
          )}
        </div>
      )}
      <div className="flex-grow overflow-hidden bg-white w-full rounded-lg">
        <TableStyle>
          <THead table={table} scrollbarWidth={scrollbarWidth} />
          <TBody
            rowVirtualizer={rowVirtualizer}
            rows={rows}
            tableContainerRef={tableContainerRef}
            onSelectRow={onSelectRow}
            isRefetching={isRefetching}
          />
          {isFetchingNextPage && showLoadingToast && <LoadingToaster />}
        </TableStyle>
      </div>
    </div>
  );
};
