import { Item, Label, List, Value } from "components/descriptionList";
import React, { FC } from "react";
import { FlowerLink } from "../FlowerLink";
import { RouteUrl } from "routing/routeUrl.enum";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useAssetRevenue } from "api/asset/asset.endpoints";
import CustomPlaceholder from "../CustomPlaceholder";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { Shadow } from "../Shadow";

interface Props {
  assetId?: number;
  bessCompensationStartDate?: string;
  showLink?: boolean;
}

export const FlowerRevenue: FC<Props> = ({
  assetId,
  bessCompensationStartDate,
  showLink = true,
}) => {
  const { t } = useTranslation();

  // Get asset
  const { data: asset, isLoading, isError } = useAssetRevenue(assetId);

  const compensationStartText = bessCompensationStartDate
    ? new Date(bessCompensationStartDate).toLocaleString("default", {
        year: "numeric",
        month: "long",
      })
    : t("flowerRevenue.Start date unavailable");

  const renderFlowerRevenuePlaceholder = () => {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !asset}
          icon={<DocumentTextIcon />}
          errorMessage={t(`graph.error`)}
          emptyDataMessage={t(`header.noFlowerHub`)}
          loaderSize="medium"
        />
      </div>
    );
  };

  if (isLoading || !asset || isError) {
    return renderFlowerRevenuePlaceholder();
  }

  return (
    <div className="overflow-auto">
      <Shadow>
        <List>
          <Item>
            <Label>
              {t("flowerRevenue.Model compensation")} (
              {asset.assetModel.manufacturer} {asset.assetModel.name})
            </Label>
            <Value appendix="SEK/kW/month">
              {asset.assetModel.compensationPerKW}
            </Value>
          </Item>
          <Item>
            <Label>
              {t("flowerRevenue.Available power")}{" "}
              <div className="group relative inline">
                <InformationCircleIcon
                  className="inline ml-1 text-gray-500 hover:text-gray-400"
                  width={20}
                  height={20}
                />
                <span className="sm:w-52 w-36 z-50 absolute top-5 scale-0 rounded bg-gray-600 p-2 text-sm font-normal text-white group-hover:scale-100">
                  {t("flowerRevenue.Power and energy capacity difference")}
                </span>
              </div>
            </Label>
            <Value appendix="kW">{asset.minAvailablePower}</Value>
          </Item>
          <Item>
            <Label>{t("flowerRevenue.Monthly revenue")}</Label>
            <Value appendix="SEK/month">{asset.compensation} </Value>
          </Item>
          <Item>
            <Label>
              {t("flowerRevenue.Starting from")}{" "}
              <div className="group relative inline">
                <InformationCircleIcon
                  className="inline ml-1 text-gray-500 hover:text-gray-400"
                  width={20}
                  height={20}
                />
                <span className="sm:w-52 w-36 absolute top-5 scale-0 rounded bg-gray-600 p-2 text-sm font-normal text-white group-hover:scale-100">
                  {t("flowerRevenue.Date from which Flower Hub is installed")}
                </span>
              </div>
            </Label>
            <Value>{compensationStartText}</Value>
          </Item>
        </List>
      </Shadow>

      <div className="sm:grid sm:grid-cols-1 sm:gap-4 px-2 md:px-6 mb-6 md:mt-8 mt-10 text-sm font-normal text-gray-500">
        <div className="my-3 sm:my-0">
          {t(
            "flowerRevenue.The revenue for a certain month is part of the invoice for that month",
          )}
        </div>
        <div className="my-3 sm:my-0">
          {t("flowerRevenue.An invoice is sent out in the following month")}
        </div>
        {showLink && (
          <div className="my-3 sm:my-0">
            {t("flowerRevenue.Your can find your invoices in the")}{" "}
            <FlowerLink to={`${RouteUrl.App}/${RouteUrl.InvoiceList}`}>
              {t("flowerRevenue.invoices tab")}
            </FlowerLink>
          </div>
        )}
      </div>
    </div>
  );
};
