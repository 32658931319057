import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateAssetModelRequestDTO,
  GetAssetModelItemResponseDTO,
  GetAssetModelResponseDTO,
} from "./assetModel.dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ENDPOINT = "/asset-model";

export enum AssetModelQueryKeys {
  GetAll = "asset-model-get-all",
}

export const useAssetModels = () =>
  useQuery({
    queryKey: [AssetModelQueryKeys.GetAll],
    queryFn: async (): Promise<GetAssetModelItemResponseDTO[]> =>
      (await api.get(ENDPOINT)).data,
  });

export const useCreateAssetModel = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateAssetModelRequestDTO,
    ): Promise<GetAssetModelResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AssetModelQueryKeys.GetAll] });
      notification.info(`Asset created`);
    },
    onError: () => {
      notification.error();
    },
    ...options,
  });
};

export const useUpdateAssetModel = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      payload: GetAssetModelItemResponseDTO,
    ): Promise<GetAssetModelResponseDTO> =>
      (await api.put(`${ENDPOINT}/${payload.id}`, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AssetModelQueryKeys.GetAll] });
      notification.info(`Changes saved`);
    },
    onError: () => {
      notification.error();
    },
  });
};

export const useDeleteAssetModel = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AssetModelQueryKeys.GetAll] });
      notification.info(`Asset model deleted`);
    },
    onError: () => {
      notification.error("Something went wrong, please try again");
    },
    ...settings,
  });
};
