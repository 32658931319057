import { OperationalStatusDTO } from "api/flowerHub/flowerHub.dto";
import { Badge } from "components/tags/Badge";
import { Role } from "enums/role.enum";
import { isFlower } from "helpers/role";
import { FC } from "react";
import OperationalStatistics from "./OperationalStatistics";
import { Panel } from "components/Panel";
import { useTranslation } from "react-i18next";
import OperationStatusGrid from "./OperationStatusGrid";
import { operationStatusMapping } from "helpers/operationalProgress";

type Prop = {
  operationalStatus: OperationalStatusDTO;
  serialNumber: string;
  userRole: Role;
};

const OperationalProgress: FC<Prop> = ({
  operationalStatus,
  serialNumber,
  userRole,
}) => {
  const { t } = useTranslation();
  const {
    leftAnimationType,
    rightAnimationType,
    leftText,
    rightText,
    leftActionText,
    rightActionText,
  } = operationStatusMapping[operationalStatus.status];

  return (
    <Panel
      header={t(`flowerHubProgress.Device status`)}
      infoText={t(`flowerHubProgress.Device status info`)}
    >
      <div className="bg-white p-4 w-full rounded-md">
        <OperationalStatistics
          serialNumber={serialNumber}
          operationalStatus={operationalStatus}
          userRole={userRole}
        />
        <OperationStatusGrid
          leftAnimationType={leftAnimationType}
          rightAnimationType={rightAnimationType}
          leftText={t(`${leftText}`)}
          rightText={t(`${rightText}`)}
          leftActionText={leftActionText}
          rightActionText={rightActionText}
        />
        {isFlower(userRole) && (
          <div className="flex flex-col justify-center items-center">
            <Badge color="gray" text={operationalStatus.status} />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default OperationalProgress;
