import api from "../axiosInstance";
import {
  GetAssetOwnerInvitationResponseDTO,
  ResendAssetOwnerInvitationRequestDTO,
  SignupFlowByCodeRequestDTO,
} from "./assetOwnerInvitation.dto";
import { useNotification } from "hooks/useNotification";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import {
  UpdateAssetOwnerInvitationRequestDTO,
  UpdateAssetOwnerInvitationResponseDTO,
} from "api/assetOwner/assetOwner.dto";
import { ErrorResponse } from "types/errorResponse.type";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const ENDPOINT = "/asset-owner-invitation";

export enum AssetOwnerInvitationQueryKeys {
  GetOne = "asset-owner-invitation-get-one",
}

export const useAssetOwnerSignUp = () => {
  return useMutation({
    mutationFn: async (payload: SignupFlowByCodeRequestDTO) => {
      (await api.post(`${ENDPOINT}/signup-flow-by-code`, payload)).data;
    },
  });
};

export const useAssetOwnerInvitation = (code: string, enabled: boolean) =>
  useQuery({
    queryKey: [AssetOwnerInvitationQueryKeys.GetOne],
    queryFn: async (): Promise<GetAssetOwnerInvitationResponseDTO> =>
      (await api.get(`${ENDPOINT}/${code}`)).data,
    enabled,
  });

export const useResendInvitation = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      payload: ResendAssetOwnerInvitationRequestDTO,
    ): Promise<void> =>
      await api.put(`${ENDPOINT}/${payload.oldInvitationId}/resend`, {
        installerId: payload.installerId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AssetOwnerQueryKeys.GetOne] });
      notification.info("Invitation resent, invalidated old one");
    },
    onError: () => {
      notification.error();
    },
    ...settings,
  });
};

export const useUpdateAssetOwnerInvitation = (oldInvitationId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      payload: UpdateAssetOwnerInvitationRequestDTO,
    ): Promise<UpdateAssetOwnerInvitationResponseDTO> =>
      (await api.put(`${ENDPOINT}/${oldInvitationId}`, payload)).data,
    onSuccess: (data: UpdateAssetOwnerInvitationResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetProfile, data.id],
      });
      notification.info(`${data.name} updated`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};
