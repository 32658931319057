import { FC } from "react";
import { menuStore } from "store/menu.store";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { getBreadCrumbIcon } from "./AppWrapper.helpers";
import { isMobile } from "react-device-detect";

export const Header: FC = () => {
  const { breadCrumbs } = menuStore();
  if (breadCrumbs.length === 1) return null;
  return (
    <header className="flex h-12 md:h-14 items-center mr-auto max-w-7xl w-full py-2 md:py-6 px-4 md:px-8">
      {/* Breadcrumbs */}
      <nav className="flex sm:text-xs w-full" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4 w-full">
          {breadCrumbs.map((breadCrumb, index) => (
            <li key={breadCrumb.to} className="!ml-0">
              <div
                className={
                  index > 0 ? "flex items-center !ml-1 md:!ml-4" : "flex"
                }
              >
                {index > 0 && (
                  <ChevronRightIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <Link
                  to={breadCrumb.to}
                  className={
                    index > 0
                      ? "ml-1 md:ml-4 text-xs md:text-sm font-medium text-gray-400 hover:text-gray-700 flex items-center"
                      : "text-xs md:text-sm font-medium text-gray-400 hover:text-gray-700 flex items-center"
                  }
                >
                  <>
                    {!!breadCrumb.iconType &&
                      !isMobile &&
                      getBreadCrumbIcon(breadCrumb.iconType)}
                    {breadCrumb.label}
                  </>
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </header>
  );
};
