import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { UserIcon } from "@heroicons/react/20/solid";
import { useInstallerUsers } from "api/installer/installer.endpoints";
import { inviteHasExpired } from "helpers/validation";
import { useTranslation } from "react-i18next";
import { Badge, BadgeColor } from "components/tags/Badge";
import { AddInstallerUser } from "components/installer/AddInstallerUser";
import { DeleteInstallerInvitation } from "./DeleteInstallerInvitation";
import { DeleteInstallerUser } from "./DeleteInstallerUser";
import CustomPlaceholder from "components/CustomPlaceholder";
import { ResendInstallerInvitation } from "./ResendInstallerInvitation";
import { Role } from "enums/role.enum";
import { InstallerUserDTO } from "../../api/installer/installer.dto";
import {
  getRoleName,
  isDistributor,
  isFlower,
  isInstaller,
} from "../../helpers/role";
import { InstallerInvitationDTO } from "../../api/installerInvitation/installerInvitation.dto";
import { EditInstallerInvitation } from "./EditInstallerInvitation";
import { EditInstallerUser } from "./EditInstallerUser";

interface Props {
  installerId: number;
  userRole: Role;
  userId?: number;
  className?: string;
}

export const InstallerUsersTab: FC<Props> = ({
  installerId,
  userRole,
  userId,
  className = "",
}) => {
  const { t } = useTranslation();

  const {
    data: useInstallerUsersResponse,
    isLoading,
    isError,
  } = useInstallerUsers(installerId);

  if (
    isLoading ||
    isError ||
    !useInstallerUsersResponse?.users ||
    !useInstallerUsersResponse.invitations
  ) {
    return (
      <CustomPlaceholder
        isEmpty={
          !isLoading &&
          !isError &&
          !useInstallerUsersResponse?.users &&
          !useInstallerUsersResponse?.invitations
        }
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`installer.Error`)}
        emptyDataMessage={t(`installer.EmptyUser`)}
        loaderSize="page"
      />
    );
  }

  const { users, invitations } = useInstallerUsersResponse;

  const badgeColor = (date: Date): BadgeColor => {
    return inviteHasExpired(date) ? "yellow" : "blue";
  };

  const badgeText = (date: Date): string => {
    return inviteHasExpired(date)
      ? t(`badgeAccountStatus.Invitation expired`)
      : t(`badgeAccountStatus.Invited`);
  };

  return (
    <div className={className}>
      <div className="mb-4">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`installer.Email`)}</TH>
              <TH>{t(`installer.Name`)}</TH>
              <TH>{t(`installer.Account type`)}</TH>
              <TH>{t(`installer.Status`)}</TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {users.map(({ id, email, role, name }: InstallerUserDTO) => (
              <TR key={email}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>{getRoleName(role)}</TD>
                <TD>
                  <Badge
                    color="green"
                    text={t(`badgeAccountStatus.Account created`)}
                  />
                </TD>
                <TD alignRight>
                  {isFlower(userRole) && (
                    <EditInstallerUser
                      installerUser={{ id, name, email, role }}
                    />
                  )}
                  {userId != id && isInstaller(userRole) && (
                    <DeleteInstallerUser
                      installerId={installerId}
                      userId={id}
                    />
                  )}
                </TD>
              </TR>
            ))}
            {invitations.map(
              ({
                id,
                email,
                role,
                name,
                expiryDate,
              }: InstallerInvitationDTO) => (
                <TR key={id}>
                  <TD addLeftMargin>{email}</TD>
                  <TD>{name}</TD>
                  <TD>{getRoleName(role)}</TD>
                  <TD>
                    <div className="flex">
                      <Badge
                        color={badgeColor(expiryDate)}
                        text={badgeText(expiryDate)}
                      />
                    </div>
                  </TD>
                  <TD>
                    <div className="flex items-center justify-end gap-5 pr-3">
                      {(isFlower(userRole) ||
                        isDistributor(userRole) ||
                        isInstaller(userRole)) && (
                        <ResendInstallerInvitation
                          installerId={installerId}
                          oldInvitationId={id}
                        />
                      )}
                      {(isFlower(userRole) ||
                        (isDistributor(userRole) &&
                          !inviteHasExpired(expiryDate))) && (
                        <EditInstallerInvitation
                          installerInvitation={{
                            id,
                            email,
                            role,
                            name,
                            expiryDate,
                          }}
                        />
                      )}
                      {(isFlower(userRole) ||
                        isDistributor(userRole) ||
                        isInstaller(userRole)) && (
                        <DeleteInstallerInvitation
                          installerId={installerId}
                          invitationId={id}
                        />
                      )}
                    </div>
                  </TD>
                </TR>
              ),
            )}
          </TBody>
        </Table>
      </div>

      {isInstaller(userRole) && <AddInstallerUser installerId={installerId} />}
    </div>
  );
};
