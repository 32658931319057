import React from "react";
import { Cell, flexRender } from "@tanstack/react-table";

interface Props<T> {
  cell: Cell<T, unknown>;
}

export const TD = <T,>({ cell }: Props<T>): React.ReactElement => (
  <div
    key={cell.id}
    className="truncate px-2 flex flex-1"
    style={{ width: cell.column.getSize() }}
  >
    <span className="truncate">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </span>
  </div>
);
