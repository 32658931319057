import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import SecondaryButton from "components/buttons/SecondaryButton";
import { DeleteFlowerUserConfirmation } from "./DeleteFlowerUserConfirmation";
import { useDeleteFlowerInvitation } from "api/flowerInvitation/flowerInvitation.endpoints";

interface Props {
  invitationId: number;
}

export const DeleteFlowerUserInvitation: FC<Props> = ({ invitationId }) => {
  const { t } = useTranslation();

  const [isDeleteInvitationModalOpen, setIsInvitationModalOpen] =
    useState(false);

  // api
  const { mutate: deleteInvitation, isSuccess } = useDeleteFlowerInvitation();

  // submit
  const onDeleteInvitation = () => deleteInvitation(invitationId);

  // modal close
  const onDeleteInvitationModalClose = () => {
    setIsInvitationModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteInvitationModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <SecondaryButton
        size="small"
        onClick={() => setIsInvitationModalOpen(true)}
      >
        {t(`flowerUser.Delete invitation`)}
      </SecondaryButton>

      <Modal
        title={t(`flowerUser.Delete invitation`)}
        leftButtonAction={onDeleteInvitationModalClose}
        leftButtonText={t(`Close`)}
        rightButtonActionHandler={() => onDeleteInvitation()}
        isOpen={isDeleteInvitationModalOpen}
        onClose={onDeleteInvitationModalClose}
        loadingText={t(`flowerUser.Deleting invitation`)}
      >
        <DeleteFlowerUserConfirmation />
      </Modal>
    </>
  );
};
