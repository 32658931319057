import { OperationalStatusDTO } from "api/flowerHub/flowerHub.dto";
import { Role } from "enums/role.enum";
import { classNames } from "helpers/classNames";
import { getOperationalStatus } from "helpers/operationalProgress";
import { isFlower } from "helpers/role";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type Prop = {
  operationalStatus: OperationalStatusDTO;
  serialNumber: string;
  userRole: Role;
};

const OperationalStatistics: FC<Prop> = ({
  operationalStatus,
  serialNumber,
  userRole,
}) => {
  const { t } = useTranslation();
  const { status, color } = getOperationalStatus(operationalStatus.status);

  let stats = [
    { name: t(`batteryOverview.Serial number`), stat: serialNumber },
    {
      name: t(`flowerHubProgress.operationalStats.Dongle Ip address`),
      stat: operationalStatus.dongleIp ?? "-",
    },
    {
      name: t(`flowerHubProgress.operationalStats.Flower Hub Ip address`),
      stat: operationalStatus.flowerHubIp ?? "-",
    },
    {
      name: t(`flowerHubProgress.operationalStats.Status`),
      stat: status,
      color,
    },
  ];

  // Remove the first item (serialNumber) if the user is a flower user
  if (isFlower(userRole)) {
    stats = stats.slice(1);
  }

  return (
    <dl
      className={classNames(
        isFlower(userRole) ? "sm:grid-cols-3" : "sm:grid-cols-4",
        "mt-2 grid grid-cols-1 gap-5",
      )}
    >
      {stats.map((item) => (
        <div
          key={item.name}
          className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
        >
          <dt className="truncate text-sm text-xs lg:text-sm font-medium text-gray-500">
            {item.name}
          </dt>
          <dd
            className={classNames(
              item?.color ? `text-${item.color}-500` : "text-gray-900",
              "mt-1 text-l lg:text-xl tracking-tight",
            )}
          >
            {item.stat}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default OperationalStatistics;
