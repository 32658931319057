/* cspell:disable-next-line */
import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getDistributorId, getUserRole } from "store/user.store";
import { Installers } from "../../../components/installer/Installers";
import { SmoothUp } from "../../../components/animations/SnapUp";

export const InstallerListPage = () => {
  const userRole = userStore(getUserRole);
  const distributorId = userStore(getDistributorId);

  // breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs([getInstallerListBreadcrumb()]);
  }, []);

  return (
    <SmoothUp>
      <Installers userRole={userRole} distributorId={distributorId} />
    </SmoothUp>
  );
};

export const getInstallerListBreadcrumb = (): BreadCrumb => ({
  label: RouteLabel.Installers,
  to: `${RouteUrl.App}/${RouteUrl.Installers}`,
});
