import React from "react";
import { HeaderGroup } from "@tanstack/react-table";
import { TH } from "./TH";

interface Props<T> {
  headerGroup: HeaderGroup<T>;
}

export const TRH = <T,>({ headerGroup }: Props<T>): React.ReactElement => {
  return (
    <div className="flex w-full h-11 justify-start items-center">
      {headerGroup.headers.map((header) => (
        <TH key={header.id} header={header} />
      ))}
    </div>
  );
};
