import { useNotification } from "hooks/useNotification";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../axiosInstance";
import {
  CreateDistributorRequestDTO,
  CreateDistributorResponseDTO,
  GetDistributorItemResponseDTO,
  GetDistributorResponseDTO,
  GetDistributorUsersResponseDTO,
  GetLatestInstallersItemResponseDTO,
  UpdateDistributorRequestDTO,
  UpdateDistributorResponseDTO,
} from "./distributor.dto";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "/distributor";

export enum DistributorQueryKeys {
  GetOne = "distributor-get-one",
  GetAll = "distributor-get-all",
  GetUsers = "distributor-get-users",
  GetLatestInstallers = "distributor-get-latest-installers",
}

export const useDistributors = () =>
  useQuery({
    queryKey: [DistributorQueryKeys.GetAll],
    queryFn: async (): Promise<GetDistributorItemResponseDTO[]> =>
      (await api.get(ENDPOINT)).data,
  });

export const useDistributorUsers = (id: number) =>
  useQuery({
    queryKey: [DistributorQueryKeys.GetUsers, id],
    queryFn: async (): Promise<GetDistributorUsersResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/user`)).data,
    initialData: { users: [], invitations: [] },
  });

export const useGetLatestInstallers = (distributorId?: number) => {
  return useQuery({
    queryKey: [DistributorQueryKeys.GetLatestInstallers, distributorId],
    queryFn: async (): Promise<GetLatestInstallersItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/${distributorId}/dashboard/latest-installer`))
        .data,
    enabled: !!distributorId,
  });
};

export const useDistributor = (id: number) =>
  useQuery({
    queryKey: [DistributorQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetDistributorResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
  });

export const useCreateDistributor = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateDistributorRequestDTO,
    ): Promise<CreateDistributorResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [DistributorQueryKeys.GetAll],
      });
      notification.info(`${data.name} created`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useUpdateDistributor = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateDistributorRequestDTO,
    ): Promise<UpdateDistributorResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [DistributorQueryKeys.GetOne, data.id],
      });
      notification.info(`${data.name} updated`);
    },
    onError: () => {
      notification.error();
    },
  });
};
