import { FC } from "react";
import { SimpleButton } from "components/buttons/SimpleButton";

type Props = {
  onBackButtonClicked: () => void;
  onNextButtonClicked: () => void;
};

export const TermsAndConditions: FC<Props> = ({
  onBackButtonClicked,
  onNextButtonClicked,
}) => {
  return (
    <form className="space-y-8 sm:w-fit bg-white px-6 py-8">
      <div className="space-y-8 sm:space-y-5 sm:grid sm:grid-cols-6 sm:gap-2">
        <div className="space-y-6 sm:space-y-5 sm:col-span-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Villkor för Flower Hub
          </h3>
          <ul className="mt-1 max-w-2xl text-sm text-gray-600 leading-6 list-disc px-4 space-y-3">
            <li>
              Flower Hub ska vara uppkopplad med ström och internet så som
              installatören installerade den
            </li>
            <li>
              Du behöver behålla vårt elavtal för att fortsätta få intäkt från
              Flower Hub
            </li>
            <li>
              Flower Hub kommer att generera en intäkt till dig varje månad som
              dras av på din elfaktura (enligt prislista)
            </li>
          </ul>
          <p className="mt-1 max-w-2xl text-sm text-gray-600 leading-6">
            Genom att gå vidare till nästa steg accepterar du Flower Hubs{" "}
            <a
              href="https://flowerhub.se/anvandarvillkor"
              className="text-sm font-medium text-flowerSky-800 hover:text-flowerSky-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              användarvillkor
            </a>
            .
          </p>
        </div>
        <div className="sm:col-span-1 sm:mt-0">
          <SimpleButton
            onClick={onBackButtonClicked}
            text={"Tillbaka"}
            style={"secondary"}
          />
        </div>
        <div className="sm:col-span-5 sm:mt-0">
          <SimpleButton
            onClick={onNextButtonClicked}
            text={"Jag godkänner villkoren"}
            style={"primary"}
          />
        </div>
      </div>
    </form>
  );
};
