import { FC } from "react";
import { FlowerHubLogo } from "styles/Icons";

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
  headerText: string;
  buttonText: string;
  buttonTextLoading?: string;
  onActionClick: () => void;
  hideAction?: boolean;
}

export const AuthFlowWrapper: FC<Props> = ({
  children,
  isLoading = false,
  headerText,
  buttonText,
  buttonTextLoading,
  onActionClick,
  hideAction = false,
}) => (
  <>
    <div className="min-h-full flex flex-col justify-center  justify-center py-12 px-6 md:px-8 gap-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="h-16 w-auto h-auto mx-auto" src={FlowerHubLogo} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {headerText}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <>{children}</>

            {!hideAction && (
              <div>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowerSky-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-flowerSky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowerSky-300 sm:col-start-2 sm:text-sm"
                  onClick={onActionClick}
                >
                  {isLoading && (
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {isLoading && !!buttonTextLoading
                    ? buttonTextLoading
                    : buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);
