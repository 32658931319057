import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
interface Props {
  label: string;
  name: string;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any;
  errorMessage?: string;
  autoFocus?: boolean;
  placeholder?: string;
}
export const TextArea: FC<Props> = ({
  label,
  name,
  register,
  validation,
  errorMessage,
  autoFocus = false,
  placeholder,
}) => {
  const hasError = !!errorMessage;
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
              : "border-gray-300 focus:border-flowerblue-500 focus:ring-flowerblue-500",
          )}
          autoComplete="off"
          {...register(name, validation && validation)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          rows={3}
        />
      </div>
      {hasError && (
        <div className="flex flex-row items-center mt-2">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <p className="ml-1 text-sm text-red-600">{errorMessage}</p>
        </div>
      )}
    </div>
  );
};
