import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
}

export const DashboardGreetingHeader: FC<Props> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <div className="md:flex md:items-center md:justify-between mb-4 md:mb-6 lg:md-8 lg:mt-4">
      <div className="min-w-0 flex-1">
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold  sm:truncate leading-7 sm:tracking-tight after:content-['👋']">
          {t(`greetingHeader`, { name })}
        </h2>
        <h4 className="text-base mt-3 font-medium leading-7 sm:truncate sm:tracking-tight text-flowerGray-400">
          {t(`greetingSubHeader`)}
        </h4>
      </div>
    </div>
  );
};
