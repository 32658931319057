import { FC } from "react";
import { UserDetailsForm } from "pages/sign-up-flow/components/UserDetailsForm";
import { LargeLoader } from "components/loaders/LargeLoader";
import { useTranslation } from "react-i18next";
import {
  AssetOwnerDetailsDTO,
  GetAssetOwnerInvitationResponseDTO,
} from "../../../api/assetOwnerInvitation/assetOwnerInvitation.dto";

type Props = {
  invitation: GetAssetOwnerInvitationResponseDTO;
  assetOwnerDetails: AssetOwnerDetailsDTO;
  onBackButtonPressed: () => void;
  onUserDetailsFormCompleted: (assetOwnerDetails: AssetOwnerDetailsDTO) => void;
};

export const UserDetails: FC<Props> = ({
  invitation,
  assetOwnerDetails,
  onBackButtonPressed,
  onUserDetailsFormCompleted,
}) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-6 sm:space-y-5 sm:pb-8 sm:px-6">
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t("signUpFlow.Your personal details")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-red-600">
            {t(
              "signUpFlow.Important! Note that these details must agree with those on the household's current electricity contract.",
            )}
          </p>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {t(
              "signUpFlow.We never disclose your data to third parties without your consent. Read more about our privacy policy",
            )}{" "}
            <a
              className="text-sm font-medium text-flowerSky-800 hover:text-flowerSky-500"
              href="https://flowerhub.se/integritetspolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("signUpFlow.Here")}
            </a>
            .
          </p>
        </div>
        <div className="space-y-8 sm:space-y-5">
          {invitation && (
            <UserDetailsForm
              invitation={invitation}
              assetOwnerDetails={assetOwnerDetails}
              onBackButtonPressed={onBackButtonPressed}
              onUserDetailsFormCompleted={onUserDetailsFormCompleted}
            />
          )}
          {!invitation && <LargeLoader />}
        </div>
      </div>
    </div>
  );
};
