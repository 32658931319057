import { FC, useState } from "react";
import Modal from "components/Modal";
import { GetAssetModelItemResponseDTO } from "api/assetModel/assetModel.dto";
import { useDeleteAssetModel } from "api/assetModel/assetModel.endpoints";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ModalContentWrapper } from "components/ModalContentWrapper";

interface Props {
  assetModel: GetAssetModelItemResponseDTO;
}

export const DeleteAssetModel: FC<Props> = ({ assetModel }) => {
  const { t } = useTranslation();

  // state
  const [isDeleteAssetModelModalOpen, setIsDeleteAssetModelModalOpen] =
    useState<boolean>(false);

  // api
  const { mutate: deleteAssetModel } = useDeleteAssetModel();

  // submit
  const onDeleteAssetModel = (id: number) => {
    deleteAssetModel(id);
  };

  // modal close
  const onDeleteAssetModelModalClose = () => {
    setIsDeleteAssetModelModalOpen(false);
  };

  const renderDeleteAssetModelButton = () => {
    return (
      <TrashIcon
        className="h-5 w-5 ml-6 text-flowerRed-800 hover:text-flowerRed-500 cursor-pointer"
        aria-hidden="true"
        onClick={() => setIsDeleteAssetModelModalOpen(true)}
      />
    );
  };

  return (
    <>
      {assetModel.safeToRemove && renderDeleteAssetModelButton()}

      <Modal
        isOpen={!!isDeleteAssetModelModalOpen}
        onClose={onDeleteAssetModelModalClose}
        title={t(`assetModel.Delete asset model`)}
        rightButtonActionHandler={() => onDeleteAssetModel(assetModel.id)}
        loadingText={t(`assetModel.Deleting`)}
        leftButtonAction={onDeleteAssetModelModalClose}
        leftButtonText={t(`Close`)}
      >
        <ModalContentWrapper>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t(`assetModel.Are you sure that you want to delete`)}{" "}
              {assetModel.name} {assetModel.manufacturer}?
            </p>
          </div>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};
