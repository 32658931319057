import { getInverterTypeName, InverterType } from "enums/inverterType.enum";
import { AssetInformation } from "components/installationFlow/steps/AssetInformationStep";
import { Fuse } from "./bessTypes";

export enum AssetStep {
  CONNECT_ETH = 1,
  ASSET_INFORMATION = 2,
  SERIAL_NUMBER = 3,
  META_DATA_CONFIRMATION = 4,
  POWER_CONFIRMATION = 5,
  FINISHING = 6,
}

export const isAssetInformationComplete = (
  assetInformation: AssetInformation,
  fuse?: Fuse,
) => {
  return (
    assetInformation.manufacturer &&
    assetInformation.assetModel &&
    assetInformation.configuration &&
    assetInformation.inverterPowerCapacity &&
    fuse?.ampere
  );
};

export const INVERTER_TYPES = [
  { value: InverterType.Undefined, label: "Choose inverter type" },
  {
    value: InverterType.Standard,
    label: getInverterTypeName(InverterType.Standard),
  },
  {
    value: InverterType.Hybrid,
    label: getInverterTypeName(InverterType.Hybrid),
  },
];

export const isSerialNumberComplete = (
  addAssetSerialNumber: string | undefined,
) => !!addAssetSerialNumber && addAssetSerialNumber.length === 7;
