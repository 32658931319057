import React, { FC } from "react";
import { GetFlowerHubResponseDTO } from "api/flowerHub/flowerHub.dto";
import {
  FlowerHubInstallationStatusMessageCode,
  StepStatus,
} from "enums/flowerHubStatus.enum";
import { Step, StepItem } from "./installationStep/StepItem";
import {
  getCurrentStepIndex,
  isInstallationFailureMessageCode,
} from "helpers/installationProgress";
import { Badge } from "components/tags/Badge";
import { useTranslation } from "react-i18next";
import { classNames } from "helpers/classNames";
import { Panel } from "components/Panel";

type Prop = {
  flowerHub: GetFlowerHubResponseDTO;
  isDistributor?: boolean;
};

// Translations for these names using in the StepContent component
const stepsConfig: Step[] = [
  { name: "Installation start", status: StepStatus.Complete },
  { name: "Device handshake", status: StepStatus.Upcoming },
  { name: "Create cloud resources", status: StepStatus.Upcoming },
  { name: "Create device resources", status: StepStatus.Upcoming },
  { name: "Software deployment", status: StepStatus.Upcoming },
];

const getCompletedAtDate = (
  index: number,
  flowerHub: GetFlowerHubResponseDTO,
): Date | null => {
  switch (index) {
    case 0:
      return new Date(flowerHub.installerEntryCreatedAt);
    case 1:
      return flowerHub.certificateProvisionedAt
        ? new Date(flowerHub.certificateProvisionedAt)
        : null;
    case 2:
      return flowerHub.stackCreatedAt
        ? new Date(flowerHub.stackCreatedAt)
        : null;
    case 3:
      return flowerHub.coreDeviceCreatedAt
        ? new Date(flowerHub.coreDeviceCreatedAt)
        : null;
    case 4:
      return flowerHub.firstDeploymentCompletedAt
        ? new Date(flowerHub.firstDeploymentCompletedAt)
        : null;
    default:
      return null;
  }
};

const mapSteps = (
  steps: Step[],
  flowerHub: GetFlowerHubResponseDTO,
  currentStepIndex: number,
): Step[] =>
  steps.map((step, index) => {
    const completedAt = getCompletedAtDate(index, flowerHub);
    const isErrored = isInstallationFailureMessageCode(
      flowerHub.installationStatus,
    );

    if (index < currentStepIndex) {
      return { ...step, status: StepStatus.Complete, completedAt, isErrored };
    } else if (index === currentStepIndex) {
      return { ...step, status: StepStatus.Current, isErrored };
    } else {
      return { ...step, status: StepStatus.Upcoming, isErrored };
    }
  });

export const ProgressMessages: FC<{
  message: FlowerHubInstallationStatusMessageCode;
  isDistributor: boolean;
}> = ({ message, isDistributor }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row w-full mt-4 gap-4">
      <div className="bg-flowerSky-50 p-4 w-full md:w-1/2 rounded-lg">
        <span className="block text-sm font-semibold text-gray-700">
          {t(
            isDistributor
              ? "flowerHubProgress.Distributor message"
              : "flowerHubProgress.Flower user message",
          )}
        </span>
        <span
          className={classNames(
            message ===
              FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub
              ? "whitespace-pre-line"
              : "",
            "flex text-xs md:text-sm leading-6 mt-2 text-gray-700",
          )}
        >
          {isDistributor
            ? t([
                `flowerHubProgress.distributorInstallationProgressMessage.${message}`,
                "flowerHubProgress.distributorInstallationProgressMessage.FallbackMessage",
              ])
            : t([
                `flowerHubProgress.flowerUserInstallationProgressMessage.${message}`,
                "flowerHubProgress.flowerUserInstallationProgressMessage.FallbackMessage",
              ])}
        </span>
      </div>
      <div className="bg-gray-50 p-4 w-full md:w-1/2 rounded-lg">
        <span className="block text-sm font-semibold text-gray-700">
          {t("flowerHubProgress.Installer message")}
        </span>
        <span
          className={classNames(
            message ===
              FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub
              ? "whitespace-pre-line"
              : "",
            "flex text-xs md:text-sm leading-6 mt-2 text-gray-700",
          )}
        >
          {t([
            `flowerHubProgress.installerInstallationProgressMessageForFlowerUserView.${message}`,
            "flowerHubProgress.installerInstallationProgressMessageForFlowerUserView.FallbackMessage",
          ])}
        </span>
      </div>
    </div>
  );
};

const InstallationProgressFlowerVersion: FC<Prop> = ({
  flowerHub,
  isDistributor = false,
}) => {
  const { t } = useTranslation();
  const currentStepIndex = getCurrentStepIndex(
    flowerHub.installationStatus,
    flowerHub?.firstDeploymentCompletedAt,
  );

  const updatedSteps = mapSteps(stepsConfig, flowerHub, currentStepIndex);

  return (
    <Panel
      header={t(`flowerHubProgress.Installation progress`)}
      infoText={t(`flowerHubProgress.Installation progress info`)}
    >
      <div className="bg-white p-0 lg:pt-6 w-full rounded-md">
        <nav className="pl-2 md:pl-4 lg:pl-0" aria-label="Progress">
          <ol role="list" className="overflow-hidden lg:flex mb-2 md:mb-4">
            {updatedSteps.map((step, index) => (
              <StepItem
                key={step.name}
                step={step}
                isLastStep={index === updatedSteps.length - 1}
                stepIdx={index + 1}
                isErrored={step.isErrored}
              />
            ))}
          </ol>
        </nav>

        {!flowerHub.firstDeploymentCompletedAt && (
          <div className="flex flex-col justify-center items-center mt-4 lg:mt-8 w-full">
            {!isDistributor && (
              <Badge color="gray" text={flowerHub.installationStatus} />
            )}
            <ProgressMessages
              message={flowerHub.installationStatus}
              isDistributor={isDistributor}
            />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default InstallationProgressFlowerVersion;
