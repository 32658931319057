export enum SignUpStep {
  Landing,
  SamePersonWarning,
  UserDetails,
  ElectricityContract,
  Terms,
  ChoosePassword,
  BankID,
  Final,
}
