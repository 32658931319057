import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { InstallerUserDTO } from "api/installer/installer.dto";
import { EditInstallerUserForm } from "./EditInstallerUserForm";
import { useUpdateInstallerUser } from "api/user/user.endpoints";
import { UpdateInstallerUserRequestDTO } from "api/user/user.dto";

interface Props {
  installerUser: InstallerUserDTO;
}

export const EditInstallerUser: FC<Props> = ({ installerUser }) => {
  const { t } = useTranslation();

  const [isEditInstallerModalOpen, setIsEditInstallerModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateUser,
    isPending: isUpdating,
    isSuccess,
  } = useUpdateInstallerUser(installerUser.id);

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateInstallerUserRequestDTO>();

  // submit
  const onSubmit = handleSubmit((data: UpdateInstallerUserRequestDTO) => {
    updateUser({
      ...data,
      role: Number(data.role),
    });
  });

  // modal close
  const onEditInstallerModalClose = () => {
    setTimeout(() => reset(), 300);
    setIsEditInstallerModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onEditInstallerModalClose();
    }
  }, [isSuccess]);

  // Populate or clear the form every time isEditInstallerModalOpen is set
  useEffect(() => {
    if (isEditInstallerModalOpen) {
      reset(installerUser);
    }
  }, [isEditInstallerModalOpen]);

  const renderEditInstallerButton = () => {
    return (
      <div className="py-5 flex justify-end">
        <PencilSquareIcon
          className="h-5 w-5 ml-1 text-gray-700 hover:text-flowerPeach-900 cursor-pointer"
          onClick={() => setIsEditInstallerModalOpen(true)}
        />
      </div>
    );
  };

  return (
    <>
      {renderEditInstallerButton()}

      <Modal
        isOpen={isEditInstallerModalOpen}
        rightButtonActionHandler={onSubmit}
        title={t(`installer.EditInstaller`)}
        rightButtonText={t(`Save`)}
        onClose={onEditInstallerModalClose}
        isLoading={isUpdating}
      >
        <EditInstallerUserForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
