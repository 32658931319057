import { FC } from "react";
import { classNames } from "helpers/classNames";
import CustomPlaceholder from "components/CustomPlaceholder";
import { Panel } from "components/Panel";
import { SystemManagerStatusTag } from "../../tags/SystemManagerStatusTag";
import { CurrentFlowerHubSystemManagerForAssetResponse } from "api/flowerHubSystemManager/flowerHubSystemManager.dto";
import { useFlowerHubSystemManagerLogsForAsset } from "api/flowerHubSystemManager/flowerHubSystemManager.endpoints";
import { useTranslation } from "react-i18next";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

interface Props {
  assetId: number;
}

export const SystemManagerLogs: FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { data, isError, isLoading } =
    useFlowerHubSystemManagerLogsForAsset(assetId);

  return (
    <Panel
      header={t(`flowerHubSystemManagerLogs.Title`)}
      infoText={t(`flowerHubSystemManagerLogs.Info`)}
    >
      <div className="flow-root w-full pl-3 pr-3 overflow-y-auto">
        {isLoading || data?.length === 0 || isError ? (
          <CustomPlaceholder
            isError={isError}
            isLoading={isLoading}
            isEmpty={!isLoading && !isError && data?.length === 0}
            icon={<ClipboardDocumentListIcon />}
            errorMessage={t(`flowerHubSystemManagerLogs.UnableToLoad`)}
            emptyDataMessage={t(`flowerHubSystemManagerLogs.NotFound`)}
          />
        ) : (
          <ul role="list" className="max-h-80 -mb-8">
            {data?.map((record, i) => <LogItem record={record} key={i} />)}
          </ul>
        )}
      </div>
    </Panel>
  );
};

interface LogItemProps {
  record: CurrentFlowerHubSystemManagerForAssetResponse;
}

const LogItem: FC<LogItemProps> = ({ record }) => (
  <div className="relative mb-8">
    <div className="relative flex space-x-3">
      <div>
        <span
          className={classNames(
            "h-8 w-40 rounded-full flex items-center ring-8 ring-white capitalize",
          )}
        >
          <SystemManagerStatusTag status={record.status} />
        </span>
      </div>
      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
        <div>
          <p className="text-sm text-gray-500">{record.ipAddress}</p>
        </div>
        <div className="whitespace-nowrap text-right text-sm text-gray-400">
          <time>{new Date(record.reportedAt).toLocaleString()}</time>
        </div>
      </div>
    </div>
  </div>
);
