import {
  AssetOwnerDetailsDTO,
  GetAssetOwnerInvitationResponseDTO,
} from "./assetOwnerInvitation.dto";

// Used for someone else than the person in the invitation
export const getAddressOnlyAssetDetailsFromInvitation = (
  invitation: GetAssetOwnerInvitationResponseDTO,
): AssetOwnerDetailsDTO => ({
  firstName: "",
  lastName: "",
  personalNumber: "",
  address: invitation.address.street,
  postalCode: invitation.address.postalCode,
  city: invitation.address.city,
  phone: "",
  contactEmail: "",
});

export const getEmptyAssetOwnerDetails = (): AssetOwnerDetailsDTO => ({
  firstName: "",
  lastName: "",
  personalNumber: "",
  address: "",
  postalCode: "",
  city: "",
  phone: "",
  contactEmail: "",
});

// Used if the invitation is the same person that is on the electricity contract
export const getFullAssetDetailsFromInvitation = (
  invitation: GetAssetOwnerInvitationResponseDTO,
): AssetOwnerDetailsDTO => {
  return {
    firstName: invitation.firstName,
    lastName: invitation.lastName,
    personalNumber: "",
    address: invitation.address.street,
    postalCode: invitation.address.postalCode,
    city: invitation.address.city,
    phone: "",
    contactEmail: invitation.email,
  };
};
