import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Badge } from "components/tags/Badge";
import { useDistributorUsers } from "api/distributor/distributor.endpoints";
import { UserIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { Role } from "enums/role.enum";
import { AddDistributorUser } from "components/distributor/AddDistributorUser";
import { DeleteDistributorUser } from "./DeleteDistributorUser";
import { DeleteDistributorInvitation } from "./DeleteDistributorInvitation";
import { getRoleName, isDistributor } from "../../helpers/role";
import { DistributorUserDTO } from "../../api/distributor/distributor.dto";
import { DistributorInvitationDTO } from "../../api/distributorInvitation/distributorInvitation.dto";

interface Props {
  distributorId: number;
  userRole: Role;
  userId?: number;
  className?: string;
}

export const DistributorUsersTab: FC<Props> = ({
  distributorId,
  userRole,
  userId,
  className = "",
}) => {
  const { t } = useTranslation();

  // Get users and invitations
  const {
    data: useDistributorUsersResponse,
    isLoading,
    isError,
  } = useDistributorUsers(distributorId);

  if (
    isLoading ||
    isError ||
    !useDistributorUsersResponse?.users ||
    !useDistributorUsersResponse.invitations
  ) {
    return (
      <CustomPlaceholder
        isEmpty={
          !isLoading &&
          !isError &&
          !useDistributorUsersResponse?.users &&
          !useDistributorUsersResponse?.invitations
        }
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`distributor.Error`)}
        emptyDataMessage={t(`distributor.EmptyUsers`)}
        loaderSize="page"
      />
    );
  }

  const { users, invitations } = useDistributorUsersResponse;

  return (
    <div className={className}>
      <div className="mb-4">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`distributor.Email`)}</TH>
              <TH>{t(`distributor.Name`)}</TH>
              <TH>{t(`distributor.Account type`)}</TH>
              <TH>{t(`distributor.Status`)}</TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {users.map(({ id, email, role, name }: DistributorUserDTO) => (
              <TR key={email}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>{getRoleName(role)}</TD>
                <TD>
                  <Badge
                    color="green"
                    text={t(`badgeAccountStatus.Account created`)}
                  />
                </TD>
                <TD alignRight>
                  {userId != id && isDistributor(userRole) && (
                    <DeleteDistributorUser
                      distributorId={distributorId}
                      userId={id}
                    />
                  )}
                </TD>
              </TR>
            ))}
            {invitations.map(
              ({ email, id, role, name }: DistributorInvitationDTO) => (
                <TR key={id}>
                  <TD addLeftMargin>{email}</TD>
                  <TD>{name}</TD>
                  <TD>{getRoleName(role)}</TD>
                  <TD>
                    <Badge color="blue" text="Invited" />
                  </TD>
                  <TD alignRight>
                    <div className="flex justify-end">
                      {isDistributor(userRole) && (
                        <DeleteDistributorInvitation
                          distributorId={distributorId}
                          invitationId={id}
                        />
                      )}
                    </div>
                  </TD>
                </TR>
              ),
            )}
          </TBody>
        </Table>
      </div>
      {isDistributor(userRole) && (
        <AddDistributorUser distributorId={distributorId} />
      )}
    </div>
  );
};
