import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const ConfirmedDocIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      width="79"
      height="auto"
      viewBox="0 0 49 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.93 1.06006C23.48 1.06006 24.05 1.09005 24.6 1.15005C31.17 1.89005 36.5 7.18007 37.28 13.7401C38.12 20.8001 33.8 27.3701 27 29.3701C26.52 29.5101 26.2 29.9601 26.22 30.4601C26.24 30.9601 26.6 31.3801 27.1 31.4701C36.84 33.3801 44 41.7601 44.45 51.6101H1.40001C1.86001 41.7701 9.01002 33.3801 18.75 31.4701C19.24 31.3701 19.6 30.9601 19.63 30.4601C19.65 29.9601 19.33 29.5101 18.85 29.3701C12.74 27.5801 8.47002 21.8801 8.47002 15.5201C8.47002 7.55007 14.95 1.06006 22.93 1.06006ZM22.93 0.0600586C14.39 0.0600586 7.47002 6.98007 7.47002 15.5201C7.47002 22.5401 12.16 28.4501 18.57 30.3301C18.66 30.3601 18.65 30.4701 18.57 30.4901C8.21002 32.5201 0.390015 41.6501 0.390015 52.6101H45.48C45.48 41.6501 37.66 32.5301 27.3 30.4901C27.21 30.4701 27.2 30.3501 27.3 30.3301C34.28 28.2801 39.22 21.4601 38.29 13.6301C37.46 6.60007 31.76 0.950065 24.73 0.160065C24.13 0.0900647 23.53 0.0600586 22.94 0.0600586H22.93Z"
        fill={color}
      />
      <path d="M48.59 44.1899H22.96V69.8199H48.59V44.1899Z" fill={color} />
      <path
        d="M28.14 57.5602L32.86 62.2702L43.39 51.7402"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default ConfirmedDocIcon;
