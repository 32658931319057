import { FC } from "react";
import { Badge, BadgeSize } from "./Badge";
import { PayoutInformationStatusTypes } from "enums/postBillingAdjustmentStatus.enums";

interface Props {
  status: PayoutInformationStatusTypes;
  size?: BadgeSize;
}

export const PayoutInformationStatusTag: FC<Props> = ({
  status,
  size = "small",
}) => {
  return (
    <div className="flex items-center">
      <Badge
        color={
          status === PayoutInformationStatusTypes.Registered ? "green" : "red"
        }
        text={status}
        size={size}
      />
    </div>
  );
};
