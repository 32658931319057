import { useNotification } from "hooks/useNotification";

import { ConsumptionMonth } from "types/consumptionMonth.type";
import api from "../axiosInstance";
import {
  CreateAssetOwnerRequestDTO,
  GetAllAssetOwnerResponseDTO,
  GetAssetOwnerProfileResponseDTO,
  GetAssetOwnerResponseDTO,
  GetAssetOwnersByUserRoleQueryParams,
  GetAssetOwnerWithAssetIdResponseDTO,
  GetElectricityAgreementResponseDTO,
  GetLatestAssetOwnerBasedOnAssetCreationDTO,
  GetLatestAssetOwnerForInstallerDTO,
  UpdateAssetOwnerRequestDTO,
  UpdateZavannSiteIdsRequestDTO,
} from "./assetOwner.dto";
import { GetInvoiceItemResponseDTO } from "types/invoice.type";
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { fetchPages } from "../pagination/pagination.endpoint";
import { ErrorResponse } from "types/errorResponse.type";
import qs from "qs";

const ENDPOINT = "/asset-owner";

export enum AssetOwnerQueryKeys {
  GetAllPagination = "asset-owner-get-all-pagination",
  GetOne = "asset-owner-get-one",
  GetInvoices = "asset-owner-get-invoices",
  GetConsumption = "asset-owner-get-consumption",
  GetProfile = "asset-owner-get-profile",
  GetElectricityAgreement = "asset-owner-get-electricity-agreement",
  GetAssetOwnerWithAssetId = "asset-owner-get-with-asset-id",
  GetLatestBasedOnAssetCreationForFlower = "asset-owner-get-latest-for-asset-created",
  GetLatestAssetOwnerForInstaller = "asset-owner-get-latest-for-installers",
}

export const useAssetOwnersPagination = (
  queryString: string,
  optionalParams?: GetAssetOwnersByUserRoleQueryParams,
) => {
  const { distributorId, installerId } = optionalParams || {};

  // Create optional query string
  const optionalQueryString = qs.stringify({
    installerId: installerId ? installerId : undefined,
    distributorId: installerId
      ? undefined
      : distributorId
        ? distributorId
        : undefined,
  });

  return useInfiniteQuery({
    queryKey: [
      AssetOwnerQueryKeys.GetAllPagination,
      { queryString, installerId, distributorId },
    ],
    queryFn: async ({ pageParam }): Promise<GetAllAssetOwnerResponseDTO> =>
      (
        await fetchPages({
          url: `${ENDPOINT}`,
          pageParam,
          queryString,
          extraQueryString: optionalQueryString,
        })
      ).data,
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasNextPage ? pages.length : undefined,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    gcTime: 2000,
  });
};

export const useAssetOwner = (id: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetAssetOwnerResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    enabled: !!id,
  });

export const useAssetOwnerWithAssetId = (id: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetAssetOwnerWithAssetId, id],
    queryFn: async (): Promise<GetAssetOwnerWithAssetIdResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/withAssetId`)).data,
    enabled: !!id,
  });

export const useAssetOwnerProfile = (id?: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetProfile, id],
    queryFn: async (): Promise<GetAssetOwnerProfileResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/profile`)).data,
    enabled: !!id,
  });

export const useCreateAssetOwner = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: CreateAssetOwnerRequestDTO): Promise<void> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetAllPagination],
      });
      notification.info(`Asset owner created`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
    ...options,
  });
};

export const useUpdateAssetOwner = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateAssetOwnerRequestDTO,
    ): Promise<GetAssetOwnerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    onSuccess: (data: GetAssetOwnerResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetOne, data.id],
      });
      notification.info(`Changes saved`);
    },

    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useDeleteAssetOwner = (settings = {}) => {
  const notification = useNotification();
  return useMutation({
    mutationFn: async (id: number): Promise<{ id: number }> =>
      await api.delete(`${ENDPOINT}/${id}`),
    onError: () => {
      notification.error("Something went wrong, please try again");
    },
    ...settings,
  });
};

export const useInvoices = (assetOwnerId: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetInvoices, assetOwnerId],
    queryFn: async (): Promise<GetInvoiceItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/${assetOwnerId}/invoice`)).data,
  });

export const useConsumption = (assetOwnerId: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetConsumption, assetOwnerId],
    queryFn: async (): Promise<ConsumptionMonth[]> =>
      (await api.get(`${ENDPOINT}/${assetOwnerId}/consumption`)).data,
  });

export const useAssetOwnerElectricityAgreement = (id?: number) =>
  useQuery({
    queryKey: [AssetOwnerQueryKeys.GetElectricityAgreement, id],
    queryFn: async (): Promise<GetElectricityAgreementResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/electricity-agreement`)).data,
    enabled: !!id,
  });

export const useLatestBasedOnAssetCreationForFlower = () => {
  return useQuery({
    queryKey: [AssetOwnerQueryKeys.GetLatestBasedOnAssetCreationForFlower],
    queryFn: async (): Promise<GetLatestAssetOwnerBasedOnAssetCreationDTO[]> =>
      (await api.get(`${ENDPOINT}/dashboard/latest-with-asset`)).data,
  });
};

export const useLatestAssetOwnersForInstaller = (installerId?: number) => {
  return useQuery({
    queryKey: [
      AssetOwnerQueryKeys.GetLatestAssetOwnerForInstaller,
      installerId,
    ],
    queryFn: async (): Promise<GetLatestAssetOwnerForInstallerDTO[]> =>
      (await api.get(`${ENDPOINT}/${installerId}/dashboard/latest-asset-owner`))
        .data,
    enabled: !!installerId,
  });
};

export const useUpdateZavannSiteIds = (assetOwnerId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateZavannSiteIdsRequestDTO,
    ): Promise<GetAssetOwnerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${assetOwnerId}/zavann-site`, payload)).data,
    onSuccess: (data: GetAssetOwnerResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetOne, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetElectricityAgreement, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetAllPagination],
      });
      notification.info(`Zavann site ids updated`);
    },
    onError: () => {
      notification.error();
    },
  });
};
