import { HandRaisedIcon } from "@heroicons/react/24/outline";
import { PageWrapper } from "components/PageWrapper";
import React, { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";

// useManualAction endpoint commented because we are not currently using it, please uncomment the code accordingly when you gonna use it.
export const ManualActionPage = () => {
  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, []);
  // const [manualActionInput, setManualActionInput] = useState("");
  // const {
  //   data: manualAction,
  //   isLoading,
  //   isError,
  // } = useManualAction(manualActionInput, { enabled: !!manualActionInput });

  // const { register, handleSubmit } = useForm<{ tableName: string }>();

  // const onSubmit = handleSubmit((data) => {
  //   setManualActionInput(data.tableName);
  // });

  return (
    <PageWrapper>
      <div>
        <div className="my-8 text-m text-gray-600">
          <p>
            We only have this page as a way to trigger functionality, such as
            logging something we need, if we have no other means of doing it.
          </p>
          <p className="mt-2">
            A developer needs to update this page and the related backend to
            make something happen.
          </p>
        </div>
        {/* <div>
          <TextInput
            label="Write the name of the manual action you want to do"
            name="tableName"
            register={register}
            size="third"
          />
          <PrimaryButton
            type="button"
            className="mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowerSky-500 text-base font-medium text-white hover:bg-flowerSky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowerSky-300 sm:col-start-2 sm:text-sm"
            onClick={onSubmit}
            size="small"
          >
            Perform action
          </PrimaryButton>
        </div> */}
        <div className="text-center my-8 text-m text-gray-600">
          <HandRaisedIcon className="mx-auto my-3 h-20 w-20 text-flowerSky-500" />
          {/* <p>
            {manualAction &&
              manualActionInput &&
              `Visit Cloudwatch or relevant service for result of manual action ${manualActionInput}`}
          </p>
          <p>{isLoading && `Loading...`}</p>
          <p>{isError && `Error performing ${manualActionInput}`}</p> */}
        </div>
      </div>
    </PageWrapper>
  );
};

const getBreadCrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.ManualAction,
    to: `${RouteUrl.App}/${RouteUrl.ManualAction}`,
  },
];
