import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { classNames } from "helpers/classNames";
import { useTranslation } from "react-i18next";
interface Props {
  label?: string;
  name: string;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  errorMessage?: string;
}
export const PostalCodeInput: FC<Props> = ({
  label,
  name,
  register,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const hasError = !!errorMessage;

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="flex text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className={classNames("mt-1 relative rounded-md shadow-sm")}>
        <input
          type="text"
          className={classNames(
            "block w-full rounded-md shadow-sm sm:text-sm",
            hasError
              ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300"
              : "border-gray-300 focus:border-flowerblue-500 focus:ring-flowerblue-500",
          )}
          {...register(name, {
            required: t(
              "validation.Please provide a valid postal code on the format XXXXX",
            ),
            pattern: {
              value: /^\d{5}$/,
              message: t(
                "validation.Please provide a valid postal code on the format XXXXX",
              ),
            },
          })}
          placeholder={"XXXXX"}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
