import {
  GetComponentStatusItemResponseDTO,
  GetFlowerHubResponseDTO,
} from "./flowerHub.dto";
import api from "../axiosInstance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "hooks/useNotification";

const ENDPOINT = "/flower-hub";

export enum FlowerHubQueryKeys {
  Get = "get-flower-hub",
  GetComponents = "get-components",
}

export const useFlowerHub = (serialNumber?: string) => {
  return useQuery({
    queryKey: [FlowerHubQueryKeys.Get, serialNumber],
    queryFn: async (): Promise<GetFlowerHubResponseDTO> =>
      (await api.get(`${ENDPOINT}/${serialNumber}`)).data,
    enabled: !!serialNumber,
    refetchInterval: 30000,
  });
};

export const useFlowerHubComponents = (serialNumber: string) => {
  return useQuery({
    queryKey: [FlowerHubQueryKeys.GetComponents, serialNumber],
    queryFn: async (): Promise<GetComponentStatusItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/components/${serialNumber}`)).data,
  });
};

export const useCompleteFirstDeployment = (serialNumber: string) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (serialNumber: string): Promise<boolean> =>
      (await api.put(`${ENDPOINT}/first-deployment-complete/${serialNumber}`))
        .data,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FlowerHubQueryKeys.Get, serialNumber],
      });
    },
    onError: () => {
      notification.error(
        "Failed to proceed to next step, please try again later.",
      );
    },
  });
};
