import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { InstallerAccountDetails } from "../../../components/installer/InstallerAccountDetails";
import { InstallerUsersTab } from "../../../components/installer/InstallerUsersTab";
import { menuStore } from "store/menu.store";
import { useInstaller } from "api/installer/installer.endpoints";
import userStore, { getUserRole } from "store/user.store";
import { getBreadCrumbs } from "../../../helpers/installer";

import { DashboardStatistics } from "../../../components/statistics/DashboardStatistics";
import { LatestAssetOwners } from "components/assetOwner/LatestAssetOwners";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { AssetOwners } from "components/assetOwner/AssetOwners";
import TabContainer from "../../../components/tab/TabContainer";

const DASHBOARD_TAB = "dashboard";
const ORGANIZATION_TAB = "organization";
const ASSET_OWNER_TAB = "asset-owners";
const USERS_TAB = "users";

export default function InstallerPage() {
  const { t } = useTranslation();

  const params = useParams();
  const installerId: string = params?.installerId || "-1";
  const userRole = userStore(getUserRole);

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || DASHBOARD_TAB;

  // Get installer
  const { data: installer, isLoading, isError } = useInstaller(+installerId);

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    if (installer) {
      setBreadCrumbs(getBreadCrumbs(userRole, installer));
    }
  }, [installer]);

  if (isLoading || isError || !installer) {
    return (
      <CustomPlaceholder
        isEmpty={!isLoading && !isError && !installer}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`installer.Error`)}
        emptyDataMessage={t(`installer.Empty`)}
        loaderSize="page"
      />
    );
  }

  return (
    <TabPageWrapper
      tabs={[
        {
          param: DASHBOARD_TAB,
          label: t("installer.DashboardTitle"),
          content: (
            <TabContainer key={DASHBOARD_TAB}>
              <div className="overflow-auto">
                <DashboardStatistics installerId={installer.id} />
                <LatestAssetOwners installerId={installer.id} />
              </div>
            </TabContainer>
          ),
          isSelected: currentTab === DASHBOARD_TAB,
        },
        {
          param: ORGANIZATION_TAB,
          label: t("installer.OrganizationTitle"),
          content: (
            <TabContainer key={ORGANIZATION_TAB}>
              <InstallerAccountDetails
                installer={installer}
                userRole={userRole}
                className="flex flex-col h-full overflow-auto"
              />
            </TabContainer>
          ),
          isSelected: currentTab === ORGANIZATION_TAB,
        },
        {
          param: ASSET_OWNER_TAB,
          label: t("installer.AssetOwnersTitle"),
          content: (
            <TabContainer key={ASSET_OWNER_TAB}>
              <div className="flex flex-col h-full pb-4 md:pb-8">
                <AssetOwners
                  userRole={userRole}
                  distributorId={installer.distributor.id}
                  installerId={installer.id}
                />
              </div>
            </TabContainer>
          ),
          isSelected: currentTab === ASSET_OWNER_TAB,
        },
        {
          param: USERS_TAB,
          label: t("installer.UsersTitle"),
          content: (
            <TabContainer key={USERS_TAB}>
              <InstallerUsersTab
                installerId={installer.id}
                userRole={userRole}
                className={"flex flex-col overflow-x-hidden overflow-auto"}
              />
            </TabContainer>
          ),
          isSelected: currentTab === USERS_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
}
