import { SystemNotificationType } from "enums/systemNotificationType.enum";

export type CreateSystemNotificationDTO = {
  message: string;
  startDate: Date;
  endDate: Date;
  type: SystemNotificationType;
};

export const getEmptySystemNotificationDTO = () => ({
  message: "",
  startDate: undefined,
  endDate: undefined,
  type: undefined,
});
