import { FC } from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "components/input/TextInput";
import { SimpleButton } from "components/buttons/SimpleButton";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { emailValidator } from "helpers/validation";
import {
  AssetOwnerDetailsDTO,
  GetAssetOwnerInvitationResponseDTO,
} from "../../../api/assetOwnerInvitation/assetOwnerInvitation.dto";

type Props = {
  invitation: GetAssetOwnerInvitationResponseDTO;
  assetOwnerDetails: AssetOwnerDetailsDTO;
  onBackButtonPressed: () => void;
  onUserDetailsFormCompleted: (assetOwnerDetails: AssetOwnerDetailsDTO) => void;
};

export const UserDetailsForm: FC<Props> = ({
  invitation,
  assetOwnerDetails,
  onBackButtonPressed,
  onUserDetailsFormCompleted,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AssetOwnerDetailsDTO>({
    defaultValues: assetOwnerDetails,
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit((data: AssetOwnerDetailsDTO) => {
    onUserDetailsFormCompleted(data);
  });

  const postalCodeUpdated = watch("postalCode");

  const isPostalCodeUpdated =
    postalCodeUpdated !== invitation.address.postalCode &&
    postalCodeUpdated.length === 5;

  return (
    <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-2">
      <div className="sm:mt-0 sm:col-span-3">
        <TextInput
          name={"firstName"}
          register={register}
          errorMessage={errors?.firstName?.message}
          registerOptions={{ required: "Fyll i ditt tilltalsnamn" }}
          label={"Tilltalsnamn"}
        />
      </div>
      <div className="sm:mt-0 sm:col-span-3">
        <TextInput
          name={"lastName"}
          register={register}
          errorMessage={errors?.lastName?.message}
          registerOptions={{ required: "Fyll i ditt efternamn" }}
          label={"Efternamn"}
        />
      </div>
      <div className="sm:col-span-6 mt-2">
        <TextInput
          name={"address"}
          register={register}
          errorMessage={errors?.address?.message}
          registerOptions={{ required: "Fyll i din adress" }}
          hoverDetails={
            "Denna adress måste vara samma som elavtalet gäller för."
          }
          label={"Adress"}
        />
      </div>
      <div className="sm:col-span-2 mt-2">
        <PostalCodeInput
          label="Postnummer"
          name="postalCode"
          register={register}
          errorMessage={errors?.postalCode?.message}
        />
      </div>
      <div className="sm:col-span-4 mt-2">
        <TextInput
          name={"city"}
          register={register}
          errorMessage={errors?.city?.message}
          registerOptions={{ required: "Fyll i vilken stad du bor" }}
          label={"Stad"}
        />
      </div>
      {isPostalCodeUpdated && (
        <div className={"sm:col-span-6 mt-2 p-3 bg-gray-50 rounded-lg shadow"}>
          <p className="flex text-sm text-flowerblue-600">
            Adressen stämmer inte med den som installatören angett. Adressen du
            anger ska vara den där batteriet är installerat. Var god dubbelkolla
            att det är så innan du går vidare till nästa steg.
          </p>
        </div>
      )}
      <div className="sm:col-span-6 mt-2">
        <div className="text-sm font-medium text-gray-700">
          Email för inloggning
        </div>
        <div className="border p-2 bg-gray-100 rounded-md text-sm text-gray-500 font-normal">
          {invitation.email}
        </div>
      </div>
      <div className="sm:col-span-6 mt-2">
        <TextInput
          name={"contactEmail"}
          register={register}
          errorMessage={errors?.contactEmail?.message}
          registerOptions={emailValidator}
          type="email"
          hoverDetails={
            "Denna email används för att skicka information och fakturor angående elavtalet. Det kan vara en annan email än den som används för att logga in."
          }
          label={"Email för kontakt"}
        />
      </div>
      <div className="sm:col-span-6 mt-2">
        <TextInput
          name={"phone"}
          register={register}
          errorMessage={errors?.phone?.message}
          registerOptions={{
            required: "Fyll i ditt telefonnummer",
            pattern: {
              value: /^\+?[\d ]{10,}$/, // optional +, at least 10 digits-or-spaces. Not perfect but good enough and simple.
              message:
                "Ange telefonnummer inklusive riktnummer utan parenteser eller bindestreck.",
            },
          }}
          label={"Telefonnummer"}
        />
      </div>
      <div className="sm:col-span-1 sm:mt-0">
        <div className="sm:col-span-5 sm:mt-0">
          <SimpleButton
            onClick={onBackButtonPressed}
            text={"Tillbaka"}
            style={"secondary"}
          ></SimpleButton>
        </div>
      </div>
      <div className="sm:col-span-5 sm:mt-0">
        <SimpleButton onClick={onSubmit} text={"Nästa"} style={"primary"} />
      </div>
    </div>
  );
};
