import { classNames } from "../../helpers/classNames";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  isWide?: boolean;
  style?: string;
}

export const NotScrollablePage: FC<Props> = ({
  children,
  isWide = false,
  style = "",
}) => (
  <div
    className={classNames(
      "flex flex-col mr-auto px-4 md:px-8 w-full h-full overflow-hidden",
      isWide ? "max-w-screen-2xl" : "max-w-screen-xl",
      style,
    )}
  >
    {children}
  </div>
);
