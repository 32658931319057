export enum SystemNotificationType {
  ZavannMaintenance = "Zavann Maintenance",
  FlowerMaintenance = "Flower Maintenance",
}

export enum SystemNotificationStatus {
  Upcoming = "Upcoming",
  OnGoing = "Ongoing",
  Ended = "Ended",
}
