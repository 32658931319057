import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

export const FlowerLink: FC<
  LinkProps & React.RefAttributes<HTMLAnchorElement>
> = ({ children, ...rest }) => (
  <Link
    className="text-sm font-medium text-flowerSky-800 hover:text-flowerSky-500"
    {...rest}
  >
    {children}
  </Link>
);
