import { useEffect } from "react";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";

import { DistributorList } from "../../../components/distributor/DistributorList";
import userStore, { getUserRole } from "store/user.store";

export const DistributorsPage = () => {
  const userRole = userStore(getUserRole);

  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs([getDistributorListBreadcrumb()]);
  }, []);

  return <DistributorList userRole={userRole} />;
};

export const getDistributorListBreadcrumb = (): BreadCrumb => ({
  label: "Distributors",
  to: `${RouteUrl.App}/${RouteUrl.Distributors}`,
});
