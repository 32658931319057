import {
  BoltIcon,
  ClipboardDocumentListIcon,
  CubeIcon,
  HomeModernIcon,
  ServerStackIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useDashboardStatistics } from "api/statistics/statistics.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import DashboardTile from "components/statistics/DashboardTile";
import { StatisticPeriodType } from "enums/statistic.enum";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import userStore, { getUserRole } from "store/user.store";
import PeriodFilterTabs from "./PeriodFilterTabs";
import { isDistributor, isFlower } from "../../helpers/role";

interface Props {
  distributorId?: number;
  installerId?: number;
}
export const DashboardStatistics: FC<Props> = ({
  distributorId,
  installerId,
}) => {
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);
  const [selectedPeriod, setSelectedPeriod] = useState<StatisticPeriodType>(
    StatisticPeriodType.Last30Days,
  );

  const handlePeriodChange = (period: StatisticPeriodType) => {
    setSelectedPeriod(period);
  };

  const {
    data: statisticsData,
    isLoading: isLoadingStatistics,
    isError: statisticsIsErrored,
  } = useDashboardStatistics(distributorId, installerId, selectedPeriod);

  const calculatePercentageChange = (
    current: number,
    previous: number,
    decimalPlaces: number = 1,
  ) => {
    if (previous === 0) {
      if (current === 0) {
        return 0;
      }
      return current * 100;
    }
    return parseFloat(
      (((current - previous) / previous) * 100).toFixed(decimalPlaces),
    );
  };

  const formatActivePower = (valueInKw: number): string => {
    // Define the conversion factor
    const conversionFactor = 0.001; // 1 kW = 0.001 MW
    const valueInMw = valueInKw * conversionFactor;

    if (valueInMw >= 1) {
      return `${valueInMw.toFixed(3)} MW`;
    } else {
      return `${Math.round(valueInKw)} kW`;
    }
  };

  return (
    <div className="flow-root w-full mb-6">
      <PeriodFilterTabs
        selectedPeriod={selectedPeriod}
        onPeriodChange={handlePeriodChange}
      />
      {isLoadingStatistics || !statisticsData || statisticsIsErrored ? (
        <CustomPlaceholder
          isError={statisticsIsErrored}
          isLoading={isLoadingStatistics}
          isEmpty={
            !isLoadingStatistics && !statisticsIsErrored && !statisticsData
          }
          icon={<ClipboardDocumentListIcon />}
          errorMessage={t(`dashboardStatistics.UnableToLoad`)}
          emptyDataMessage={t(`dashboardStatistics.NotFound`)}
        />
      ) : (
        <dl className="flex flex-wrap gap-4 my-4 justify-center md:justify-start">
          {statisticsData?.distributorStats && isFlower(userRole) && (
            <DashboardTile
              title={t("dashboardStatistics.Distributors")}
              leftStat={{
                count: statisticsData.distributorStats.totalOrganizations,
                label: t("dashboardStatistics.Org", {
                  count: statisticsData.distributorStats.totalOrganizations,
                }),
              }}
              rightStat={{
                count: statisticsData.distributorStats.totalUsers,
                label: t("dashboardStatistics.User", {
                  count: statisticsData.distributorStats.totalUsers,
                }),
              }}
              deviation={calculatePercentageChange(
                statisticsData.distributorStats.totalUsers,
                statisticsData.distributorStats.previousTotalUsers,
              )}
              icon={<CubeIcon />}
              showDeviation={selectedPeriod !== StatisticPeriodType.All}
            />
          )}
          {statisticsData?.installerStats &&
            (isFlower(userRole) || isDistributor(userRole)) && (
              <DashboardTile
                title={t("dashboardStatistics.Installers")}
                leftStat={{
                  count: statisticsData.installerStats.totalOrganizations,
                  label: t("dashboardStatistics.Org", {
                    count: statisticsData.installerStats.totalOrganizations,
                  }),
                }}
                rightStat={{
                  count: statisticsData.installerStats.totalUsers,
                  label: t("dashboardStatistics.User", {
                    count: statisticsData.installerStats.totalUsers,
                  }),
                }}
                deviation={calculatePercentageChange(
                  statisticsData.installerStats.totalUsers,
                  statisticsData.installerStats.previousTotalUsers,
                )}
                icon={<TruckIcon />}
                showDeviation={selectedPeriod !== StatisticPeriodType.All}
              />
            )}
          <DashboardTile
            title={t("dashboardStatistics.AssetOwners")}
            leftStat={{
              count: statisticsData.assetOwnerStats.totalUsers,
              label: "",
            }}
            deviation={calculatePercentageChange(
              statisticsData.assetOwnerStats.totalUsers,
              statisticsData.assetOwnerStats.previousTotalUsers,
            )}
            icon={<HomeModernIcon />}
            showDeviation={selectedPeriod !== StatisticPeriodType.All}
          />
          <DashboardTile
            title={t("dashboardStatistics.FlowerHubs")}
            leftStat={{
              count: statisticsData.flowerHubStats.newInstallations,
              label: "",
            }}
            deviation={calculatePercentageChange(
              statisticsData.flowerHubStats.newInstallations,
              statisticsData.flowerHubStats.previousInstallations,
            )}
            icon={<ServerStackIcon />}
            showDeviation={selectedPeriod !== StatisticPeriodType.All}
          />
          <DashboardTile
            title={t("dashboardStatistics.ActivePower")}
            leftStat={{
              count: formatActivePower(
                statisticsData.flowerHubStats.currentActivePower,
              ),
              label: "",
            }}
            deviation={calculatePercentageChange(
              statisticsData.flowerHubStats.currentActivePower,
              statisticsData.flowerHubStats.previousActivePower,
            )}
            icon={<BoltIcon />}
            showDeviation={selectedPeriod !== StatisticPeriodType.All}
          />
        </dl>
      )}
    </div>
  );
};
