import React from "react";
import { Tab, TabList, TabGroup } from "@headlessui/react";
import { StatisticPeriodType } from "enums/statistic.enum";
import { useTranslation } from "react-i18next";

interface PeriodFilterTabsProps {
  selectedPeriod: StatisticPeriodType;
  onPeriodChange: (period: StatisticPeriodType) => void;
}

const PeriodFilterTabs: React.FC<PeriodFilterTabsProps> = ({
  selectedPeriod,
  onPeriodChange,
}) => {
  const { t } = useTranslation();

  return (
    <TabGroup>
      <TabList className="flex gap-4 rounded-full bg-white max-w-fit shadow-sm">
        {Object.values(StatisticPeriodType).map((type) => (
          <Tab
            key={type}
            onClick={() => onPeriodChange(type)}
            className={`min-w-20 rounded-full py-1 px-3 text-sm font-normal focus:outline-none ${
              selectedPeriod === type
                ? "bg-flowerPeach-500 text-flowerPeach-900"
                : "text-flowerGray-400 hover:text-flowerPeach-900"
            }`}
          >
            {t(`dashboardStatistics.periodTypeLabels.${type}`)}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
};

export default PeriodFilterTabs;
