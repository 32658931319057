import { useAssetStatistics } from "api/asset/asset.endpoints";
import { FC } from "react";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FlowerHubStatisticContainer } from "./FlowerHubStatisticContainer";

interface Props {
  assetId: number;
}

export const FlowerHubStatisticsFlowerVersion: FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { data, isError, isLoading } = useAssetStatistics(assetId);

  return (
    <div className="flex flex-col items-stretch justify-center mb-8 rounded-lg border border-gray-100 bg-white shadow min-h-40 p-2">
      {isLoading || !data || isError ? (
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !data}
          icon={<ExclamationCircleIcon />}
          errorMessage={t(
            `flowerHubStatistics.UnableToLoadFlowerHubStatistics`,
          )}
        />
      ) : (
        <dl className="grid grid-cols-1 lg:grid-cols-4 lg:divide-x divide-y sm:divide-y lg:divide-y-0 lg:px-2 xl:px-0">
          <FlowerHubStatisticContainer
            name={t(`flowerHubStatistics.Serial number`)}
            data={data?.serialNumber}
          />
          <FlowerHubStatisticContainer
            name={t(`flowerHubStatistics.Prequalification group`)}
            data={data?.prequalificationGroupName}
          />
          <FlowerHubStatisticContainer
            name={t(`flowerHubStatistics.Market approvals`)}
            list={data?.marketApprovals}
          />
          <FlowerHubStatisticContainer
            name={t(`flowerHubStatistics.Asset control`)}
            data={data?.assetControlStatus}
          />
        </dl>
      )}
    </div>
  );
};
