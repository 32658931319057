import React from "react";
import { useTranslation } from "react-i18next";
import { config } from "react-spring";
import { Transition } from "../animations/Transition";

export const LoadingToaster = () => {
  const { t } = useTranslation();

  return (
    <Transition
      settings={{
        from: { opacity: 0.5, transform: "translate3d(0, 30px, 0px)" },
        enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
        config: { ...config.default, precision: 0.02, mass: 0.9 },
      }}
    >
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-gray-400 bg-white px-3 py-1.5 rounded-lg text-xs shadow">
        {t("Loading more data")}
      </div>
    </Transition>
  );
};
