import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import useDebounce from "hooks/useDebounce";
import { FC, useEffect, useState } from "react";

interface Props {
  placeholder?: string;
  onSearch: (value: string) => void;
  initialValue?: string;
}
export const SearchField: FC<Props> = ({
  placeholder,
  onSearch,
  initialValue = "",
}) => {
  const [value, setValue] = useState(initialValue);
  const debouncedSearchValue = useDebounce(value);

  useEffect(() => {
    onSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <div className="mt-2 mb-2 w-full flex rounded-md shadow-sm max-w-md align-middle">
      <div className="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          className="block w-full rounded-md border-gray-100 pl-5 focus:border-flowerSkyVivid-500 focus:ring-indigo-500 sm:text-sm box py-2"
          placeholder={placeholder ?? ""}
          autoComplete="off"
          type="text"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
};
