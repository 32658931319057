import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import { DeleteFlowerUserConfirmation } from "./DeleteFlowerUserConfirmation";
import DangerButton from "components/buttons/DangerButton";
import { useDeactivateUser, UserQueryKeys } from "api/user/user.endpoints";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  userId: number;
}

export const DeleteFlowerUser: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDeleteUserModalOpen, setIsUserModalOpen] = useState(false);

  // api
  const { mutate: deactivateUser, isSuccess } = useDeactivateUser({
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UserQueryKeys.GetFlowerUsers],
      }),
  });

  // submit
  const onDeactivateUser = () => deactivateUser(userId);

  // modal close
  const onDeleteUserModalClose = () => {
    setIsUserModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteUserModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <DangerButton size="small" onClick={() => setIsUserModalOpen(true)}>
        {t(`flowerUser.Deactivate user`)}
      </DangerButton>

      <Modal
        title={t(`flowerUser.Delete user`)}
        leftButtonAction={onDeleteUserModalClose}
        leftButtonText={t(`Close`)}
        rightButtonActionHandler={() => onDeactivateUser()}
        isOpen={isDeleteUserModalOpen}
        onClose={onDeleteUserModalClose}
        loadingText={t(`flowerUser.Deleting user`)}
      >
        <DeleteFlowerUserConfirmation />
      </Modal>
    </>
  );
};
