import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";

import { useVirtualizer } from "@tanstack/react-virtual";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { ListStyle } from "./ListStyle";
import { Title } from "./Title";
import { ToolBar } from "../tableBeta/Toolbar";
import { SearchField } from "../tableBeta/SearchField";
import { FilterMenu } from "../tableBeta/FilterMenu";
import { UL } from "./UL";
import { ElementDef, Identifiable, LI } from "./LI";
import { classNames } from "../../helpers/classNames";
import { LoadingToaster } from "../tableBeta/LoadingToaster";
import { useFetchMoreOnBottomReached } from "../../hooks/useFetchMoreOnBottomReached";

interface Props<T> {
  elements: ElementDef<T>;
  paginationQuery: InfiniteQueryResult<T>;
  onSelectRow?: (data: T) => void;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  title?: string;
  searchPlaceholder?: string;
  hasToolbar?: boolean;
  offSetToTriggerPagination?: number;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const List = <T,>({
  elements,
  paginationQuery,
  onSelectRow,
  setPaginationUrl,
  title = "",
  searchPlaceholder = "",
  hasToolbar = true,
  offSetToTriggerPagination = 300,
  createColumnFilter,
}: Props<T>): ReactElement => {
  // State used to store the visibility of the loading toast when fetching more data
  const [showLoadingToast, setShowLoadingToast] = useState<boolean>(false);

  // Reference to the parent div of the list
  const listContainerElement = useRef<HTMLDivElement>(null);

  // Destructure the data from the paginationQuery
  const { data, isFetchingNextPage, isRefetching } = paginationQuery;

  // Flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.items) ?? [],
    [data],
  );

  // Getting updated filters, if any, from the server. Checked only first page, because filters are static (same for each page)
  const filters = useMemo(() => data?.pages?.[0]?.filters ?? [], [data]);

  // Called on scroll to fetch more pages as the user scrolls and reaches bottom of the table body
  useFetchMoreOnBottomReached(
    paginationQuery,
    offSetToTriggerPagination,
    setShowLoadingToast,
    listContainerElement,
  );

  // Virtualizer for the list rows to improve performance
  const rowVirtualizer = useVirtualizer({
    count: flatData.length,
    getScrollElement: () => listContainerElement.current,
    estimateSize: () => 110,
    overscan: 4, // number of rows to render above and below the visible area
  });

  return (
    <div className="w-full p-4 rounded-2xl flex flex-col bg-white h-max-full min-h-12 shadow">
      {(title || hasToolbar) && (
        <div className="flex-shrink-0 h-8 justify-between items-center flex mb-4">
          <Title title={title} />
          {hasToolbar && (
            <ToolBar size={"small"}>
              <SearchField
                placeholder={searchPlaceholder}
                setPaginationUrl={setPaginationUrl}
              />
              {filters.length !== 0 && (
                <FilterMenu
                  filters={createColumnFilter(filters)}
                  setPaginationUrl={setPaginationUrl}
                />
              )}
            </ToolBar>
          )}
        </div>
      )}
      <div className="flex-grow overflow-hidden bg-white w-full rounded-lg">
        <ListStyle>
          <div
            ref={listContainerElement}
            className={classNames(
              "relative overflow-auto rounded-bl-lg rounded-br-lg h-full",
              isRefetching ? "opacity-50" : "",
            )}
          >
            <UL rowVirtualizer={rowVirtualizer}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = flatData[virtualRow.index] as T & Identifiable; // Every row must have an id
                return (
                  <LI
                    key={virtualRow.index}
                    virtualRow={virtualRow}
                    row={row}
                    elements={elements}
                    onSelectRow={isRefetching ? undefined : onSelectRow}
                    rowVirtualizer={rowVirtualizer}
                  />
                );
              })}
            </UL>
            {rowVirtualizer.getVirtualItems().length === 0 && (
              <div className="flex justify-center items-center h-36">
                <span className="text-gray-400">No data available.</span>
              </div>
            )}
          </div>
          {isFetchingNextPage && showLoadingToast && <LoadingToaster />}
        </ListStyle>
      </div>
    </div>
  );
};
