/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { RouteUrl } from "routing/routeUrl.enum";
import { clearRefreshTokenExpirationDate } from "store/refreshToken.store";
import { AuthEndPoints } from "./auth/auth.endpoints";
const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// The following interceptor checks for calls that become unauthorized
// and retrieves a new access token if the current one seems to have expired.
// The original call is redone immediately afterwards without the user
// having time to notice what happened.
api.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (isUnauthorized(err)) {
      if (isRefreshTokenRequest(err)) {
        // Refresh token has expired, user must login again
        clearRefreshTokenExpirationDate();
        window.location.href = `${window.location.origin}${RouteUrl.Login}`;
        return Promise.reject(err);
      } else if (isLoginRequest(err)) {
        // User logged in with the wrong credentials
        return Promise.reject(err);
      }
      originalConfig._retry = true;
      try {
        // If we have come this far, it means that a "normal request" has become unauthorized
        // The normal case here is that the authtoken has expired and needs to be refreshed
        await api.get(AuthEndPoints.RefreshToken);

        // Then try to redo the original call again.
        return api(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(err);
  },
);
export default api;

const isUnauthorized = (err: any) =>
  err.response && err.response.status === 401 && !err.config._retry;

const isRefreshTokenRequest = (err: any) =>
  err.config.url === AuthEndPoints.RefreshToken;

const isLoginRequest = (err: any) => err.config.url === AuthEndPoints.Login;
