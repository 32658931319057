import { SystemNotification } from "api/system-notification/systemNotification.type";
import { FlowerHubLogo } from "styles/Icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  maintenance?: SystemNotification;
}
export const Maintenance: FC<Props> = ({ maintenance }) => {
  const { t } = useTranslation();

  return (
    <div className="min-h-full pl-8 py-14 sm:py-14 space-y-4 sm:space-y-8">
      <img className="h-16 w-auto h-auto" src={FlowerHubLogo} />

      <main className="sm:flex">
        <div className="flex-shrink-0">
          <svg
            className="mx-auto h-12 w-12 text-red-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
            />
          </svg>
        </div>
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 className="text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl">
              {t("maintenance.title")}
            </h1>
            <p className="mt-1 text-base text-gray-500">
              {maintenance?.message}
            </p>
          </div>
          <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"></div>
        </div>
      </main>
    </div>
  );
};
