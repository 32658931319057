import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateAssetRequestDTO,
  CreateAssetResponseDTO,
  GetAllAssetResponseDTO,
  GetAssetResponseDTO,
  GetAssetRevenueWithAssetModelResponseDTO,
  GetAssetStatisticsResponseDTO,
  UpdateAssetRequestDTO,
  UpdateAssetResponseDTO,
} from "./asset.dto";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { fetchPages } from "../pagination/pagination.endpoint";

const ENDPOINT = "/asset";

export enum AssetQueryKeys {
  GetAllPagination = "asset-get-assets-pagination",
  GetOne = "asset-get-asset",
  GetRevenue = "asset-get-revenue",
  CheckSerialNumber = "asset-check-serial-number",
  GetAssetStatistics = "asset-get-asset-statistics",
}

export const useAssetsPagination = (queryString: string) => {
  return useInfiniteQuery({
    queryKey: [AssetQueryKeys.GetAllPagination, { queryString }],
    queryFn: async ({ pageParam }): Promise<GetAllAssetResponseDTO> =>
      (
        await fetchPages({
          url: `${ENDPOINT}`,
          pageParam,
          queryString,
        })
      ).data,
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasNextPage ? pages.length : undefined,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    gcTime: 2000,
  });
};

export const useAssetRevenue = (id?: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetRevenue, id],
    queryFn: async (): Promise<GetAssetRevenueWithAssetModelResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/revenue`)).data,
    enabled: !!id,
  });
};

export const useAsset = (id?: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetAssetResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    enabled: !!id,
  });
};

export const useAssetStatistics = (id: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetAssetStatistics, id],
    queryFn: async (): Promise<GetAssetStatisticsResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/statistics`)).data,
  });
};

export const useCreateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateAssetRequestDTO,
    ): Promise<CreateAssetResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data: CreateAssetResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetOne, data.assetOwnerId],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetAllPagination],
      });
    },
    onError: () => {
      notification.error();
    },
  });
};

export const useUpdateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      payload: UpdateAssetRequestDTO;
      id: number;
    }): Promise<UpdateAssetResponseDTO> =>
      (await api.put(`${ENDPOINT}/${data.id}`, data.payload)).data,

    onSuccess: (data: UpdateAssetResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetQueryKeys.GetOne, data.id],
      });
    },
    onError: () => {
      notification.error(
        "Failed to update site ids, please double check site ids and try again.",
      );
    },
  });
};
