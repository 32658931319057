import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import { FlowerHubs } from "../../../components/flowerHub/FlowerHubs";
import { SmoothUp } from "../../../components/animations/SnapUp";

export const AssetListPage = () => {
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs(getAssetListBreadcrumbs());
  }, []);

  return (
    <SmoothUp>
      <FlowerHubs />
    </SmoothUp>
  );
};

const getAssetListBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Assets,
    to: getAssetListLink(),
  },
];

export const getAssetListLink = (): string =>
  [RouteUrl.App, RouteUrl.Assets].join("/");
