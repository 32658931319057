import { FC } from "react";

export const ActionButton: FC<{
  onClick: () => void;
  label: string;
  variant: "black" | "primary";
  disabled?: boolean;
}> = ({ onClick, label, variant, disabled = false }) => {
  const baseClasses =
    "py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600";
  const variants = {
    black: "bg-black text-white hover:bg-gray-900",
    primary:
      "bg-flowerSkyVivid-500 text-white hover:bg-gray-900 disabled:opacity-75 disabled:bg-gray-600 disabled:cursor-not-allowed",
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseClasses} ${variants[variant]}`}
    >
      {label}
    </button>
  );
};
