import { FC } from "react";
import {
  CreatePrequalificationGroupDTO,
  PrequalificationGroupDTO,
} from "types/prequalificationGroup.type";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import {
  BiddingRegion,
  BIDDING_REGION_DROPDOWN_ITEMS,
} from "enums/biddingRegion.enum";
import Dropdown from "components/input/Dropdown";
import { BRP, BRP_DROPDOWN_ITEMS } from "enums/brp.enum";
import { CheckboxGroup } from "components/input/CheckboxGroup";
import { TextInput } from "components/input/TextInput";
import {
  positiveNumberValidator,
  noEmptyStringValidator,
} from "helpers/validation";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<
    CreatePrequalificationGroupDTO | PrequalificationGroupDTO
  >;
  errors: Partial<
    FieldErrorsImpl<CreatePrequalificationGroupDTO | PrequalificationGroupDTO>
  >;
}
export const PrequalificationGroupForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`prequalificationGroup.Name`)}
        name="name"
        register={register}
        errorMessage={errors?.name?.message}
        registerOptions={noEmptyStringValidator()}
      />
      <Dropdown
        label={t(`prequalificationGroup.Bidding region`)}
        name="biddingRegion"
        items={BIDDING_REGION_DROPDOWN_ITEMS}
        register={register}
        validation={{
          validate: (value?: BiddingRegion) =>
            !!value || t(`prequalificationGroup.Select a bidding region`),
        }}
        errorMessage={errors?.biddingRegion?.message}
      />
      <Dropdown
        label={t(`prequalificationGroup.BRP`)}
        name="brp"
        items={BRP_DROPDOWN_ITEMS}
        register={register}
        validation={{
          validate: (value?: BRP) =>
            (!!value && value !== BRP.Undefined) ||
            t(`prequalificationGroup.Select a BRP`),
        }}
        errorMessage={errors?.brp?.message}
      />
      <CheckboxGroup
        label={t(`prequalificationGroup.Market approvals`)}
        items={[
          {
            label: t(`prequalificationGroup.FCR-D-up`),
            name: "marketApprovalFcrdUp",
          },
          {
            label: t(`prequalificationGroup.FCR-D-down`),
            name: "marketApprovalFcrdDown",
          },
          {
            label: t(`prequalificationGroup.FCR-N`),
            name: "marketApprovalFcrN",
          },
        ]}
        register={register}
      />
      <TextInput
        label={t(`prequalificationGroup.Approved capacity`)}
        name="approvedCapacity"
        register={register}
        appendix="kW"
        size="third"
        registerOptions={positiveNumberValidator}
        type="number"
      />
    </ModalContentWrapper>
  );
};
