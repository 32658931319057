import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CreateAssetModelRequestDTO } from "api/assetModel/assetModel.dto";
import { useCreateAssetModel } from "api/assetModel/assetModel.endpoints";
import { getEmptyAssetModelDTO } from "api/assetModel/assetModel.helper";
import { AddAssetModelForm } from "./AddAssetModelForm";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useTranslation } from "react-i18next";

interface Props {
  centered?: boolean;
}

export const AddAssetModel: FC<Props> = ({ centered = false }) => {
  const { t } = useTranslation();

  // state
  const [isAddAssetModelModalOpen, setIsAddAssetModelModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: createAssetModel,
    isPending: isCreatingAssetModel,
    isSuccess: isCreateAssetModelSuccess,
  } = useCreateAssetModel();

  // form
  const {
    register: registerAssetModel,
    handleSubmit: handleAssetModelSubmit,
    reset: resetAssetModel,
    formState: { errors: assetModelErrors },
  } = useForm<CreateAssetModelRequestDTO>();

  // submit
  const onAssetModelSubmit = handleAssetModelSubmit(
    (data: CreateAssetModelRequestDTO) => createAssetModel(data),
  );

  // modal close
  const closeAddAssetModelModal = () => {
    setIsAddAssetModelModalOpen(false);
    setTimeout(() => resetAssetModel(getEmptyAssetModelDTO()), 300);
  };

  // after submit success
  useEffect(() => {
    if (isCreateAssetModelSuccess) {
      closeAddAssetModelModal();
    }
  }, [isCreateAssetModelSuccess]);

  const renderAddAssetModelButton = () => {
    return (
      <div
        className={`pb-2 flex ${centered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton onClick={() => setIsAddAssetModelModalOpen(true)}>
          <PlusCircleIcon className="h-5 w-5 mr-1" />{" "}
          {t(`assetModel.Add asset model`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddAssetModelButton()}

      <Modal
        isOpen={isAddAssetModelModalOpen}
        onClose={closeAddAssetModelModal}
        leftButtonAction={closeAddAssetModelModal}
        leftButtonText={t(`Close`)}
        title={t(`assetModel.Add asset model`)}
        rightButtonActionHandler={onAssetModelSubmit}
        isLoading={isCreatingAssetModel}
      >
        <AddAssetModelForm
          register={registerAssetModel}
          errors={assetModelErrors}
        />
      </Modal>
    </>
  );
};
