import { FC, useId, useState } from "react";
import { SignUpStep } from "enums/signUpStep.enum";
import { Button } from "pages/sign-up-flow/components/Button";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  setStep: (step: SignUpStep) => void;
};

export const SameUserWarning: FC<Props> = ({ setStep }) => {
  const checkboxId = useId();

  const [confirmed, setConfirmed] = useState(false);

  return (
    <div className="px-6 py-6">
      <ExclamationCircleIcon className="ml-auto mr-auto h-20 w-20 animate-attention mr-1 text-red-600" />
      <h2 className="text-2xl font-medium leading-6 text-gray-900 mx-auto mb-4">
        Varning!
      </h2>
      <p className="mt-1 max-w-2xl text-base text-gray-600 leading-6">
        Personen som står på hushållets nuvarande elavtal måste signera med
        BankID för att slutföra registreringen. Se därför till att fylla i den
        personens uppgifter i kommande steg samt att den personen är tillgänglig
        för signering med BankID.
      </p>
      <div className="mt-6">
        <input
          name="confirmed"
          type="checkbox"
          id={checkboxId}
          onChange={(event) => setConfirmed(event.target.checked)}
        />
        <label className="ml-2" htmlFor={checkboxId}>
          Jag förstår att jag ska fylla i personuppgifterna för personen som
          står på hushållets elavtal.
        </label>
      </div>
      <Button
        disabled={!confirmed}
        handleClick={() => setStep(SignUpStep.UserDetails)}
        label="Gå vidare"
      />
    </div>
  );
};
