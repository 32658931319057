import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { Checkbox, CheckboxItem } from "components/input/Checkbox";

interface CheckboxGroupProps {
  label: string;
  items: CheckboxItem[];
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
}
export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  items,
  register,
}) => (
  <fieldset>
    <legend className="sr-only">{label}</legend>
    <div className="flex text-sm font-medium text-gray-700" aria-hidden="true">
      {label}
    </div>
    <div className="mt-2 space-y-2">
      {items.map((item: CheckboxItem) => (
        <Checkbox key={item.name} item={item} register={register} />
      ))}
    </div>
  </fieldset>
);
