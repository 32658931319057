import { FC } from "react";
import { config } from "react-spring";
import { ChildrenProps } from "types/childrenProps.type";
import { Transition } from "./Transition";

export const SmoothUp: FC<ChildrenProps> = ({ children }) => {
  return (
    <Transition
      settings={{
        from: { opacity: 0.5, transform: "translate3d(0, 30px, 0px)" },
        enter: { opacity: 1, transform: "translate3d(0px, 0px, 0px)" },
        config: { ...config.default, precision: 0.02, mass: 0.9 },
      }}
      className={"flex flex-col h-full w-full"}
    >
      {children}
    </Transition>
  );
};

export const OpacityTransition: FC<ChildrenProps> = ({ children }) => (
  <Transition
    settings={{
      from: { opacity: 0.6 },
      enter: { opacity: 1 },
      leave: { opacity: 0.6 },
      config: { ...config.stiff, precision: 0.04 },
    }}
    isVisible
  >
    {children}
  </Transition>
);

export const ScaleUp: FC<ChildrenProps> = ({ children }) => (
  <Transition
    settings={{
      from: {
        transform: "scale(0.92)",
        opacity: 0.7,
      },
      enter: {
        transform: "scale(1)",
        opacity: 1,
      },
      config: { ...config.wobbly },
    }}
    isVisible
  >
    {children}
  </Transition>
);
