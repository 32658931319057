import api from "../axiosInstance";
import { GetFlowerHubLogForAssetDTO } from "./flowerHubLog.dto";
import { useQuery } from "@tanstack/react-query";

const ENDPOINT = "/flower-hub-log";

export enum FlowerHubLogQueryKeys {
  GetAllForAsset = "flower-hub-log-for-asset",
}

export const useFlowerHubLogsForAsset = (assetId: number) =>
  useQuery({
    queryKey: [FlowerHubLogQueryKeys.GetAllForAsset, assetId],
    queryFn: async (): Promise<GetFlowerHubLogForAssetDTO[]> =>
      (await api.get(`${ENDPOINT}/${assetId}`)).data,
    gcTime: 0,
  });
