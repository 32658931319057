import { formatDateMonth } from "helpers/date";
import { GetInvoiceItemResponseDTO } from "types/invoice.type";
import { ZavannInvoiceStatus } from "enums/zavannInvoiceStatus.enum";
import { Link } from "components/Link";
import { FC } from "react";
import { InvoiceStatusTag } from "components/tags/InvoiceStatusTag";

interface Props {
  invoices: GetInvoiceItemResponseDTO[];
}

export const MobileVersion: FC<Props> = ({ invoices }) => {
  return (
    <div className="my-8 flex flex-col">
      <div className="overflow-hidden rounded-md shadow">
        <table className="min-w-full divide-y divide-gray-50">
          <tbody className="divide-y divide-gray-200 bg-white">
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                  {`${invoice.total_amount} kr`}
                  <dl className="font-normal">
                    <dt className="sr-only">Förfallodatum</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {`${
                        invoice.invoice_status_id ===
                        ZavannInvoiceStatus.Försenad
                          ? "Förföll "
                          : "Förfaller "
                      }den ${formatDateMonth(invoice.due_date)}`}
                    </dd>
                    <dt className="sr-only">Fakturanummer</dt>
                    <dd className="mt-1 truncate text-gray-500">
                      {invoice.id}
                    </dd>
                  </dl>
                </td>

                <td className="px-3 py-4 text-sm text-gray-500">
                  <InvoiceStatusTag status={invoice.invoice_status_id} />
                </td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium">
                  <Link
                    href={invoice.invoice_pdf}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visa
                    <span className="sr-only">, {invoice.id}</span>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
