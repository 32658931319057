import { useForm } from "react-hook-form";
import { useForgotPassword } from "api/user/user.endpoints";
import { TextInput } from "components/input/TextInput";
import { AuthFlowWrapper } from "components/AuthFlowWrapper";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Transition } from "components/animations/Transition";
import { config } from "react-spring";
import { SmoothUp } from "components/animations/SnapUp";
import { emailValidator } from "helpers/validation";
import { ForgotPasswordRequestDTO } from "../../api/user/user.dto";
import { useTranslation } from "react-i18next";

function ForgotPasswordPage() {
  const { t } = useTranslation();

  const {
    mutate: resetPassword,
    isPending: isLoading,
    isSuccess,
  } = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordRequestDTO>();

  const onSubmit = handleSubmit((data: ForgotPasswordRequestDTO) => {
    resetPassword(data);
  });

  return (
    <SmoothUp>
      <AuthFlowWrapper
        headerText={t("password.Reset your password")}
        onActionClick={onSubmit}
        isLoading={isLoading}
        buttonText={t("password.Submit")}
        buttonTextLoading={t("password.Submitting")}
        hideAction={isSuccess}
      >
        {isSuccess ? (
          <>
            <Transition
              settings={{
                from: {
                  transform: "scale(0)",
                },
                enter: {
                  transform: "scale(1)",
                },
                delay: 100,
                config: { ...config.wobbly },
              }}
              isVisible={true}
            >
              <CheckCircleIcon className="mx-auto h-10 w-auto text-flowerSky-400 mb-2" />
            </Transition>
            <p className="text-gray-700 text-center">
              {t(
                "password.Check your inbox to complete the password reset process",
              )}
            </p>
          </>
        ) : (
          <>
            <p className="text-gray-400 text-sm text-center">
              {t(
                "password.We will send a link to your email address with information on how to reset your password",
              )}
            </p>
            <TextInput
              register={register}
              name="email"
              label={t("password.Email")}
              type="email"
              registerOptions={emailValidator}
              errorMessage={errors.email?.message}
              autoFocus
            />
          </>
        )}
      </AuthFlowWrapper>
    </SmoothUp>
  );
}

export default ForgotPasswordPage;
