import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  appendix?: string;
}
export const Value: FC<Props> = ({ children, appendix }) => (
  <dd className="text-base text-gray-900 col-span-2">
    {children}
    {!!appendix && ` ${appendix}`}
  </dd>
);
