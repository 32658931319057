import { FlowerHubLogo } from "styles/Icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  header?: string;
  text?: string;
}
export const MaintenanceScreen: FC<Props> = ({
  header = "System maintenance ongoing!",
  text = "Functionality unavailable due to maintenance, Sorry for the inconvenience.",
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-screen flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Flower Hub</span>
            <img className="h-16 w-auto h-auto mx-auto" src={FlowerHubLogo} />
          </a>
        </div>
        <div className="py-12">
          <div className="text-center flex flex-col items-center">
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#ef4444"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>
            </div>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-flowerblue-700 sm:text-5xl">
              {t(`maintenance.${header}`)}
            </h1>
            <p className="mt-4 text-base text-gray-500 max-w-md">
              {" "}
              {t(`maintenance.${text}`)}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};
