import api from "../axiosInstance";
import { useTranslation } from "react-i18next";
import { useNotification } from "hooks/useNotification";
import { CollectResponseDTO, InitiateBankIDResponseDTO } from "./zavann.dto";
import { useMutation, useQuery } from "@tanstack/react-query";

const ENDPOINT = "/zavann";

export enum ZavannQueryKeys {
  PollQRCode = "zavann-poll-bank-id-qr-code",
  PollBankID = "zavann-poll-bank-id",
}

export const useInitiateBankIDSignature = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  return useMutation({
    mutationFn: async (): Promise<InitiateBankIDResponseDTO> =>
      (await api.post(`${ENDPOINT}/bankid`)).data,
    onError: () => {
      notification.error(
        t(
          "bankID.Something went wrong. Check your details in previous steps and try again",
        ),
      );
    },
  });
};

export const usePollBankIDGenerateQRCode = (id: string, settings = {}) =>
  useQuery({
    queryKey: [ZavannQueryKeys.PollQRCode, id],
    queryFn: async (): Promise<string> =>
      (await api.get(`${ENDPOINT}/bankid-qr-code/${id}`)).data,
    ...settings,
  });

export const usePollBankID = (id: string, settings = {}) =>
  useQuery({
    queryKey: [ZavannQueryKeys.PollBankID, id],
    queryFn: async (): Promise<CollectResponseDTO> =>
      (await api.get(`${ENDPOINT}/bankid/${id}`)).data,
    ...settings,
  });
