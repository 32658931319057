import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRLib from "qrcode";

interface Props {
  qrCode: string;
}

export const AnimatedQRCode: FC<Props> = ({ qrCode }) => {
  const { t } = useTranslation();

  const [qrImage, setQrImage] = useState<string>();

  useEffect(() => {
    QRLib.toDataURL(qrCode)
      .then((url: string) => {
        setQrImage(url);
      })
      .catch(() => {
        setQrImage(undefined);
      });
  }, [qrCode]);

  return (
    <div className="flex flex-col items-center">
      <p className="text-sm text-gray-500 mt-4 mb-4">
        {t("bankID.OpenBankIDAppAndScanQRCode")}
      </p>
      <img className="min-w-60 max-w-60" src={qrImage} />
    </div>
  );
};
