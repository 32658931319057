import { FC } from "react";

import { Badge, BadgeColor, BadgeSize } from "./Badge";
import { AssetOwnerStatus } from "enums/assetOwnerStatus.enum";

interface Props {
  status: AssetOwnerStatus;
  size?: BadgeSize;
}
export const AssetOwnerStatusTag: FC<Props> = ({ status, size = "small" }) => (
  <Badge color={getColor(status)} text={status} size={size} />
);

const getColor = (status: AssetOwnerStatus): BadgeColor => {
  switch (status) {
    case AssetOwnerStatus.AccountVerified:
      return "green";
    case AssetOwnerStatus.Invited:
      return "blue";
    case AssetOwnerStatus.InvitationExpired:
      return "yellow";
    default:
      return "gray";
  }
};
