import Dropdown from "components/input/Dropdown";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { Role } from "enums/role.enum";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { CreateInstallerInvitationRequestDTO } from "../../api/installerInvitation/installerInvitation.dto";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<CreateInstallerInvitationRequestDTO>;
  errors: Partial<FieldErrorsImpl<CreateInstallerInvitationRequestDTO>>;
}
export const AddInstallerUserForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`installer.Name`)}
        name="name"
        register={register}
        errorMessage={errors?.name?.message}
        registerOptions={noEmptyStringValidator()}
      />
      <TextInput
        label={t(`installer.Email`)}
        name="email"
        type="email"
        register={register}
        registerOptions={emailValidator}
        errorMessage={errors?.email?.message}
      />
      <Dropdown
        items={[
          { label: t(`installer.User`), value: Role.InstallerUser.toString() },
          {
            label: t(`installer.Admin`),
            value: Role.InstallerAdmin.toString(),
          },
        ]}
        name="role"
        label={t(`installer.Account type`)}
        register={register}
      />
    </ModalContentWrapper>
  );
};
