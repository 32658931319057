import { StepsDesktop } from "components/Steps";
import { FC } from "react";
import { getSteps, ViewProps } from "../SignUpFlowPage";
import { StepsComponentContainer } from "./StepsComponentContainer";

export const DesktopView: FC<ViewProps> = ({
  currentStep,
  setStep,
  setStartDate,
  startDate,
  invitation,
  assetOwnerDetails,
  setAssetOwnerDetails,
  setPassword,
  passwordIsEntered,
  isCollectCompleted,
  setIsCollectCompleted,
  setCollectResult,
}) => (
  <div className="flex flex-col items-center py-12 min-h-screen justify-around">
    <div className="flex">
      <div
        style={{
          position: "absolute",
          transform: "translate3d(-200px, -30px, 0)",
        }}
      >
        <StepsDesktop steps={getSteps(currentStep)} />
      </div>
      <div className="w-128">
        <div className="bg-white rounded-lg shadow-lg px-4 pt-8 pb-4">
          <StepsComponentContainer
            currentStep={currentStep}
            setStep={setStep}
            setStartDate={setStartDate}
            startDate={startDate}
            invitation={invitation}
            assetOwnerDetails={assetOwnerDetails}
            setAssetOwnerDetails={setAssetOwnerDetails}
            setPassword={setPassword}
            passwordIsEntered={passwordIsEntered}
            isCollectCompleted={isCollectCompleted}
            setIsCollectCompleted={setIsCollectCompleted}
            setCollectResult={setCollectResult}
          />
        </div>
      </div>
    </div>
  </div>
);
