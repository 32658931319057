import { FC } from "react";
import { StepProps } from "pages/sign-up-flow/SignUpFlowPage";
import { Button } from "pages/sign-up-flow/components/Button";

export const FinalStep: FC<StepProps> = ({ handleClick }) => {
  return (
    <form className="space-y-8 divide-y divide-gray-200 bg-white px-4 py-4 pb-8">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5 pt-6 sm:pt-4 lg:pt-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Grattis! Nu är du registrerad.
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-600 leading-6">
            Du kan nu logga in på Flower Hub-portalen genom att trycka på länken
            nedan.
          </p>
        </div>
        <Button handleClick={handleClick} label="Logga in" />
      </div>
    </form>
  );
};
