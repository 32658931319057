import React from "react";

interface Props {
  title: string;
}

export const Title = ({ title }: Props) => (
  <div className="px-2 justify-start items-start gap-2.5 flex">
    <div className="text-black text-normal font-medium leading-normal">
      {title}
    </div>
  </div>
);
