import { useEffect } from "react";
import { menuStore } from "store/menu.store";
import userStore, { getUserRole } from "store/user.store";

import { getAssetOwnerListBreadcrumb } from "helpers/assetOwnerList";
import { AssetOwners } from "../../../components/assetOwner/AssetOwners";
import { SmoothUp } from "../../../components/animations/SnapUp";

export const AssetOwnerListPage = () => {
  const { user } = userStore();
  const userRole = userStore(getUserRole);

  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs([getAssetOwnerListBreadcrumb()]);
  }, []);

  return (
    <SmoothUp>
      <AssetOwners
        userRole={userRole}
        distributorId={user?.distributorId}
        installerId={user?.installerId}
      />
    </SmoothUp>
  );
};
