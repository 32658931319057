import { useTranslation } from "react-i18next";
import { ActionButton } from "../../buttons/ActionButton";
import { FC } from "react";
import { cloudFrontDomainUrl } from "constants/environment";
import { getDeviceForMedia } from "helpers/installationFlow";

type Props = {
  isErrored: boolean;
  changeStep: (step: number) => void;
};

export const FinishingStep: FC<Props> = ({ isErrored, changeStep }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center space-y-8">
      <img
        src={`${cloudFrontDomainUrl}/installation-flow/${getDeviceForMedia()}/final-setup.jpg`}
        alt="Final setup"
        className="w-full lg:w-2/3"
      />

      <h2 className="text-xl font-bold text-center leading-tight">
        {isErrored
          ? t(`batteryOverview.We are sorry, unable to register Flower Hub`)
          : t(`batteryOverview.Connecting to Flower Hub...`)}
      </h2>
      <p className="text-base text-gray-800 text-center">
        {isErrored
          ? t(
              `batteryOverview.Double check the Serial number and retry the process, otherwise please contact distributor`,
            )
          : t(`batteryOverview.This might take a couple of minutes`)}
      </p>
      {isErrored && (
        <div className="flex w-full justify-end">
          <ActionButton
            onClick={() => changeStep(1)}
            label={t("batteryOverview.Return to the first step")}
            variant="primary"
          />
        </div>
      )}
    </div>
  );
};
