import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { Language } from "./i18n.constants";

import englishTranslation from "./translations/english.json";
import swedishTranslation from "./translations/swedish.json";

const resources = {
  [Language.English]: {
    translation: englishTranslation,
  },
  [Language.Swedish]: {
    translation: swedishTranslation,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: Language.English,
  pluralSeparator: "_",
});

export default i18next;
