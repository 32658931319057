import { DIGIT_REGEX } from "constants/regex";
import { FC, ChangeEvent, KeyboardEvent, RefObject } from "react";

interface SerialNumberInputProps {
  value: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>, index: number) => void;
  inputRefs: RefObject<(HTMLInputElement | null)[]>;
  error: string | null;
}

const SerialNumberInput: FC<SerialNumberInputProps> = ({
  value,
  onChange,
  onKeyDown,
  inputRefs,
  error,
}) => {
  return (
    <>
      <div className="mt-8 flex justify-center items-center space-x-4">
        <p className="text-xl lg:text-3xl font-bold">E</p>
        <div className="flex space-x-2">
          {value.map((val, index) => (
            <input
              key={index}
              ref={(el) => inputRefs.current && (inputRefs.current[index] = el)}
              id={`serial-number-input-${index}`}
              type="text"
              value={val}
              onChange={(e) => onChange(e, index)}
              onKeyDown={(e) => onKeyDown(e, index)}
              maxLength={1}
              className={`w-10 h-10 lg:w-12 lg:h-12 text-center text-sm lg:text-xl border rounded-md ${
                error && !value.every((v) => DIGIT_REGEX.test(v))
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
      {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
    </>
  );
};

export default SerialNumberInput;
