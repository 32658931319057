import { useAssetOwnerWithAssetId } from "api/assetOwner/assetOwner.endpoints";
import { PageWrapperSmall } from "components/PageWrapper";
import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getAssetOwnerId } from "store/user.store";
import { Role } from "enums/role.enum";
import CustomPlaceholder from "components/CustomPlaceholder";
import { Battery0Icon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { BatteryTab } from "../../../components/battery/BatteryTab";

export const BatteryPage = () => {
  const { t } = useTranslation();

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  const assetOwnerId = userStore(getAssetOwnerId) ?? -1;

  const {
    data: assetOwnerWithAssetId,
    isLoading,
    isError,
  } = useAssetOwnerWithAssetId(assetOwnerId);

  if (isLoading || isError || !assetOwnerWithAssetId?.assetId) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !assetOwnerWithAssetId?.assetId}
          icon={<Battery0Icon />}
          errorMessage={t(`batteryOverview.UnableToFetchTheBattery`)}
          emptyDataMessage={t(`batteryOverview.NoBattery`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <PageWrapperSmall>
      <BatteryTab
        userRole={Role.AssetOwner}
        assetId={assetOwnerWithAssetId.assetId}
      />
    </PageWrapperSmall>
  );
};

const getBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Battery,
    to: `${RouteUrl.App}/${RouteUrl.Battery}`,
  },
];
