import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const InverterIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 450 450"
      fill={color}
      stroke={color}
      strokeWidth="3"
    >
      <path d="M403.6,65.2H96.4c-10.59,0-19.2,8.61-19.2,19.2v292.8c0,10.59,8.61,19.2,19.2,19.2h16.8v21.6h26.4v16.8h33.6v-16.8h28.8v16.8h33.6v-16.8h28.8v16.8h33.6v-16.8h28.8v16.8h33.6v-16.8h26.4v-21.6h16.8c10.59,0,19.2-8.61,19.2-19.2V84.4c0-10.59-8.61-19.2-19.2-19.2ZM418,377.2c0,7.94-6.46,14.4-14.4,14.4H96.4c-7.94,0-14.4-6.46-14.4-14.4V84.4c0-7.94,6.46-14.4,14.4-14.4h307.2c7.94,0,14.4,6.46,14.4,14.4v292.8Z" />
      <circle cx="120.4" cy="353.2" r="9.6" />
      <circle cx="379.6" cy="353.2" r="9.6" />
      <circle cx="120.4" cy="108.4" r="9.6" />
      <circle cx="379.6" cy="108.4" r="9.6" />
      <polygon points="249.8 192.49 249.8 192.49 249.8 187.69 201.31 187.69 201.31 192.49 201.31 192.49 249.8 192.49" />
      <polygon points="218.99 205.11 218.99 205.11 218.99 200.31 201.31 200.31 201.31 205.11 201.31 205.11 218.99 205.11" />
      <polygon points="249.8 205.11 249.8 200.31 232.12 200.31 232.12 205.11 232.12 205.11 249.8 205.11 249.8 205.11" />
      <path d="M287.6,254.18c-1.78,2.61-2.9,4.09-5.03,4.09s-3.26-1.48-5.04-4.09c-1.88-2.75-4.23-6.18-9-6.18s-7.12,3.43-9,6.18c-1.79,2.61-2.9,4.09-5.04,4.09v4.8c4.78,0,7.12-3.43,9-6.18,1.79-2.61,2.9-4.09,5.04-4.09s3.25,1.48,5.04,4.09c1.88,2.75,4.23,6.18,9.01,6.18s7.12-3.43,9-6.18c1.78-2.61,2.9-4.09,5.03-4.09v-4.8c-4.78,0-7.12,3.43-9,6.18Z" />
      <path d="M181.8,298.06h137.65v-137.65h-137.65v137.65ZM190.21,293.26l124.43-124.43v124.43h-124.43ZM311.47,165.21l-124.87,124.87v-124.87h124.87Z" />
    </svg>
  );
};

export default InverterIcon;
