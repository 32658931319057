import { CreateSystemNotificationDTO } from "api/system-notification/systemNotification.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { DateTimePicker } from "components/input/DateTimePicker";
import Dropdown from "components/input/Dropdown";
import { TextArea } from "components/input/TextArea";
import { SystemNotificationType } from "enums/systemNotificationType.enum";
import { FC } from "react";
import { Control, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<CreateSystemNotificationDTO>;
  control: Control<CreateSystemNotificationDTO>;
  errors: Partial<
    FieldErrorsImpl<{
      message: string;
      startDate: string;
      endDate: string;
      type: SystemNotificationType;
    }>
  >;
}
export const AddSystemNotificationForm: FC<Props> = ({
  register,
  control,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      {/* Todo : start date end date validation and show error message when start date is greater than end date */}
      <DateTimePicker
        label={t(`systemNotification.Start Date`)}
        name="startDate"
        control={control}
        minDate={new Date()}
        validation={{
          required: t(`systemNotification.Start date is required`),
          validate: (value: string) => value !== "",
        }}
      />
      <DateTimePicker
        label={t(`systemNotification.End Date`)}
        name="endDate"
        control={control}
        minDate={new Date()}
        validation={{
          required: t(`systemNotification.End date is required`),
          validate: (value: string) => value !== "",
        }}
      />
      <Dropdown
        items={[
          { label: t(`systemNotification.Pick a type`), value: -1 },
          ...Object.values(SystemNotificationType).map((nt) => ({
            label: `${nt}`,
            value: nt,
          })),
        ]}
        name="type"
        label={t(`systemNotification.Type`)}
        register={register}
        validation={{
          validate: (value: string) =>
            +value !== -1 || t(`systemNotification.Select one from the list`),
        }}
        errorMessage={errors?.type?.message}
      />
      <TextArea
        label={t(`systemNotification.Message`)}
        name="message"
        register={register}
        validation={{
          validate: (value: string) =>
            value !== "" ||
            t(`systemNotification.Notification Message is required`),
        }}
        errorMessage={errors?.message?.message}
      />
    </ModalContentWrapper>
  );
};
