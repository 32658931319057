// enum also used as a key in json files for translation
export enum RouteLabel {
  Dashboard = "Dashboard",
  Organization = "Organization",
  Settings = "Settings",
  Distributors = "Distributors",
  Installers = "Installers",
  AssetOwners = "Asset owners",
  Battery = "Battery",
  Profile = "Profile",
  Invoices = "Invoices",
  ManualAction = "Manual Action",
  Assets = "Flower Hubs",
  ConsumptionAndRevenue = "Consumption & Revenue",
  BillingAdjustment = "Billing Adjustment",
}
