export enum BiddingRegion {
  SE1 = "SE1",
  SE2 = "SE2",
  SE3 = "SE3",
  SE4 = "SE4",
}

export const BIDDING_REGION_DROPDOWN_ITEMS = [
  {
    label: BiddingRegion.SE1,
    value: BiddingRegion.SE1,
  },
  {
    label: BiddingRegion.SE2,
    value: BiddingRegion.SE2,
  },
  {
    label: BiddingRegion.SE3,
    value: BiddingRegion.SE3,
  },
  {
    label: BiddingRegion.SE4,
    value: BiddingRegion.SE4,
  },
];
