import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import { useResendInvitation } from "api/assetOwnerInvitation/assetOwnerInvitation.endpoints";
import { useNotification } from "hooks/useNotification";
import { useQueryClient } from "@tanstack/react-query";
import SecondaryButton from "components/buttons/SecondaryButton";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

interface Props {
  assetOwnerId: number;
  installerId: number;
  oldInvitationId?: number;
}

export const ResendAssetOwnerInvitation: FC<Props> = ({
  assetOwnerId,
  installerId,
  oldInvitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotification();

  // api
  const { mutate: resendInvitation } = useResendInvitation({
    onSuccess: async () => {
      queryClient
        .invalidateQueries({
          queryKey: [AssetOwnerQueryKeys.GetProfile, assetOwnerId],
        })
        .then(() =>
          notification.info(t(`assetOwnerAccountDetails.InvitationResent`)),
        );
    },
  });

  // submit
  const onResendInvitation = () => {
    if (oldInvitationId && installerId) {
      resendInvitation({ oldInvitationId, installerId });
    }
  };

  return (
    <SecondaryButton size="small" onClick={onResendInvitation}>
      <ArrowPathIcon className="h-5 w-5 mr-1" />
      {t(`assetOwnerAccountDetails.ResendInvitation`)}{" "}
    </SecondaryButton>
  );
};
