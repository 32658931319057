import { classNames } from "../../helpers/classNames";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  isWide?: boolean;
}

export const ScrollablePage: FC<Props> = ({ children, isWide = false }) => (
  <div className="flex flex-col flex-1 mr-auto py-4 md:py-8 px-4 md:px-8 w-full h-full overflow-x-hidden overflow-y-auto">
    <div
      className={classNames(
        "flex flex-col",
        isWide ? "max-w-screen-2xl" : "max-w-screen-xl",
      )}
    >
      {children}
    </div>
  </div>
);
