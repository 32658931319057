import { isEmptyArray } from "helpers/array";
import React, { FC, useState } from "react";

import { useInstallersPagination } from "api/installer/installer.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddInstaller } from "./AddInstaller";
import { getInstallerPageRoute } from "helpers/installer";
import { GetAllInstallerResponseDTO } from "../../api/installer/installer.dto";
import { isDistributor } from "../../helpers/role";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import { InstallerTable } from "./InstallerTable";
import { InstallerCanInviteFilterStatus } from "../../enums/installerStatus.enum";
import {
  FilterDTO,
  FilterWithLabelDTO,
} from "../../api/pagination/pagination.type";
import { InstallerList } from "./InstallerList";

interface Props {
  userRole: Role;
  distributorId?: number;
}

export const Installers: FC<Props> = ({ userRole, distributorId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pagination query string state is defined to store the query string in the parent component
  const [queryString, setQueryString] = useState<string>("");

  // Infinite query to get installers with pagination
  const paginationQuery: UseInfiniteQueryResult<
    InfiniteData<GetAllInstallerResponseDTO>
  > = useInstallersPagination(queryString, {
    distributorId,
  });

  // Extract relevant information for this component from the pagination query
  const { data: installers, isLoading, isError, isSuccess } = paginationQuery;

  const flatInstallers = installers?.pages.flatMap((page) => page.items);

  const onInstallerClick = (installerId: number, distributorId: number) =>
    navigate(getInstallerPageRoute(userRole, installerId, distributorId));

  // Convert the filter options to the enum values for the installer can invite filter
  const createColumnFilter = (filters: FilterDTO[]): FilterWithLabelDTO[] =>
    filters.map((filter) => {
      if (filter.id === "canInviteAssetOwners") {
        return {
          id: filter.id,
          options: filter.options.map((option) => {
            return {
              label: option
                ? InstallerCanInviteFilterStatus.Yes
                : InstallerCanInviteFilterStatus.No,
              value: option,
            };
          }),
        };
      }
      return {
        id: filter.id,
        options: filter.options.map((option) => {
          return {
            label: option,
            value: option,
          };
        }),
      };
    });

  if (isLoading || isError) {
    return (
      <div className="flex h-full items-center lg:items-start lg:pt-8">
        <CustomPlaceholder
          isEmpty={false}
          isLoading={isLoading}
          isError={isError}
          icon={<UserIcon />}
          errorMessage={t(`installerList.Error`)}
          loaderSize="page"
        />
      </div>
    );
  }

  if (isSuccess && isEmptyArray(flatInstallers) && queryString === "") {
    return (
      <div className="flex flex-col h-full items-center justify-center lg:justify-start lg:pt-8">
        <CustomPlaceholder
          isEmpty={true}
          emptyDataMessage={t(`installerList.Empty`)}
          isLoading={false}
          isError={false}
          icon={<UserIcon />}
        />

        {isDistributor(userRole) && distributorId && (
          <AddInstaller distributorId={distributorId} isCentered />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {isDistributor(userRole) && distributorId && (
        <AddInstaller distributorId={distributorId} />
      )}
      {isMobile ? (
        <InstallerList
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onInstallerClick={onInstallerClick}
          createColumnFilter={createColumnFilter}
        />
      ) : (
        <InstallerTable
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onInstallerClick={onInstallerClick}
          createColumnFilter={createColumnFilter}
        />
      )}
    </div>
  );
};
