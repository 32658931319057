import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { EditAssetOwnerInvitationForm } from "./EditAssetOwnerInvitationForm";
import { UpdateAssetOwnerInvitationRequestDTO } from "api/assetOwner/assetOwner.dto";
import { useUpdateAssetOwnerInvitation } from "api/assetOwnerInvitation/assetOwnerInvitation.endpoints";

interface Props {
  assetOwner: UpdateAssetOwnerInvitationRequestDTO;
  oldInvitationId: number;
}

export const EditAssetOwnerInvitation: FC<Props> = ({
  assetOwner,
  oldInvitationId,
}) => {
  const { t } = useTranslation();

  const [isEditInvitationModalOpen, setIsEditInvitationModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateInvitation,
    isPending: isUpdating,
    isSuccess,
  } = useUpdateAssetOwnerInvitation(oldInvitationId);

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateAssetOwnerInvitationRequestDTO>();

  // submit
  const onSubmit = handleSubmit((data: UpdateAssetOwnerInvitationRequestDTO) =>
    updateInvitation(data),
  );

  // modal close
  const onEditInvitationModalClose = () => {
    setTimeout(() => reset(), 300);
    setIsEditInvitationModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onEditInvitationModalClose();
    }
  }, [isSuccess]);

  // Populate or clear the form every time isEditInvitationModalOpen is set
  useEffect(() => {
    if (isEditInvitationModalOpen) {
      reset({ ...assetOwner });
    }
  }, [isEditInvitationModalOpen]);

  const renderEditInvitationButton = () => {
    return (
      <div className="py-5 flex justify-end">
        <PencilSquareIcon
          className="h-5 w-5 text-gray-700 hover:text-flowerPeach-900 cursor-pointer"
          onClick={() => setIsEditInvitationModalOpen(true)}
        />
      </div>
    );
  };

  return (
    <>
      {renderEditInvitationButton()}

      <Modal
        isOpen={isEditInvitationModalOpen}
        rightButtonActionHandler={onSubmit}
        title={t(`assetOwnerAccountDetails.EditAssetOwner`)}
        rightButtonText={t(`Save`)}
        onClose={onEditInvitationModalClose}
        isLoading={isUpdating}
      >
        <EditAssetOwnerInvitationForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
