import CustomPlaceholder from "components/CustomPlaceholder";
import { FC } from "react";
import { Panel } from "components/Panel";
import { useCurrentFlowerHubSystemManagerForAsset } from "api/flowerHubSystemManager/flowerHubSystemManager.endpoints";
import { SystemManagerStatusTag } from "../../tags/SystemManagerStatusTag";
import { useTranslation } from "react-i18next";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";

interface Props {
  assetId: number;
}

export const FlowerHubSystemManager: FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();
  const { data, isError, isLoading } =
    useCurrentFlowerHubSystemManagerForAsset(assetId);

  return (
    <Panel
      header={t(`flowerHubSystemManager.Title`)}
      infoText={t(`flowerHubSystemManager.Info`)}
    >
      {isLoading || !data || isError ? (
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !data}
          icon={<Cog8ToothIcon />}
          errorMessage={t(`flowerHubSystemManager.UnableToLoad`)}
          emptyDataMessage={t(`flowerHubSystemManager.NotFound`)}
        />
      ) : (
        <dl className="mt-5 grid grid-cols-1 gap-3 sm:grid-cols-3">
          <DisplayItem
            title={t(`flowerHubSystemManager.PlatformVersion`)}
            content={data.platformVersion}
          />
          <DisplayItem
            title={t(`flowerHubSystemManager.ConnectionStatus`)}
            content={<SystemManagerStatusTag status={data.status} />}
          />
          <DisplayItem
            title={t(`flowerHubSystemManager.IPAddress`)}
            content={data.ipAddress}
          />
        </dl>
      )}
    </Panel>
  );
};

interface DisplayItemProp {
  title: string;
  content: React.ReactNode;
}

const DisplayItem: FC<DisplayItemProp> = ({ title, content }) => (
  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
    <dt className="truncate text-sm font-medium text-gray-500">{title}</dt>
    <dd className="mt-2 text-sm font-semibold tracking-tight text-gray-900 capitalize">
      {content}
    </dd>
  </div>
);
