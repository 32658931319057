import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { AddSystemNotificationForm } from "./AddSystemNotificationForm";
import {
  CreateSystemNotificationDTO,
  getEmptySystemNotificationDTO,
} from "api/system-notification/systemNotification.dto";
import { useCreateSystemNotification } from "api/system-notification/systemNotification.endpoints";

interface Props {
  centered?: boolean;
}

export const AddSystemNotification: FC<Props> = ({ centered = false }) => {
  const { t } = useTranslation();

  // state
  const [
    isAddSystemNotificationModalOpen,
    setIsAddSystemNotificationModalOpen,
  ] = useState<boolean>(false);

  // api
  const {
    mutate: createSystemNotification,
    isPending: isCreatingSystemNotification,
    isSuccess: isSystemNotificationSuccess,
  } = useCreateSystemNotification();

  // form
  const {
    register: registerSystemNotificationModal,
    handleSubmit: handleSystemNotificationModalSubmit,
    reset: resetSystemNotificationModal,
    control,
    formState: { errors: systemNotificationModalErrors },
  } = useForm<CreateSystemNotificationDTO>();

  // submit
  const onSystemNotificationModalSubmit = handleSystemNotificationModalSubmit(
    (data: CreateSystemNotificationDTO) =>
      createSystemNotification({
        ...data,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
      }),
  );

  // modal close
  const closeAddSystemNotificationModal = () => {
    setIsAddSystemNotificationModalOpen(false);
    setTimeout(
      () => resetSystemNotificationModal(getEmptySystemNotificationDTO()),
      300,
    );
  };

  // after submit success
  useEffect(() => {
    if (isSystemNotificationSuccess) {
      closeAddSystemNotificationModal();
    }
  }, [isSystemNotificationSuccess]);

  const renderAddSystemNotificationButton = () => {
    return (
      <div
        className={`py-4 flex ${centered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton
          onClick={() => setIsAddSystemNotificationModalOpen(true)}
        >
          <PlusCircleIcon className="h-5 w-5 mr-1" />
          {t(`systemNotification.Add system notification`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddSystemNotificationButton()}

      <Modal
        isOpen={isAddSystemNotificationModalOpen}
        onClose={closeAddSystemNotificationModal}
        leftButtonAction={closeAddSystemNotificationModal}
        leftButtonText={t(`Close`)}
        title={t(`systemNotification.Add system notification`)}
        rightButtonActionHandler={onSystemNotificationModalSubmit}
        isLoading={isCreatingSystemNotification}
      >
        <AddSystemNotificationForm
          register={registerSystemNotificationModal}
          control={control}
          errors={systemNotificationModalErrors}
        />
      </Modal>
    </>
  );
};
