import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { InfiniteQueryResult } from "../api/pagination/pagination.type";

export const useFetchMoreOnBottomReached = <T>(
  paginationQuery: InfiniteQueryResult<T>,
  offSetToTriggerPagination: number,
  setShowLoadingToast: Dispatch<SetStateAction<boolean>>,
  containerRef: RefObject<HTMLDivElement>,
) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    paginationQuery;

  const isFetchingRef = useRef(false);

  const numberOfPages = useMemo(() => data?.pages?.length ?? 0, [data]);

  const fetchMoreOnBottomReached = useCallback(
    (containerElement?: HTMLDivElement | null) => {
      if (containerElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerElement;
        setShowLoadingToast(
          scrollHeight - scrollTop - clientHeight < 20 * numberOfPages,
        );
        if (
          scrollHeight - scrollTop - clientHeight <
            offSetToTriggerPagination * numberOfPages &&
          hasNextPage &&
          !isFetchingRef.current &&
          !isFetchingNextPage
        ) {
          isFetchingRef.current = true;
          fetchNextPage().finally(() => {
            isFetchingRef.current = false;
          });
        }
      }
    },
    [
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage,
      numberOfPages,
      offSetToTriggerPagination,
      setShowLoadingToast,
    ],
  );

  // Attach scroll event to the container
  useEffect(() => {
    const tableContainerElement = containerRef.current;

    if (tableContainerElement) {
      const handleScroll = () =>
        fetchMoreOnBottomReached(tableContainerElement);
      tableContainerElement.addEventListener("scroll", handleScroll);

      return () => {
        tableContainerElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchMoreOnBottomReached]);
};
