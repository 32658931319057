import { Link } from "react-router-dom";
import userStore from "store/user.store";
import { useLogin } from "api/auth/auth.endpoints";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { refreshTokenHasExpired } from "store/refreshToken.store";
import { ErrorAlert } from "components/ErrorAlert";
import { RouteUrl } from "routing/routeUrl.enum";
import { LoginDTO } from "api/auth/auth.dto";
import { PasswordInput } from "components/input/PasswordInput";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import FlowerHubLogo from "/logos/flower-hub-logo.png";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { cloudFrontDomainUrl } from "constants/environment";

const transitionProps = {
  duration: 1,
  ease: "easeOut",
};

const fadeInFromRight = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
};

export const LoginPage = () => {
  const { t } = useTranslation();

  const { login, isError, isLoading } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors: loginErrors },
  } = useForm<LoginDTO>();

  const { user, clearUser } = userStore((state) => state);

  const onSubmit = handleSubmit((data: LoginDTO) => login(data));

  useEffect(() => {
    if (user && refreshTokenHasExpired()) {
      clearUser();
    }
  }, [user, clearUser]);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <motion.div
        className="relative flex items-center justify-center overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={fadeInFromRight}
        transition={transitionProps}
      >
        <div className="flex">
          <div className="hidden md:flex flex-1 flex-shrink-0 items-center justify-center bg-gray-100">
            <img
              src={`${cloudFrontDomainUrl}/image-1.png`}
              alt="Login Image"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="w-full flex-1 flex items-center justify-center md:bg-white md:rounded-r-[16px]">
            <div className="p-8 w-full max-w-sm">
              <div className="mb-16">
                <img
                  src={FlowerHubLogo}
                  alt={t("login.Logo")}
                  style={{ width: "267px", minWidth: "267px" }}
                  loading="eager"
                />
              </div>
              <h1 className="text-2xl font-semibold text-left">
                {t("login.Header")}
              </h1>
              <p className="text-base text-flowerGray-400 mb-8 text-left">
                {t("login.SubHeader")}
              </p>
              <form className="space-y-4">
                <div>
                  <TextInput
                    label={t("login.Email")}
                    name="username"
                    register={register}
                    size="full"
                    registerOptions={emailValidator}
                    type="email"
                    errorMessage={loginErrors?.username?.message}
                    tabIndex={1}
                  />
                </div>
                <div>
                  <PasswordInput
                    label={t("login.Password")}
                    name="password"
                    register={register}
                    registerOptions={noEmptyStringValidator()}
                    errorMessage={loginErrors?.password?.message}
                    tabIndex={2}
                  />
                </div>
                {isError && (
                  <ErrorAlert
                    text={t("login.Invalid credentials, please try again.")}
                  />
                )}

                <div className="flex items-center justify-between">
                  <div className="flex items-center"></div>

                  <div className="text-sm">
                    <Link
                      to={`/${RouteUrl.ForgotPassword}`}
                      className="font-medium text-flowerSky-300 hover:text-flowerSky-300 outline-gray-300 underline underline-offset-4"
                      tabIndex={4}
                    >
                      {t("login.Forgot your password?")}
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    onClick={onSubmit}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                    tabIndex={3}
                  >
                    {isLoading && (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    {isLoading ? t("login.Signing in") : t("login.Sign in")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
