import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { useCompleteFirstDeployment } from "api/flowerHub/flowerHub.endpoints";
import {
  FlowerHubInstallationProgressTitle,
  FlowerHubInstallationStatusMessageCode,
} from "enums/flowerHubStatus.enum";
import {
  getProgressInfo,
  mapInstallationFailureActionByMessageCode,
} from "helpers/installationProgress";
import { FlowerSymbolLogo } from "styles/Icons";
import { ActionButton } from "components/buttons/ActionButton";
import { debuggingGuide } from "constants/externalLinks";

type Prop = {
  serialNumber: string;
  installationStatus: FlowerHubInstallationStatusMessageCode;
};

const InstallationProgress: FC<Prop> = ({
  serialNumber,
  installationStatus,
}) => {
  const { t } = useTranslation();
  const { mutate: completeInstallation, isPending: isCompletingInstallation } =
    useCompleteFirstDeployment(serialNumber);

  const { percentage, title } = getProgressInfo(installationStatus);
  const actionMessage =
    mapInstallationFailureActionByMessageCode(installationStatus);

  const renderIcon = () => {
    switch (title) {
      case FlowerHubInstallationProgressTitle.ConfigurationFailed:
        return (
          <ExclamationTriangleIcon className="h-7 w-7 mr-2 text-red-500" />
        );
      case FlowerHubInstallationProgressTitle.ConfigurationPending:
        return (
          <ExclamationCircleIcon className="h-7 w-7 mr-2 text-orange-500" />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-6 w-full rounded-md mb-4">
      <div className="flex flex-col items-center">
        <img
          src={FlowerSymbolLogo}
          alt="Flower symbol"
          className="w-16 h-16 my-6 animate-spin-slow"
        />

        <span className="text-3xl font-medium mb-20">{serialNumber}</span>

        <div className="w-3/4 lg:w-1/2 rounded-full bg-gray-200 overflow-hidden">
          <div
            style={{ width: `${percentage}%` }}
            className="h-2 bg-flowerSkyVivid-500 rounded-full"
          />
        </div>

        <h2 className="mt-8 text-xl text-center flex items-center">
          {renderIcon()}
          {title}
        </h2>

        {installationStatus ===
        FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub ? (
          <div className="mt-8 text-gray-800 text-start">
            <p>
              {t(
                "flowerHubProgress.installerInstallationProgressMessageForInstallerView.NotReceivedSignalFromFlowerHub_I",
              )}
            </p>
            <ul className="list-disc pl-8 pt-2">
              <li>
                {t("flowerHubProgress.Flower Hub is connected to the internet")}
              </li>
              <li>
                {t(
                  "flowerHubProgress.The correct serial number has been entered",
                )}
              </li>
            </ul>
          </div>
        ) : title ===
          FlowerHubInstallationProgressTitle.ConfigurationCompleted ? (
          <>
            <p className="my-8 text-gray-800 text-center">
              {t(
                "flowerHubProgress.Flower Hub has been configured successfully. Please proceed to the next step of the installation",
              )}
            </p>
            <ActionButton
              onClick={() => completeInstallation(serialNumber)}
              label={t("Next")}
              variant="primary"
              disabled={isCompletingInstallation}
            />
          </>
        ) : (
          <p className="mt-8 text-gray-800 text-center">
            {t(
              `flowerHubProgress.installerInstallationProgressMessageForInstallerView.${installationStatus}`,
            )}
          </p>
        )}

        {actionMessage && (
          <div className="rounded-md bg-blue-100 p-2 sm:mt-10 mt-4">
            <div className="flex">
              <div className="flex-shrink-0 mt-[2px]">
                <LightBulbIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-blue-500"
                />
              </div>
              <div className="ml-1 flex-1 md:flex">
                <p className="text-sm text-blue-700 text-left whitespace-pre-line">
                  <Trans
                    i18nKey={`flowerHubProgress.installationProgressErrorDebuggingGuideAction.${actionMessage}`}
                    components={{
                      DebuggingGuideLink: (
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={debuggingGuide}
                          style={{ textDecoration: "underline" }}
                        />
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstallationProgress;
