import { CreateAssetOwnerRequestDTO } from "./assetOwner.dto";

export const getEmptyCreateAssetOwnerDTO = (
  installerId?: number,
): CreateAssetOwnerRequestDTO => ({
  installerId: installerId ?? -1,
  mainEmail: "",
  firstName: "",
  lastName: "",
  address: {
    street: "",
    postalCode: "",
    city: "",
  },
});
