import { UpdateFlowerSettingsRequestDTO } from "api/flowerSettings/flowerSettings.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { positiveNumberValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<UpdateFlowerSettingsRequestDTO>;
  errors: Partial<FieldErrorsImpl<UpdateFlowerSettingsRequestDTO>>;
}
export const EditGeneralSettingsForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`flowerSettings.asset owner compensation`)}
        name="compensationPerKwAssetOwner"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwAssetOwner?.message}
        type="number"
        appendix={t(`flowerSettings.compensation unit`)}
        size="half"
      />
      <TextInput
        label={t(`flowerSettings.pilot asset owner compensation`)}
        name="compensationPerKwPilotAssetOwner"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwPilotAssetOwner?.message}
        type="number"
        appendix={t(`flowerSettings.compensation unit`)}
        size="half"
      />
      <TextInput
        label={t(`flowerSettings.installer compensation`)}
        name="compensationPerKwInstaller"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwInstaller?.message}
        type="number"
        appendix={t(`flowerSettings.compensation unit`)}
        size="half"
      />
      <TextInput
        label={t(`flowerSettings.distributor compensation`)}
        name="compensationPerKwDistributor"
        register={register}
        registerOptions={positiveNumberValidator}
        errorMessage={errors.compensationPerKwDistributor?.message}
        type="number"
        appendix={t(`flowerSettings.compensation unit`)}
        size="half"
      />
    </ModalContentWrapper>
  );
};
