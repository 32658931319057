import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import SecondaryButton from "../buttons/SecondaryButton";
import { useForm } from "react-hook-form";
import {
  AssetOwnerQueryKeys,
  useUpdateAssetOwner,
} from "api/assetOwner/assetOwner.endpoints";
import {
  GetAssetOwnerProfileResponseDTO,
  UpdateAssetOwnerRequestDTO,
} from "api/assetOwner/assetOwner.dto";
import { EditAssetOwnerProfileForm } from "./EditAssetOwnerProfileForm";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  assetOwnerProfile: GetAssetOwnerProfileResponseDTO;
}

export const EditAssetOwnerProfile: FC<Props> = ({ assetOwnerProfile }) => {
  const { t } = useTranslation();

  const [isEditAssetOwnerModalOpen, setIsEditAssetOwnerModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateAssetOwner,
    isPending: isUpdating,
    isSuccess: isUpdateAssetOwnerSuccess,
  } = useUpdateAssetOwner(assetOwnerProfile.id);

  // form
  const {
    register: registerUpdateAssetOwner,
    handleSubmit: handleUpdateAssetOwnerSubmit,
    reset: resetUpdateAssetOwner,
    formState: { errors: updateAssetOwnerErrors },
  } = useForm<UpdateAssetOwnerRequestDTO>();

  // submit
  const onUpdateProfileSubmit = handleUpdateAssetOwnerSubmit(
    (payload: UpdateAssetOwnerRequestDTO) => updateAssetOwner(payload),
  );

  // modal close
  const onEditAssetOwnerModalClose = () => {
    setIsEditAssetOwnerModalOpen(false);
    setTimeout(() => resetUpdateAssetOwner(), 300);
  };

  // after submit success
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isUpdateAssetOwnerSuccess) {
      onEditAssetOwnerModalClose();

      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetProfile, assetOwnerProfile.id],
      });
    }
  }, [isUpdateAssetOwnerSuccess]);

  // Populate or clear the form every time isEditAssetOwnerModalOpen is set
  useEffect(() => {
    if (isEditAssetOwnerModalOpen) {
      resetUpdateAssetOwner({
        ...assetOwnerProfile,
      });
    } else {
      setTimeout(() => resetUpdateAssetOwner(), 300);
    }
  }, [isEditAssetOwnerModalOpen]);

  const renderEditAssetOwnerButton = () => {
    return (
      <div className="flex justify-end mb-4">
        <SecondaryButton onClick={() => setIsEditAssetOwnerModalOpen(true)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />{" "}
          {t(`assetOwnerAccountDetails.EditAssetOwner`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderEditAssetOwnerButton()}

      <Modal
        title={t(`assetOwnerAccountDetails.EditAssetOwner`)}
        rightButtonText={t(`Save`)}
        rightButtonActionHandler={onUpdateProfileSubmit}
        isOpen={isEditAssetOwnerModalOpen}
        onClose={onEditAssetOwnerModalClose}
        isLoading={isUpdating}
      >
        <EditAssetOwnerProfileForm
          register={registerUpdateAssetOwner}
          errors={updateAssetOwnerErrors}
        />
      </Modal>
    </>
  );
};
