import { AssetStep } from "components/installationFlow/installFlowerHub.helpers";
import React, { useEffect, useState } from "react";
import { InverterType } from "enums/inverterType.enum";
import { useCreateAsset } from "api/asset/asset.endpoints";
import {
  AssetInformation,
  AssetInformationStep,
} from "components/installationFlow/steps/AssetInformationStep";
import { Fuse } from "components/installationFlow/bessTypes";
import { ConnectedEthStep } from "./steps/ConnectedEthStep";
import { SerialNumberStep } from "./steps/SerialNumberStep";
import { MetaDataConfirmationStep } from "./steps/MetaDataConfirmationStep";
import { PowerConfirmationStep } from "./steps/PowerConfirmationStep";
import InstallationStepProgress from "./progress/InstallationStepProgress";
import { FinishingStep } from "./steps/FinishingStep";

interface Props {
  assetOwnerId: number;
}

export const InstallFlowerHub: React.FC<Props> = ({ assetOwnerId }) => {
  // states
  const [currentStep, setCurrentStep] = useState<AssetStep>(
    AssetStep.CONNECT_ETH,
  );

  const [assetInformation, setAssetInformation] = useState<AssetInformation>(
    {},
  );

  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    undefined,
  );

  const [fuse, setFuse] = useState<Fuse>();

  // api
  const { mutate: createAsset, isError } = useCreateAsset();

  useEffect(() => {
    if (currentStep === AssetStep.FINISHING) {
      onSubmitAsset();
    } else if (currentStep === AssetStep.CONNECT_ETH) {
      resetAllTheSteps();
    }
  }, [currentStep]);

  // submit
  const onSubmitAsset = () => {
    if (
      assetInformation.assetModel &&
      assetInformation.configuration &&
      assetInformation.manufacturer &&
      assetInformation.inverterPowerCapacity &&
      fuse &&
      serialNumber
    ) {
      return createAsset({
        assetModelId: assetInformation.assetModel.dbEntryId,
        assetOwnerId: assetOwnerId,
        inverterPowerCapacity: assetInformation.inverterPowerCapacity,
        bessPowerCapacity: assetInformation.configuration.ratedPower,
        bessCapacity: assetInformation.configuration.ratedEnergy,
        serialNumber: serialNumber,
        // We are hardcoding this until we have a need for other inverter types
        inverterType: InverterType.Hybrid,
        fuseSize: fuse.ampere,
      });
    }
  };

  const resetAllTheSteps = () => {
    setAssetInformation({});
    setSerialNumber("");
    setFuse(undefined);
  };

  return (
    <div className="flex justify-center bg-white rounded-md">
      <div className="p-6 lg:px-10 w-full">
        {currentStep !== AssetStep.CONNECT_ETH &&
          currentStep !== AssetStep.FINISHING && (
            <InstallationStepProgress currentStep={currentStep} />
          )}
        <>
          {currentStep === AssetStep.CONNECT_ETH ? (
            <ConnectedEthStep changeStep={setCurrentStep} />
          ) : currentStep === AssetStep.ASSET_INFORMATION ? (
            <AssetInformationStep
              changeStep={setCurrentStep}
              assetInformation={assetInformation}
              setAssetInformation={setAssetInformation}
              fuse={fuse}
              setFuse={setFuse}
            />
          ) : currentStep === AssetStep.SERIAL_NUMBER ? (
            <SerialNumberStep
              changeStep={setCurrentStep}
              serialNumber={serialNumber}
              setSerialNumber={setSerialNumber}
            />
          ) : currentStep === AssetStep.META_DATA_CONFIRMATION ? (
            <MetaDataConfirmationStep
              changeStep={setCurrentStep}
              assetInformation={assetInformation}
              assetSerialNumber={serialNumber}
              fuse={fuse}
            />
          ) : currentStep === AssetStep.POWER_CONFIRMATION ? (
            <PowerConfirmationStep changeStep={setCurrentStep} />
          ) : currentStep === AssetStep.FINISHING ? (
            <FinishingStep isErrored={isError} changeStep={setCurrentStep} />
          ) : null}
        </>
      </div>
    </div>
  );
};
