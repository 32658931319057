import { isDev, isLocal, isProd } from "./environment";

//Only certen flower users allow to post billing adjustments
const LocalPostBillingAdjustmentAllowedUserIDs = [1];
const DevPostBillingAdjustmentAllowedUserIDs = [71, 198]; // Usernames = I, A
const ProdPostBillingAdjustmentAllowedUserIDs = [8, 647, 981]; // Usernames = E, M, A

export const postBillingAdjustmentAllowedUserIDs = (): number[] => {
  if (isLocal()) return LocalPostBillingAdjustmentAllowedUserIDs;
  if (isDev()) return DevPostBillingAdjustmentAllowedUserIDs;
  if (isProd()) return ProdPostBillingAdjustmentAllowedUserIDs;
  return [];
};
