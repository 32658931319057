import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const TR: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableRowElement>,
      HTMLTableRowElement
    >
> = ({ children, ...props }) => (
  <tr
    {...props}
    className={props.onClick ? "cursor-pointer hover:opacity-75" : ""}
  >
    {children}
  </tr>
);
