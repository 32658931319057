import api from "api/axiosInstance";
import { DashboardStatisticsResponseDTO } from "./statistics.dto";
import { StatisticPeriodType } from "enums/statistic.enum";
import { useQuery } from "@tanstack/react-query";

const ENDPOINT = "/statistics";

export enum DashboardQueryKeys {
  GetDashboardStatistics = "get-dashboard-statistics",
}

export const useDashboardStatistics = (
  filterDistributorId?: number,
  filterInstallerId?: number,
  periodType?: StatisticPeriodType,
) => {
  const params = new URLSearchParams();

  if (filterDistributorId !== undefined) {
    params.append("distributorId", filterDistributorId.toString());
  }
  if (filterInstallerId !== undefined) {
    params.append("installerId", filterInstallerId.toString());
  }
  if (periodType !== undefined) {
    params.append("periodType", periodType.toString());
  }

  const queryString = params.toString();
  const url = queryString
    ? `${ENDPOINT}/dashboard?${queryString}`
    : `${ENDPOINT}/dashboard`;

  return useQuery({
    queryKey: [DashboardQueryKeys.GetDashboardStatistics, queryString],
    queryFn: async (): Promise<DashboardStatisticsResponseDTO> =>
      (await api.get(url)).data,
  });
};
