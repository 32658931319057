import { classNames } from "helpers/classNames";
import React, { FC } from "react";

export const Item: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className = "",
}) => (
  <div
    className={classNames(
      "grid grid-cols-1 md:grid-cols-3 gap-4 p-6 items-center",
      className,
    )}
  >
    {children}
  </div>
);
