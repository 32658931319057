import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { FlowerHubStatusTag } from "../tags/FlowerHubStatusTag";
import { AssetItemDTO } from "../../api/asset/asset.dto";
import { Table } from "../tableBeta/Table";
import { useTranslation } from "react-i18next";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { FlowerHubStatusDTO } from "api/flowerHub/flowerHub.dto";

interface Props {
  paginationQuery: InfiniteQueryResult<AssetItemDTO>;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  onAssetClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const FlowerHubTable: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onAssetClick,
  createColumnFilter,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: AssetItemDTO) => {
    if (row)
      onAssetClick(row.assetOwnerId, row.installer.id, row.distributorId);
  };

  // Table columns definition
  const columns: ColumnDef<AssetItemDTO>[] = useMemo(
    () => [
      {
        accessorKey: "serialNumber",
        header: `${t("FlowerHubList.columnHeader.serialNumber")}`,
      },
      {
        accessorKey: "flowerHubStatus",
        header: `${t("FlowerHubList.columnHeader.flowerHubStatus")}`,
        cell: (row) => {
          if (!row.row.original?.flowerHubStatus) {
            return null;
          }
          return (
            <FlowerHubStatusTag
              flowerHubStatus={
                row.row.original?.flowerHubStatus as FlowerHubStatusDTO
              }
            />
          );
        },
        enableSorting: false,
      },
      {
        accessorKey: "model",
        header: `${t("FlowerHubList.columnHeader.model")}`,
        cell: (row) => {
          return (
            <div className="flex flex-col truncate leading-5">
              <span className="truncate">
                {row.row.original?.assetModel.manufacturer}
              </span>
              <span className="truncate">
                {row.row.original?.assetModel.name}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "bessPowerCapacity",
        header: `${t("FlowerHubList.columnHeader.bessPowerCapacity")}`,
        accessorFn: (row: AssetItemDTO) => `${row.bessPowerCapacity} kW`,
      },
      {
        accessorKey: "bessCapacity",
        header: `${t("FlowerHubList.columnHeader.bessCapacity")}`,
        accessorFn: (row: AssetItemDTO) => `${row.bessCapacity} kWh`,
      },
      {
        accessorKey: "inverterPowerCapacity",
        header: `${t("FlowerHubList.columnHeader.inverterPowerCapacity")}`,
        accessorFn: (row: AssetItemDTO) => `${row.inverterPowerCapacity} kW`,
      },
      {
        accessorKey: "installerName",
        header: `${t("FlowerHubList.columnHeader.installerName")}`,
        accessorFn: (row: AssetItemDTO) => `${row.installer.name}`,
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      paginationQuery={paginationQuery}
      onSelectRow={handleRowClick}
      setPaginationUrl={setPaginationUrl}
      title={"Flower Hubs"}
      searchPlaceholder={`${t("FlowerHubList.SearchForFlowerHubs")}`}
      createColumnFilter={createColumnFilter}
    />
  );
};
