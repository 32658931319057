import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const HomeIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      width="79"
      height="auto"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 48L52 18L95 48" stroke={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 48.5L52 26L86 48.5V96H18V48.5ZM47 71H32V95.5H47V71Z"
        fill={color}
      />
      <path
        d="M52 26L52.2759 25.583L52 25.4004L51.7241 25.583L52 26ZM18 48.5L17.7241 48.083L17.5 48.2313V48.5H18ZM86 48.5H86.5V48.2313L86.2759 48.083L86 48.5ZM86 96V96.5H86.5V96H86ZM18 96H17.5V96.5H18V96ZM32 71V70.5H31.5V71H32ZM47 71H47.5V70.5H47V71ZM32 95.5H31.5V96H32V95.5ZM47 95.5V96H47.5V95.5H47ZM51.7241 25.583L17.7241 48.083L18.2759 48.917L52.2759 26.417L51.7241 25.583ZM86.2759 48.083L52.2759 25.583L51.7241 26.417L85.7241 48.917L86.2759 48.083ZM86.5 96V48.5H85.5V96H86.5ZM18 96.5H86V95.5H18V96.5ZM17.5 48.5V96H18.5V48.5H17.5ZM32 71.5H47V70.5H32V71.5ZM32.5 95.5V71H31.5V95.5H32.5ZM47 95H32V96H47V95ZM46.5 71V95.5H47.5V71H46.5Z"
        fill={color}
      />
      <path d="M26 21V35L34 30.5V21H26Z" fill={color} stroke={color} />
    </svg>
  );
};

export default HomeIcon;
