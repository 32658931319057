import {
  FC,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import SerialNumberInput from "components/input/SerialNumberInput";
import { isSerialNumberComplete } from "../installFlowerHub.helpers";
import { ActionButton } from "../../buttons/ActionButton";
import { SINGLE_DIGIT_REGEX } from "constants/regex";
import { cloudFrontDomainUrl } from "constants/environment";
import { getDeviceForMedia } from "helpers/installationFlow";

type SerialNumberStepProps = {
  changeStep: (step: number) => void;
  serialNumber?: string;
  setSerialNumber: (serialNumber: string | undefined) => void;
};

export const SerialNumberStep: FC<SerialNumberStepProps> = ({
  changeStep,
  serialNumber,
  setSerialNumber,
}) => {
  const [inputCode, setInputCode] = useState<string[]>(new Array(6).fill(""));
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (serialNumber && serialNumber.length === 7) {
      const serialArray = serialNumber.slice(1, 7).split("");
      setInputCode(serialArray);
      inputRefs.current[5]?.focus();
    } else {
      inputRefs.current[0]?.focus();
    }
  }, []);

  useEffect(() => {
    setSerialNumber(`E${inputCode.join("")}`);
  }, [inputCode, setSerialNumber]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;

    if (SINGLE_DIGIT_REGEX.test(value)) {
      const newCode = [...inputCode];
      newCode[index] = value;
      setInputCode(newCode);

      // Move focus to the next input
      if (value && index < inputCode.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
      setError(null);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !inputCode[index] && index > 0) {
      // Focus previous input on backspace if current is empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div className="flex flex-col items-center space-y-8">
      <img
        src={`${cloudFrontDomainUrl}/installation-flow/${getDeviceForMedia()}/check-serial-number.jpg`}
        alt="Check serial number"
        className="w-2/3"
      />

      <h2 className="text-xl font-bold text-center leading-tight">
        {t("batteryOverview.Enter the Flower Hub serial number")}
      </h2>
      <p className="text-base text-gray-800 text-center">
        {t(
          "batteryOverview.Look for the serial number on the back of the device below the Flower Hub logo",
        )}
      </p>

      <SerialNumberInput
        value={inputCode}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        inputRefs={inputRefs}
        error={error}
      />

      <div className="flex justify-between w-full">
        <ActionButton
          onClick={() => changeStep(2)}
          label={t("Back")}
          variant="black"
        />
        <ActionButton
          onClick={() => changeStep(4)}
          label={t("Next")}
          variant="primary"
          disabled={!isSerialNumberComplete(serialNumber)}
        />
      </div>
    </div>
  );
};
