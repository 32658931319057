import CustomPlaceholder from "components/CustomPlaceholder";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "components/Panel";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";
import { useFlowerHubComponents } from "api/flowerHub/flowerHub.endpoints";

interface Props {
  serialNumber: string;
}

export const FlowerHubComponents: FC<Props> = ({ serialNumber }) => {
  const { t } = useTranslation();

  const { data, isError, isLoading } = useFlowerHubComponents(serialNumber);

  return (
    <Panel
      header={t(`flowerHubComponents.Title`)}
      infoText={t(`flowerHubComponents.Info`)}
    >
      <nav className="mx-auto max-w-7xl" aria-label="Progress">
        {isLoading || data?.length === 0 || isError ? (
          <CustomPlaceholder
            isError={isError}
            isLoading={isLoading}
            isEmpty={!isLoading && !isError && data?.length === 0}
            icon={<PresentationChartLineIcon />}
            errorMessage={t(`flowerHubComponents.UnableToLoad`)}
            emptyDataMessage={t(`flowerHubComponents.NoComponents`)}
          />
        ) : (
          <dl className="grid grid-cols-1 overflow-hidden md:grid-cols-4">
            {data?.map((component) => (
              <DisplayItem
                key={component.name}
                label={t([
                  `flowerHubComponents.components.${component.name}`,
                  `flowerHubComponents.components.fallback`,
                ])}
                status={component.status}
              />
            ))}
          </dl>
        )}
      </nav>
    </Panel>
  );
};

interface DisplayItemProp {
  label: string;
  status: React.ReactNode;
}

const DisplayItem: FC<DisplayItemProp> = ({ label, status }) => (
  <div key={label} className="px-2 py-3 sm:p-3 m-3 rounded-lg bg-white shadow">
    <dt className="text-gray-500 text-sm font-medium">{label}</dt>
    <dd className="mt-2 flex items-baseline justify-between md:block lg:flex">
      <div className="flex items-baseline text-xs font-semibold tracking-tight text-gray-900">
        {status ?? "-"}
      </div>
    </dd>
  </div>
);
