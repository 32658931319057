import { PageWrapper } from "components/PageWrapper";
import { useEffect } from "react";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getAssetOwnerId } from "store/user.store";
import { Invoices } from "../../../components/invoices/Invoices";
import { useActiveZavannSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import { SystemNotificationInfo } from "components/systemNotification/SystemNotificationInfo";

export const InvoicesPage = () => {
  const assetOwnerId = userStore(getAssetOwnerId) || -1;

  //Get active zavann system notifications
  const { data: systemNotification } = useActiveZavannSystemNotifications();

  const { setBreadCrumbs } = menuStore();

  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  if (systemNotification) {
    return <SystemNotificationInfo notification={systemNotification} />;
  }

  return (
    <PageWrapper>
      <Invoices assetOwnerId={assetOwnerId} loaderSize="page" />
    </PageWrapper>
  );
};

const getBreadcrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Invoices,
    to: `${RouteUrl.App}/${RouteUrl.InvoiceList}`,
  },
];
