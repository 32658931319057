import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateDistributorInvitationRequestDTO,
  CreateDistributorInvitationResponseDTO,
} from "./distributorInvitation.dto";
import { DistributorQueryKeys } from "api/distributor/distributor.endpoints";
import { SignupByCodeRequestDTO } from "../shared/signUpByCode.dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "/distributor-invitation";

export const useDistributorSignUp = () => {
  return useMutation({
    mutationFn: async (payload: SignupByCodeRequestDTO) =>
      (await api.post(`${ENDPOINT}/sign-up-by-code`, payload)).data,
  });
};

export const useInviteDistributorUser = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateDistributorInvitationRequestDTO,
    ): Promise<CreateDistributorInvitationResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (result: CreateDistributorInvitationResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [DistributorQueryKeys.GetOne, result.distributorId],
      });
      queryClient.invalidateQueries({
        queryKey: [DistributorQueryKeys.GetUsers, result.distributorId],
      });
      notification.info(`${result.name} invited`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
    ...settings,
  });
};

export const useDeleteDistributorInvitation = (settings = {}) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  return useMutation({
    mutationFn: async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DistributorQueryKeys.GetOne],
      });
      notification.info("Invitation deleted");
    },
    onError: () => {
      notification.error();
    },
    ...settings,
  });
};
