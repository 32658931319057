import React from "react";
import { Row } from "@tanstack/react-table";
import { Virtualizer } from "@tanstack/react-virtual";
import { TRB } from "./TRB";
import { classNames } from "../../helpers/classNames";

interface Props<T> {
  tableContainerRef: React.RefObject<HTMLDivElement>;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  rows: Row<T>[];
  onSelectRow?: (data: T) => void;
  isRefetching: boolean;
}

export const TBody = <T,>({
  tableContainerRef,
  rowVirtualizer,
  rows,
  onSelectRow,
  isRefetching,
}: Props<T>): React.ReactElement => (
  <div
    className="relative overflow-auto rounded-bl-lg rounded-br-lg h-full"
    ref={tableContainerRef}
  >
    <div
      className={
        (classNames("grid relative h-full rounded-lg"),
        isRefetching ? "opacity-50" : "")
      }
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
        <TRB
          key={virtualRow.index}
          rows={rows}
          virtualRow={virtualRow}
          rowVirtualizer={rowVirtualizer}
          onSelectRow={isRefetching ? undefined : onSelectRow}
        />
      ))}
    </div>
    {rowVirtualizer.getVirtualItems().length === 0 && (
      <div className="flex justify-center items-center h-36">
        <span className="text-gray-400">No data available.</span>
      </div>
    )}
  </div>
);
