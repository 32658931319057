import React, { FC } from "react";
import { SmoothUp } from "./animations/SnapUp";

interface Props {
  children: React.ReactNode;
}
export const PageWrapper: FC<Props> = ({ children }) => {
  return (
    <SmoothUp>
      <>{children}</>
    </SmoothUp>
  );
};

export const PageWrapperSmall: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <SmoothUp>
    <div className="max-w-3xl">{children}</div>
  </SmoothUp>
);
