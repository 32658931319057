import React, { FC } from "react";
interface Props {
  children: React.ReactNode;
}
export const Table: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableElement>,
      HTMLTableElement
    >
> = ({ children, ...rest }) => (
  <div className="flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-1">
      <div className="inline-block min-w-full py-2 align-middle md:px-1">
        <div className="overflow-hidden shadow md:rounded-lg  ring-opacity-5">
          <table {...rest} className="min-w-full divide-y divide-gray-300">
            {children}
          </table>
        </div>
      </div>
    </div>
  </div>
);
