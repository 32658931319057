import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  signWithQrCode: () => void;
  signOnSameDevice: () => void;
  url: string;
  text: string;
}

export const SignOptions: FC<Props> = ({
  signWithQrCode,
  signOnSameDevice,
  text,
}) => {
  const { t } = useTranslation();

  const classNames =
    "border border-white bg-flowerSky-600 hover:bg-flowerSky-700 focus:ring-flowerSky-600 text-white w-full inline-flex justify-center items-center rounded-md shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm mt-8";

  return (
    <div className="sm:grid sm:grid-cols-4 sm:gap-1">
      <p className="text-sm font-medium leading-6 text-gray-600 sm:col-span-6">
        {text}
      </p>
      <div className="sm:col-span-4">
        <button className={classNames} onClick={signWithQrCode}>
          <span>{t("bankID.UseBankIDOnAnotherDevice")}</span>
          <img
            className={`h-8  "w-8"  pl-2 fill-red-900`}
            src="/qrCodeLogo.svg"
            alt=""
          />
        </button>
      </div>
      <div className="sm:col-span-4 -mt-5">
        <button className={classNames} onClick={signOnSameDevice}>
          <span>{t("bankID.UseBankIDOnThisDevice")}</span>
          <img
            className={`h-8  "w-12" pl-2 fill-red-900`}
            src="/bankIdLogo.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  );
};
