import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
interface Props {
  label?: string;
  name: string;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  tabIndex?: number;
  errorMessage?: string;
}
export const PasswordInput: FC<Props> = ({
  label = "Password",
  name,
  register,
  registerOptions,
  tabIndex,
  errorMessage,
}) => {
  const hasError = !!errorMessage;
  const [showPassword, setShowPassword] = useState(false);

  const toggleShow = () => setShowPassword((prev) => !prev);

  if (!registerOptions) {
    registerOptions = {};
  }

  return (
    <div>
      <label htmlFor={name} className="flex text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={"mt-1 relative rounded-md shadow-sm"}>
        <input
          type={showPassword ? "text" : "password"}
          className={
            "block w-full rounded-md shadow-sm sm:text-sm border-gray-300 focus:border-flowerSky-500 focus:ring-flowerSky-500"
          }
          {...register(name, registerOptions && registerOptions)}
          required
          tabIndex={tabIndex ? tabIndex : 0}
        />
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mr-7">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        <div
          onClick={toggleShow}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              toggleShow();
            }
          }}
          className="absolute inset-y-0 right-0 flex items-center px-2 outline-flowerSky-500 focus:rounded-r-md cursor-pointer"
          tabIndex={tabIndex ? tabIndex + 1 : 0}
        >
          {!showPassword && <EyeIcon className="h-6 w-6 text-gray-500" />}
          {showPassword && <EyeSlashIcon className="h-6 w-6 text-gray-500" />}
        </div>
      </div>
      {hasError && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
};
