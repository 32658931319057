import { classNames } from "helpers/classNames";
import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  isFirstColumn?: boolean;
  alignRight?: boolean;
}

export const TH: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >
> = ({ children, isFirstColumn = false, alignRight = false, ...rest }) => (
  <th
    className={classNames(
      "py-3 text-white text-left text-xs uppercase",
      isFirstColumn
        ? "pl-4 pr-3 sm:pl-6"
        : alignRight
          ? "pr-4 sm:pr-6 text-right"
          : "px-3",
    )}
    {...rest}
    scope="col"
  >
    {children}
  </th>
);
