import { classNames } from "helpers/classNames";
import { FC } from "react";

interface Props {
  text: string;
  color: BadgeColor;
  size?: BadgeSize;
}

export const Badge: FC<Props> = ({ text, color, size = "small" }) => (
  <span
    className={classNames(
      "inline-flex items-center rounded-full px-2.5 py-1 font-medium truncate",
      colorClass(color),
      size === "small" ? "text-xs" : size === "big" ? "text-sm" : "text-base",
    )}
  >
    <span className="truncate whitespace-pre-line text-center">{text}</span>
  </span>
);

export type BadgeColor = "red" | "yellow" | "green" | "blue" | "gray";

export const colorClass = (color: BadgeColor): string => {
  switch (color) {
    case "red":
      return "bg-red-100 text-flowerRed-500";
    case "blue":
      return "bg-flowerSky-50 text-flowerSkyVivid-500";
    case "green":
      return "bg-green-100 text-green-500";
    case "yellow":
      return "bg-yellow-100 text-yellow-500";
    case "gray":
      return "bg-gray-100 text-gray-500";
    default:
      return "bg-gray-100 text-gray-500";
  }
};

export type BadgeSize = "small" | "big" | "extra-big";
