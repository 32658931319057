import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const BoltIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      width="79"
      height="auto"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7908 53.3393H53.7798L56.9578 8.14111L19.7908 53.3393Z"
        fill={color}
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M50.6017 97.8589L87.2092 53.3394H53.7275L50.6017 97.8589Z"
        stroke={color}
      />
    </svg>
  );
};

export default BoltIcon;
