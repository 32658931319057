import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { PrequalificationGroupDTO } from "types/prequalificationGroup.type";
import { brpLabel } from "enums/brp.enum";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Shadow } from "components/Shadow";
import { usePrequalificationGroup } from "api/flowerSettings/flowerSettings.endpoints";
import { isEmptyArray } from "helpers/array";
import { AddPrequalificationGroup } from "./AddPrequalificationGroup";
import { EditPrequalificationGroup } from "./EditPrequalificationGroup";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { FlowerSymbolLogo } from "styles/Icons";

export const PrequalificationGroups = () => {
  const { t } = useTranslation();

  const {
    data: prequalificationGroups,
    isLoading,
    isError,
  } = usePrequalificationGroup();

  if (isLoading || isError || prequalificationGroups === undefined) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError || prequalificationGroups === undefined}
          isLoading={isLoading}
          isEmpty={false}
          icon={
            <img
              className={`h-auto w-28`}
              src={FlowerSymbolLogo}
              alt="Flower Hub"
            />
          }
          errorMessage={t(`prequalificationGroup.Error`)}
          loaderSize="page"
        />
      </div>
    );
  }

  if (isEmptyArray(prequalificationGroups)) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={isEmptyArray(prequalificationGroups)}
          icon={
            <img
              className={`h-auto w-28`}
              src={FlowerSymbolLogo}
              alt="Flower Hub"
            />
          }
          emptyDataMessage={t(`prequalificationGroup.Empty`)}
        />
        <AddPrequalificationGroup centered={true} />
      </div>
    );
  }

  const thClassName =
    "px-3 py-3 text-xs font-medium uppercase tracking-wide text-white text-center";
  const checkCircleClassName = "h-4 w-4 text-flowerSky-500 mx-auto";

  return (
    <div className="overflow-auto">
      <Shadow>
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`prequalificationGroup.Name`)}</TH>
              <TH>{t(`prequalificationGroup.Bidding region`)}</TH>
              <TH>{t(`prequalificationGroup.BRP`)}</TH>
              <TH className={thClassName}>
                {t(`prequalificationGroup.FCR-D-up`)}
              </TH>
              <TH className={thClassName}>
                {t(`prequalificationGroup.FCR-D-down`)}
              </TH>
              <TH className={thClassName}>
                {t(`prequalificationGroup.FCR-N`)}
              </TH>
              <TH className={thClassName}>
                {t(`prequalificationGroup.Approved capacity`)}
              </TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {prequalificationGroups
              .sort((a, b) => (a.id < b.id ? -1 : 1))
              .map((group: PrequalificationGroupDTO) => (
                <TR key={group.id}>
                  <TD addLeftMargin>{group.name}</TD>
                  <TD>{group.biddingRegion}</TD>
                  <TD>{brpLabel(group.brp)}</TD>
                  <TD>
                    {group.marketApprovalFcrdUp && (
                      <CheckCircleIcon className={checkCircleClassName} />
                    )}
                  </TD>
                  <TD>
                    {group.marketApprovalFcrdDown && (
                      <CheckCircleIcon className={checkCircleClassName} />
                    )}
                  </TD>
                  <TD>
                    {group.marketApprovalFcrN && (
                      <CheckCircleIcon className={checkCircleClassName} />
                    )}
                  </TD>
                  <TD className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-right">
                    {group.approvedCapacity}
                    <span className="text-gray-400"> kW</span>
                  </TD>
                  <TD alignRight>
                    {" "}
                    <EditPrequalificationGroup prequalificationGroup={group} />
                  </TD>
                </TR>
              ))}
          </TBody>
        </Table>
      </Shadow>

      <AddPrequalificationGroup />
    </div>
  );
};
