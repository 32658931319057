import { ChangeEvent, FC, useId, useState } from "react";
import { Button } from "pages/sign-up-flow/components/Button";
import { GetAssetOwnerInvitationResponseDTO } from "api/assetOwnerInvitation/assetOwnerInvitation.dto";

enum SelectedAssetOwner {
  SAME_AS_INVITATION = "same",
  OTHER_THAN_INVITATION = "other",
  NOT_SELECTED = "not_selected",
}

type Props = {
  invitation: GetAssetOwnerInvitationResponseDTO;
  onSamePersonSelectedContinue: () => void;
  onAnotherPersonSelectedContinue: () => void;
};

export const Landing: FC<Props> = ({
  invitation,
  onSamePersonSelectedContinue,
  onAnotherPersonSelectedContinue,
}) => {
  const samePersonRadioId = useId();
  const otherPersonRadioId = useId();

  const [selectedPerson, setSelectedPerson] = useState(
    SelectedAssetOwner.NOT_SELECTED,
  );

  const handleOptionChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    if (
      !Object.values<string>(SelectedAssetOwner).includes(
        changeEvent.target.value,
      )
    ) {
      // Actually an error but needs some kind of fallback.
      setSelectedPerson(SelectedAssetOwner.NOT_SELECTED);
    } else {
      setSelectedPerson(changeEvent.target.value as SelectedAssetOwner);
    }
  };

  return (
    <div className="px-6 py-6">
      <h2 className="text-2xl font-medium leading-6 text-gray-900 mx-auto mb-4">
        Välkommen till Flower Hub
      </h2>
      <p className="mt-1 max-w-2xl text-base text-gray-600 leading-6">
        Du kommer i detta flöde starta processen att byta elavtal.
      </p>
      <div>
        <p className="mt-1 max-w-2xl text-base text-gray-600 leading-6">
          För att kunna byta elavtal behöver vi veta vem som står på nuvarande
          elavtal.
        </p>
        <p className="mt-4 max-w-2xl text-lg text-base text-flowerblue-700 leading-6">
          Vem står på nuvarande elavtal?
        </p>
        <form className="ml-6 mt-6">
          <div className="p-1 pl-2 border border-solid rounded flex flex-row items-center">
            <input
              type="radio"
              id={samePersonRadioId}
              value={SelectedAssetOwner.SAME_AS_INVITATION}
              checked={selectedPerson === SelectedAssetOwner.SAME_AS_INVITATION}
              onChange={handleOptionChange}
            />
            <label
              className="ml-2 text-gray-800 text-sm font-medium"
              htmlFor={samePersonRadioId}
            >
              {`Jag, ${invitation?.firstName} ${invitation?.lastName}`}
            </label>
          </div>
          <div className="mt-2 p-1 pl-2 border border-solid rounded flex flex-row items-center">
            <input
              type="radio"
              id={otherPersonRadioId}
              value={SelectedAssetOwner.OTHER_THAN_INVITATION}
              checked={
                selectedPerson === SelectedAssetOwner.OTHER_THAN_INVITATION
              }
              onChange={handleOptionChange}
            />
            <label
              className="ml-2 text-gray-800 text-sm font-medium"
              htmlFor={otherPersonRadioId}
            >
              Någon annan
            </label>
          </div>
        </form>
      </div>
      <Button
        disabled={selectedPerson === SelectedAssetOwner.NOT_SELECTED}
        handleClick={() => {
          if (selectedPerson === SelectedAssetOwner.SAME_AS_INVITATION) {
            onSamePersonSelectedContinue();
          } else if (
            selectedPerson === SelectedAssetOwner.OTHER_THAN_INVITATION
          ) {
            onAnotherPersonSelectedContinue();
          }
        }}
        label="Gå vidare"
      />
    </div>
  );
};
