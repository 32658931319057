import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteInstallerInvitation } from "api/installerInvitation/installerInvitation.endpoints";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import Modal from "components/Modal";
import { DeleteInstallerConfirmation } from "./DeleteInstallerConfirmation";
import { useQueryClient } from "@tanstack/react-query";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useNotification } from "hooks/useNotification";

interface Props {
  installerId: number;
  invitationId: number;
}

export const DeleteInstallerInvitation: FC<Props> = ({
  installerId,
  invitationId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notification = useNotification();

  const [isDeleteInstallerModalOpen, setIsInstallerModalOpen] = useState(false);

  // api
  const { mutate: deleteInstaller, isSuccess } = useDeleteInstallerInvitation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetUsers, installerId],
      });
      notification.info(`Invitation deleted`);
    },
  });

  // submit
  const onDeleteInstaller = () => deleteInstaller(invitationId);

  // modal close
  const onDeleteInstallerModalClose = () => {
    setIsInstallerModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onDeleteInstallerModalClose();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="w-4 h-5 justify-center flex">
        <button onClick={() => setIsInstallerModalOpen(true)}>
          <TrashIcon
            className="h-5 w-5 text-flowerRed-800 hover:text-flowerRed-500"
            aria-hidden="true"
          />
        </button>
      </div>

      <Modal
        title={t(`installer.Delete invitation`)}
        leftButtonAction={onDeleteInstallerModalClose}
        leftButtonText={t(`Close`)}
        rightButtonActionHandler={() => onDeleteInstaller()}
        isOpen={isDeleteInstallerModalOpen}
        onClose={onDeleteInstallerModalClose}
        loadingText={t(`installer.Deleting invitation`)}
      >
        <DeleteInstallerConfirmation />
      </Modal>
    </>
  );
};
