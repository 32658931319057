import { GetFlowerHubLogForAssetDTO } from "api/flowerHubLog/flowerHubLog.dto";
import { Badge } from "components/tags/Badge";
import { formatDateTime } from "helpers/date";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface FlowerHubLogProps {
  log: GetFlowerHubLogForAssetDTO;
}

export const FlowerHubLog: FC<FlowerHubLogProps> = ({ log }) => {
  const { t } = useTranslation();
  return (
    <>
      <td className="p-4 border-b border-slate-200">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          <Badge color={log.isResolved ? "green" : "red"} text={log.type} />
        </p>
        <p className="mt-1 flex text-xs leading-5 text-gray-500">
          <span className="relative truncate hover:underline">
            {t(`flowerHubLogs.${log.type}`)}
          </span>
        </p>
      </td>
      <td className="p-4 border-b border-slate-200">
        <p className="block text-sm text-slate-800">{log.flowerHubIp ?? "-"}</p>
      </td>
      <td className="p-4 border-b border-slate-200">
        <p className="block text-sm text-slate-800">{log.dongleIp ?? "-"}</p>
      </td>
      <td className="p-4 border-b border-slate-200">
        <p className="block text-sm text-slate-800">
          {formatDateTime(log.reportedAt)}
        </p>
      </td>
    </>
  );
};
