import { useTranslation } from "react-i18next";
import { ActionButton } from "../../buttons/ActionButton";
import { FC } from "react";
import { cloudFrontDomainUrl } from "constants/environment";
import { getDeviceForMedia } from "helpers/installationFlow";

type Props = {
  changeStep: (step: number) => void;
};

export const PowerConfirmationStep: FC<Props> = ({ changeStep }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center space-y-8">
      <img
        src={`${cloudFrontDomainUrl}/installation-flow/${getDeviceForMedia()}/final-setup.jpg`}
        alt="Final setup"
        className="w-full lg:w-2/3"
      />

      <h2 className="text-xl font-bold text-center leading-tight">
        {t("batteryOverview.Time to connect the power cable")}
      </h2>
      <p className="text-base text-gray-800 text-center">
        {t(
          `batteryOverview.Connect the Flower Hub to a power outlet using the power cable provided in the box`,
        )}
      </p>
      <div className="flex flex-col items-start">
        <p className="text-base text-gray-800 text-center">
          {t(`batteryOverview.The Flower Hub should now be connected with`)}
        </p>
        <ul className="list-disc pl-8 text-base text-gray-800 pt-2">
          <li>{t(`batteryOverview.A power cable`)}</li>
          <li>
            {t(
              `batteryOverview.An Ethernet cable connecting to the same network as the inverter`,
            )}
          </li>
        </ul>
      </div>
      <div className="flex justify-between w-full">
        <ActionButton
          onClick={() => changeStep(4)}
          label={t("Back")}
          variant="black"
        />
        <ActionButton
          onClick={() => changeStep(6)}
          label={t("batteryOverview.All cables are connected")}
          variant="primary"
        />
      </div>
    </div>
  );
};
