import { ReactNode } from "react";
import { Virtualizer } from "@tanstack/react-virtual";

interface Props {
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  children: ReactNode;
}

export const UL = ({ rowVirtualizer, children }: Props) => {
  return (
    <ul
      className="flex flex-col relative rounded-lg"
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
      }}
    >
      {children}
    </ul>
  );
};
