import { BiddingRegion } from "enums/biddingRegion.enum";
import { BRP } from "enums/brp.enum";

export type PrequalificationGroupDTO = {
  id: number;
  name: string;
  biddingRegion: BiddingRegion;
  brp: BRP;
  marketApprovalFcrdUp: boolean;
  marketApprovalFcrdDown: boolean;
  marketApprovalFcrN: boolean;
  approvedCapacity: number;
};

export type CreatePrequalificationGroupDTO = {
  name: string | null;
  biddingRegion: BiddingRegion | null;
  brp: BRP | null;
  marketApprovalFcrdUp: boolean;
  marketApprovalFcrdDown: boolean;
  marketApprovalFcrN: boolean;
  approvedCapacity: number | null;
};

export const getEmptyPrequalificationGroupDTO =
  (): CreatePrequalificationGroupDTO => ({
    name: null,
    biddingRegion: null,
    brp: BRP.Undefined,
    marketApprovalFcrdUp: false,
    marketApprovalFcrdDown: false,
    marketApprovalFcrN: false,
    approvedCapacity: null,
  });
