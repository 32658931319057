import React, { Dispatch, FC, SetStateAction } from "react";
import { FlowerHubStatusTag } from "../tags/FlowerHubStatusTag";
import { AssetItemDTO } from "../../api/asset/asset.dto";
import { useTranslation } from "react-i18next";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { List } from "../listBeta/List";
import { ElementDef } from "../listBeta/LI";
import { BoltIcon, ServerIcon, TruckIcon } from "@heroicons/react/24/outline";

interface Props {
  paginationQuery: InfiniteQueryResult<AssetItemDTO>;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  onAssetClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const FlowerHubList: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onAssetClick,
  createColumnFilter,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: AssetItemDTO) => {
    if (row)
      onAssetClick(row.assetOwnerId, row.installer.id, row.distributorId);
  };

  const elements: ElementDef<AssetItemDTO> = (element: AssetItemDTO) => {
    return {
      left: [
        {
          key: "installerName",
          cell: () => (
            <div className="flex items-center text-sm font-medium truncate text-gray-500">
              <TruckIcon
                className="h-5 w-5 mr-2 flex-shrink-0"
                aria-hidden="true"
              />
              <span className="w-full truncate">{element.installer.name}</span>
            </div>
          ),
        },
        {
          key: "model",
          cell: () => (
            <div className="flex items-center text-sm font-medium truncate text-gray-500">
              <BoltIcon
                className="h-5 w-5 mr-2 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="flex flex-col w-full truncate items-start text-sm">
                <span className="w-full truncate">
                  {element.assetModel.manufacturer}
                </span>
                <span className="w-full truncate">
                  {element.assetModel.name}
                </span>
              </div>
            </div>
          ),
        },
      ],
      right: [
        {
          key: "serialNumber",
          cell: () => (
            <div className="flex items-center justify-end text-sm text-gray-500">
              {element.serialNumber}
              <ServerIcon
                className="h-5 w-5 ml-2 flex-shrink-0"
                aria-hidden="true"
              />
            </div>
          ),
        },
        {
          key: "flowerHubStatus",
          cell: () =>
            element?.flowerHubStatus ? (
              <FlowerHubStatusTag flowerHubStatus={element.flowerHubStatus} />
            ) : undefined,
        },
      ],
    };
  };

  return (
    <List
      elements={elements}
      paginationQuery={paginationQuery}
      onSelectRow={handleRowClick}
      setPaginationUrl={setPaginationUrl}
      title={"Flower Hubs"}
      searchPlaceholder={`${t("FlowerHubList.Search")}`}
      createColumnFilter={createColumnFilter}
    />
  );
};
