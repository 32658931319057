import { FC } from "react";
import { FlowerHubLogo } from "styles/Icons";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface Props {
  openSidebar: () => void;
}
export const MobileMenuButton: FC<Props> = ({ openSidebar }) => {
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 z-10 lg:hidden pl-1 pt-1 sm:pl-3 bg-white flex items-center justify-between">
      <button
        type="button"
        className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
        onClick={openSidebar}
        tabIndex={-1}
      >
        <span className="sr-only">{t("Open sidebar")}</span>
        <Bars3Icon className="h-6 w-6 text-flowerGray-600" aria-hidden="true" />
      </button>

      <img
        className={`h-auto w-40 mr-4`}
        src={FlowerHubLogo}
        alt="Flower Hub"
      />
    </div>
  );
};
