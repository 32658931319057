import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  ForgotPasswordRequestDTO,
  GetFlowerUsersResponseDTO,
  ResetPasswordRequestDTO,
  UpdateInstallerUserRequestDTO,
  UpdateInstallerUserResponseDTO,
} from "./user.dto";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import { ErrorResponse } from "types/errorResponse.type";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ENDPOINT = "user";

export enum UserQueryKeys {
  GetUsers = "user-get-users",
  GetFlowerUsers = "user-get-flower-users",
}

export const useFlowerUsers = () =>
  useQuery({
    queryKey: [UserQueryKeys.GetFlowerUsers],
    queryFn: async (): Promise<GetFlowerUsersResponseDTO> =>
      (await api.get(`${ENDPOINT}/flower`)).data,
    initialData: { users: [], invitations: [] },
  });

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: async (payload: ForgotPasswordRequestDTO): Promise<void> =>
      await api.post(`${ENDPOINT}/forgot-password`, payload),
  });
};

export const useResetPassword = () => {
  const notification = useNotification();
  return useMutation({
    mutationFn: async (payload: ResetPasswordRequestDTO): Promise<void> =>
      await api.put(`${ENDPOINT}/reset-password`, payload),
    onError: () => {
      notification.error();
    },
  });
};

export const useUpdateInstallerUser = (installerUserId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerUserRequestDTO,
    ): Promise<UpdateInstallerUserResponseDTO> =>
      (await api.put(`${ENDPOINT}/${installerUserId}`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetUsers, data.installerId],
      });
      notification.info(`${data.name} updated`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useDeactivateUser = (settings = {}) => {
  const notification = useNotification();
  return useMutation({
    mutationFn: async (id: number): Promise<void> =>
      await api.delete(`${ENDPOINT}/${id}`),
    onError: () => {
      notification.error();
    },
    ...settings,
  });
};
