import { formatDate } from "helpers/date";
import { GetInvoiceItemResponseDTO } from "types/invoice.type";
import { Link } from "components/Link";
import { FC } from "react";
import { InvoiceStatusTag } from "components/tags/InvoiceStatusTag";

interface Props {
  invoices: GetInvoiceItemResponseDTO[];
}

export const DesktopVersion: FC<Props> = ({ invoices = [] }: Props) => (
  <div className="flex flex-col my-8 ">
    <div className="overflow-hidden rounded-md shadow">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-flowerSky-500">
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-white pl-6"
            >
              Fakturanummer
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
            >
              Summa
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
            >
              Fakturadatum
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
            >
              Betalas senast
            </th>

            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-white"
            >
              Status
            </th>
            <th
              scope="col"
              className="relative whitespace-nowrap py-3.5 pl-3 pr-6"
            >
              <span className="sr-only">View details</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td className="whitespace-nowrap py-2 pr-3 text-sm text-gray-900 pl-6">
                {invoice.id}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {`${invoice.total_amount} kr`}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {formatDate(invoice.invoice_date)}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {formatDate(invoice.due_date)}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <InvoiceStatusTag status={invoice.invoice_status_id} />
              </td>
              <td className="relative whitespace-nowrap py-2 pl-3 text-right text-sm font-medium pr-6">
                <Link
                  href={invoice.invoice_pdf}
                  target="_blank"
                  rel="noreferrer"
                >
                  View
                  <span className="sr-only">, {invoice.id}</span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
