import { FC } from "react";

import { Badge, BadgeColor, BadgeSize } from "./Badge";
import {
  CompensationStatus,
  CompensationStatusMessage,
} from "enums/assetOwnerCompensationStatus.enum";
import { Tooltip } from "../tooltip/Tooltip";
import { useTranslation } from "react-i18next";

interface Props {
  status: CompensationStatus;
  message: CompensationStatusMessage;
  size?: BadgeSize;
}

export const CompensationEligibilityStatusTag: FC<Props> = ({
  status,
  message,
  size = "small",
}) => {
  const { t } = useTranslation();
  const color = getColor(status);

  return (
    <div className="flex items-center">
      <div className="absolute group flex flex-col items-center justify-center">
        <Tooltip
          message={t([
            `compensationEligibilityStatus.${message}`,
            "compensationEligibilityStatus.FallbackMessage",
          ])}
          color={color}
        >
          <Badge color={color} text={status} size={size} />
        </Tooltip>
      </div>
    </div>
  );
};

const getColor = (status: CompensationStatus): BadgeColor => {
  switch (status) {
    case CompensationStatus.Qualified:
      return "green";
    default:
      return "gray";
  }
};
