import { useAssetOwner } from "api/assetOwner/assetOwner.endpoints";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { BreadCrumb, menuStore } from "store/menu.store";
import userStore, { getAssetOwnerId } from "store/user.store";
import { Consumption } from "../../../components/consumption/Consumption";
import { useSearchParams } from "react-router-dom";
import { FlowerRevenue } from "components/flowerRevenue/FlowerRevenue";
import { RouteLabel } from "routing/routeLabel.enum";
import { RouteUrl } from "routing/routeUrl.enum";
import { useActiveZavannSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import TabContainer from "components/tab/TabContainer";
import { SystemNotificationInfo } from "components/systemNotification/SystemNotificationInfo";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const OVERVIEW_TAB = "overview";
const FLOWER_REVENUE = "flower-revenue";

export const ConsumptionAndRevenuePage = () => {
  const { t } = useTranslation();

  const { setBreadCrumbs } = menuStore();

  const getBreadcrumbs = (): BreadCrumb[] => [
    {
      label: RouteLabel.ConsumptionAndRevenue,
      to: `${RouteUrl.App}/${RouteUrl.Profile}`,
    },
  ];

  useEffect(() => {
    setBreadCrumbs(getBreadcrumbs());
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || OVERVIEW_TAB;

  const assetOwnerId = userStore(getAssetOwnerId) || -1;

  // asset owner
  const {
    data: assetOwner,
    isLoading,
    isError,
  } = useAssetOwner(assetOwnerId ?? -1);

  //Get active zavann system notifications
  const { data: systemNotification } = useActiveZavannSystemNotifications();

  if (isLoading || isError || !assetOwner) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !assetOwner}
          icon={<UserIcon />}
          errorMessage={t(`consumptionAndRevenue.assetOwnerNotFound`)}
          emptyDataMessage={t(`consumptionAndRevenue.assetOwnerNotFound`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <TabPageWrapper
      tabs={[
        {
          param: OVERVIEW_TAB,
          label: t(`consumptionAndRevenue.Overview`),
          content: (
            <TabContainer key={OVERVIEW_TAB}>
              {systemNotification ? (
                <SystemNotificationInfo notification={systemNotification} />
              ) : (
                <Consumption
                  assetOwnerId={+assetOwnerId}
                  hasSystemNotification={!!systemNotification}
                />
              )}
            </TabContainer>
          ),
          isSelected: currentTab === OVERVIEW_TAB,
        },
        {
          param: FLOWER_REVENUE,
          label: t(`consumptionAndRevenue.Revenue calculation`),
          content: (
            <TabContainer key={FLOWER_REVENUE}>
              <FlowerRevenue
                assetId={assetOwner.asset?.id}
                bessCompensationStartDate={
                  assetOwner?.bessCompensationStartDate
                }
              />
            </TabContainer>
          ),
          isSelected: currentTab === FLOWER_REVENUE,
        },
      ]}
      setTab={setTab}
    />
  );
};
