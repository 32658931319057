import {
  getStatusStyle,
  getZavannInvoiceStatusName,
} from "enums/zavannInvoiceStatus.enum";
import { classNames } from "helpers/classNames";
import { GetInvoiceItemResponseDTO } from "types/invoice.type";
import { FC } from "react";
import { formatDate } from "helpers/date";
import { FlowerLink } from "components/FlowerLink";
import { RouteUrl } from "routing/routeUrl.enum";
import { Link } from "components/Link";
import { isAfter } from "date-fns/esm";
import { isEmptyArray } from "helpers/array";
import { Label } from "../descriptionList";
import { useTranslation } from "react-i18next";
import { useInvoices } from "api/assetOwner/assetOwner.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

interface Props {
  assetOwnerId: number;
}
export const LatestInvoices: FC<Props> = ({ assetOwnerId }) => {
  const { t } = useTranslation();

  // Get invoices
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useInvoices(assetOwnerId);

  if (isLoadingInvoices || isErrorInvoices || isEmptyArray(invoices)) {
    return (
      <div className="py-8">
        <CustomPlaceholder
          isError={isErrorInvoices}
          isLoading={isLoadingInvoices}
          isEmpty={!isLoadingInvoices && !isErrorInvoices}
          icon={<DocumentTextIcon />}
          errorMessage={t(`subHeader.errorFetchingInvoices`)}
          emptyDataMessage={t(`subHeader.noInvoicesFU`)}
          loaderSize="medium"
        />
      </div>
    );
  }

  return (
    <div className="px-2 sm:px:0">
      <div className="inline-block min-w-full align-middle py-4 sm:py-5 sm:px-6">
        <Label>Latest invoices</Label>
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4 sm:mt-5">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody>
              {getLatestInvoices(invoices).map(
                (invoice: GetInvoiceItemResponseDTO) => (
                  <tr key={invoice.id}>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {formatDate(invoice.due_date)}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                      {`${invoice.total_amount} kr`}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      <span
                        className={classNames(
                          getStatusStyle(invoice.invoice_status_id),
                          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                        )}
                      >
                        {getZavannInvoiceStatusName(invoice.invoice_status_id)}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Link
                        href={invoice.invoice_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                        <span className="sr-only">, {invoice.id}</span>
                      </Link>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex sm:px-6 mb-4">
        <FlowerLink to={`${RouteUrl.App}/${RouteUrl.InvoiceList}`}>
          See all invoices
          <span aria-hidden="true"> &rarr;</span>
        </FlowerLink>
      </div>
    </div>
  );
};

const getLatestInvoices = (
  invoices: GetInvoiceItemResponseDTO[] = [],
  maxCount = 3,
): GetInvoiceItemResponseDTO[] =>
  invoices
    .sort((a, b) =>
      isAfter(new Date(a.invoice_date), new Date(b.invoice_date)) ? -1 : 1,
    )
    .slice(0, maxCount);
