import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  GetInstallerResponseDTO,
  UpdateInstallerRequestDTO,
} from "api/installer/installer.dto";
import { useUpdateInstaller } from "api/installer/installer.endpoints";
import { EditInstallerForm } from "./EditInstallerForm";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";

interface Props {
  installer: GetInstallerResponseDTO;
}

export const EditInstaller: FC<Props> = ({ installer }) => {
  const { t } = useTranslation();

  const [isEditInstallerModalOpen, setIsEditInstallerModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateInstaller,
    isPending: isUpdating,
    isSuccess,
  } = useUpdateInstaller(installer.id);

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateInstallerRequestDTO>();

  // submit
  const onSubmit = handleSubmit((data: UpdateInstallerRequestDTO) =>
    updateInstaller(data),
  );

  // modal close
  const onEditInstallerModalClose = () => {
    setTimeout(() => reset(), 300);
    setIsEditInstallerModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onEditInstallerModalClose();
    }
  }, [isSuccess]);

  // Populate or clear the form every time isEditAssetOwnerModalOpen is set
  useEffect(() => {
    if (isEditInstallerModalOpen) {
      reset(installer);
    }
  }, [isEditInstallerModalOpen]);

  const renderEditInstallerButton = () => {
    return (
      <div className="flex justify-end mb-4">
        <SecondaryButton onClick={() => setIsEditInstallerModalOpen(true)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />
          {t(`installer.Edit`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderEditInstallerButton()}

      <Modal
        isOpen={isEditInstallerModalOpen}
        rightButtonActionHandler={onSubmit}
        title={t(`installer.EditInstaller`)}
        rightButtonText={t(`Save`)}
        onClose={onEditInstallerModalClose}
        isLoading={isUpdating}
      >
        <EditInstallerForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
