import React from "react";
import { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  /* cspell:disable-next-line */
} from "@tanstack/react-table";
/* cspell:disable-next-line */
import { TableOptions } from "@tanstack/table-core";
import {
  Table as TableStyle,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "components/table";
import Filters, { Filter } from "components/filters/Filters";
import { SearchField } from "components/input/SearchField";

export type ColumnFilter = { id: string; value: string[] };

interface TableProps<T extends object> {
  columns: TableOptions<T>["columns"];
  data: T[];
  filters: Filter[];
  onSelectRow?: (data: T) => void;
  columnVisibility?: Record<string, boolean>;
}

export function TanStackTable<T extends { id?: number }>({
  columns,
  data,
  filters,
  onSelectRow,
  columnVisibility,
}: TableProps<T>): React.ReactElement {
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
    },
    enableRowSelection: true,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const changeColumnFilter = (option: string, column: string) => {
    setColumnFilters((prev: ColumnFilter[]) => {
      const existingFilter = prev.find((filter) => filter.id === column);
      if (!existingFilter) {
        // If the filter doesn't exist, create a new one
        return [
          ...prev,
          {
            id: column,
            value: [option],
          },
        ];
      } else {
        // If the filter already exists, update its value
        const isActive = existingFilter.value.includes(option);
        const updatedFilter = {
          ...existingFilter,
          value: isActive
            ? existingFilter.value.filter((s: string) => s !== option)
            : [...existingFilter.value, option],
        };
        // Replace the existing filter in the array
        return prev.map((f) => (f.id === column ? updatedFilter : f));
      }
    });
  };

  return (
    <div className="lg:block min-h-20">
      <div className="lg:flex lg:items-center space-x-4 w-full justify-end">
        <SearchField onSearch={(value) => setGlobalFilter(value)} />
        {filters.length && (
          <Filters
            filters={filters}
            columnFilters={columnFilters}
            setColumnFilters={changeColumnFilter}
          />
        )}
      </div>

      <div className="lg:block">
        <TableStyle>
          <THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TR key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TH key={header.id}>
                    {header.column.columnDef.header as string}
                  </TH>
                ))}
              </TR>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.map((row) => (
              <TR
                key={row.id}
                onClick={() => onSelectRow && onSelectRow(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TD key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TD>
                ))}
              </TR>
            ))}
          </TBody>
        </TableStyle>
      </div>
    </div>
  );
}
