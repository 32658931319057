import { FC } from "react";
import { Badge, BadgeSize } from "./Badge";
import { PostBillingAdjustmentsStatusTypes } from "enums/postBillingAdjustmentStatus.enums";

interface Props {
  status: PostBillingAdjustmentsStatusTypes;
  size?: BadgeSize;
}

export const BillingAdjustmentStatusTag: FC<Props> = ({
  status,
  size = "small",
}) => {
  return (
    <div className="flex items-center">
      <Badge
        color={
          status === PostBillingAdjustmentsStatusTypes.Sent ? "green" : "red"
        }
        text={status}
        size={size}
      />
    </div>
  );
};
