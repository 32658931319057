export enum BRP {
  Volue = 1,
  Jämtkraft = 2,
  Bixia = 3,
  Undefined = -1,
}

export const brpLabel = (brp: BRP): string => {
  switch (brp) {
    case BRP.Volue:
      return "Volue";
    case BRP.Jämtkraft:
      return "Jämtkraft";
    case BRP.Bixia:
      return "Bixia";
    default:
      throw Error(`brpLabel is missing implementation for ${brp}`);
  }
};

export const BRP_DROPDOWN_ITEMS = [
  {
    label: brpLabel(BRP.Volue),
    value: BRP.Volue,
  },
  {
    label: brpLabel(BRP.Jämtkraft),
    value: BRP.Jämtkraft,
  },
  {
    label: brpLabel(BRP.Bixia),
    value: BRP.Bixia,
  },
];
