import { ChartBarIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { DesktopVersion } from "./DesktopVersion";
import { MobileVersion } from "./MobileVersion";
import MaintenanceState from "components/systemNotification/MaintenanceState";
import {
  useConsumption,
  useInvoices,
} from "api/assetOwner/assetOwner.endpoints";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { getChartData } from "../../helpers/assetOwnerDashboard";
import { isMobile } from "react-device-detect";

interface Props {
  assetOwnerId: number;
  hasSystemNotification?: boolean;
}

export const Consumption: FC<Props> = ({
  assetOwnerId,
  hasSystemNotification = false,
}) => {
  const { t } = useTranslation();

  // Invoices
  const {
    data: invoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useInvoices(assetOwnerId);

  // Consumption Data
  const {
    data: consumption,
    isLoading: isLoadingConsumption,
    isError: isErrorConsumption,
  } = useConsumption(assetOwnerId);

  // const chartData = getChartData(consumptionData, invoices);
  const chartData = getChartData(consumption, invoices);

  if (hasSystemNotification)
    return <MaintenanceState header={t(`maintenance.Maintenance ongoing`)} />;

  const isLoading = isLoadingInvoices || isLoadingConsumption;
  const isError = isErrorInvoices || isErrorConsumption;

  const renderConsumptionPlaceholder = () => {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && chartData.length === 0}
          icon={<ChartBarIcon />}
          errorMessage={t(`graph.error`)}
          emptyDataMessage={t(`graph.Nothing to display`)}
          loaderSize="medium"
        />
      </div>
    );
  };

  if (isLoading || isError || chartData.length === 0) {
    return renderConsumptionPlaceholder();
  }

  return (
    <>
      {isMobile ? (
        <MobileVersion data={chartData} />
      ) : (
        <DesktopVersion data={chartData} />
      )}
    </>
  );
};
