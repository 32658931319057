import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import {
  CreateDistributorRequestDTO,
  getEmptyCreateDistributorDTO,
} from "api/distributor/distributor.dto";
import { useCreateDistributor } from "api/distributor/distributor.endpoints";
import AddDistributorForm from "./AddDistributorForm";
import SecondaryButton from "components/buttons/SecondaryButton";

interface Props {
  isCentered?: boolean;
}

export const AddDistributor: FC<Props> = ({ isCentered = false }) => {
  const { t } = useTranslation();

  // state
  const [isAddDistributorModalOpen, setIsAddDistributorModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: createDistributor,
    isPending: isCreating,
    isSuccess,
  } = useCreateDistributor();

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateDistributorRequestDTO>({
    defaultValues: getEmptyCreateDistributorDTO(),
  });

  // submit
  const onSubmit = handleSubmit((data: CreateDistributorRequestDTO) =>
    createDistributor(data),
  );

  // modal close
  const closeModal = () => {
    setIsAddDistributorModalOpen(false);
    setTimeout(() => reset(), 300);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  const renderAddDistributorButton = () => {
    return (
      <div
        className={`pb-2 flex ${isCentered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton onClick={() => setIsAddDistributorModalOpen(true)}>
          <UserPlusIcon className="h-5 w-5 mr-1" />
          {t(`distributorList.Add distributor`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddDistributorButton()}

      <Modal
        isOpen={isAddDistributorModalOpen}
        onClose={closeModal}
        title={t("distributorList.Save distributor")}
        rightButtonActionHandler={onSubmit}
        isLoading={isCreating}
      >
        <AddDistributorForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
