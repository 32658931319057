import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const BatteryIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      width="106"
      height="auto"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <path
        d="M92.3582 28.3735H49.6932V77.6264H92.3582V28.3735Z"
        stroke={color}
      />
      <path
        d="M49.3653 28.3735H6.70026V77.6264H49.3653V28.3735Z"
        fill={color}
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M49.0376 28.3735H6.3725V77.6264H49.0376V28.3735Z"
        stroke={color}
      />
      <path
        d="M100.635 42.8984H92.3657V64.1253H100.635V42.8984Z"
        stroke={color}
      />
    </svg>
  );
};

export default BatteryIcon;
