import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useResetPassword } from "api/user/user.endpoints";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { SignUp } from "components/SignUp";
import { AuthFlowWrapper } from "components/AuthFlowWrapper";
import { Transition } from "components/animations/Transition";
import { config } from "react-spring";
import { SmoothUp } from "components/animations/SnapUp";
import { useTranslation } from "react-i18next";
import { isPasswordStrongEnough } from "../../helpers/validation";
import { ResetPasswordRequestDTO } from "../../api/user/user.dto";

function ResetPasswordPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";

  const {
    mutate: resetPassword,
    isPending: isLoading,
    isSuccess,
    isError,
  } = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordRequestDTO>();
  const password = watch("password");

  const onSubmit = handleSubmit((data: ResetPasswordRequestDTO) => {
    resetPassword({ ...data, code });
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(`${RouteUrl.Login}`);
    }
  }, [isSuccess, navigate]);

  return (
    <SmoothUp>
      {isSuccess ? (
        <AuthFlowWrapper
          headerText={t("password.Restore your account")}
          onActionClick={onSubmit}
          isLoading={isLoading}
          buttonText={t("password.Submit")}
          buttonTextLoading={t("password.Submitting")}
          hideAction={isSuccess || true}
        >
          <Transition
            settings={{
              from: {
                transform: "scale(0)",
              },
              enter: {
                transform: "scale(1)",
              },
              delay: 100,
              config: { ...config.wobbly },
            }}
            isVisible={true}
          >
            <CheckCircleIcon className="mx-auto h-10 w-auto text-flowerSky-400 mb-2" />
          </Transition>
          <p className="text-gray-700 text-center">
            {t("password.Password reset")}
          </p>
          <div className="flex items-center justify-center">
            <Link
              to={RouteUrl.Login}
              className="font-medium text-flowerSky-600 hover:text-flowerSky-500"
            >
              {t("password.To login page")}
            </Link>
          </div>
        </AuthFlowWrapper>
      ) : (
        <SignUp
          header="Create a new password"
          buttonText={{
            loading: "Resetting password",
            default: "Reset password",
          }}
          register={register}
          errors={errors}
          onSubmit={onSubmit}
          isLoading={isLoading}
          isError={isError}
          isPasswordValid={isPasswordStrongEnough(password)}
        />
      )}
    </SmoothUp>
  );
}

export default ResetPasswordPage;
