import { CheckIcon } from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";
import { FC } from "react";

type Props = {
  steps: Step[];
  isModal?: boolean;
  smallPadding?: boolean;
};

export const Steps: FC<Props> = ({
  steps = [],
  isModal = false,
  smallPadding = false,
}) => {
  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className={classNames(
          "flex items-center justify-center",
          isModal && "bg-flowerSky-100 p-8",
        )}
      >
        {steps.map((step, stepIndex) => (
          <li
            key={step.label}
            className={classNames(
              stepIndex !== steps.length - 1
                ? isModal
                  ? "pr-24"
                  : smallPadding
                    ? "pr-6 sm:pr-12 md:14"
                    : "pr-10 sm:pr-12 md:14"
                : "",
              "relative",
            )}
          >
            {step.status === StepStatus.Complete ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-flowerSky-500" />
                </div>
                <button
                  onClick={() => !!step.onClick && step.onClick()}
                  type="button"
                  className="relative flex h-8 w-8 items-center justify-center rounded-full bg-flowerSky-500 hover:bg-flowerSky-800"
                >
                  <CheckIcon
                    className="h-5 w-5 text-flowerSky-100"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.label}</span>
                </button>
              </>
            ) : step.status === StepStatus.Current ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-flowerSky-200" />
                </div>
                <a
                  href="#"
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-flowerSky-600 bg-flowerSky-100"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-flowerSky-600"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.label}</span>
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-flowerSky-200" />
                </div>
                <a
                  href="#"
                  className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-flowerSky-300 bg-flowerSky-100 hover:border-flowerSky-400"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.label}</span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export const StepsDesktop: FC<Props> = ({ steps = [] }) => {
  return (
    <nav aria-label="Progress" className="mt-8 mr-16">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li
            key={step.label}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pb-10" : "",
              "relative",
            )}
          >
            {step.status === StepStatus.Complete ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-flowerSky-500"
                    aria-hidden="true"
                  />
                ) : null}
                <a className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-flowerSky-500 group-hover:bg-flowerSky-700">
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span
                      className="text-sm text-gray-700"
                      style={{ transform: "translateY(8px)" }}
                    >
                      {step.label}
                    </span>
                  </span>
                </a>
              </>
            ) : step.status === StepStatus.Current ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  className="group relative flex items-start"
                  aria-current="step"
                >
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-flowerSky-500 bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-flowerSky-500" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span
                      className="text-sm font-medium text-flowerSky-500"
                      style={{ transform: "translateY(8px)" }}
                    >
                      {step.label}
                    </span>
                  </span>
                </a>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a className="group relative flex items-start">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span
                      className="text-sm font-medium text-gray-500"
                      style={{ transform: "translateY(8px)" }}
                    >
                      {step.label}
                    </span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export type Step = {
  label: string;
  status: StepStatus;
  onClick?: () => void;
};

export enum StepStatus {
  Current,
  Complete,
  Upcoming,
}
