import { FC } from "react";
import { Table, TBody, TD, TH, THead, TR } from "components/table";
import { Badge } from "components/tags/Badge";
import { Role } from "enums/role.enum";
import { AddFlowerUser } from "./AddFlowerUser";
import { useFlowerUsers } from "api/user/user.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { DeleteFlowerUser } from "./DeleteFlowerUser";
import { DeleteFlowerUserInvitation } from "./DeleteFlowerUserInvitation";
import { FlowerInvitationDTO } from "api/flowerInvitation/flowerInvitation.dto";
import { FlowerUserDTO } from "api/user/user.dto";
import { isFlower } from "helpers/role";

interface Props {
  userRole: Role;
  userId: number;
}

export const FlowerUsersTab: FC<Props> = ({ userRole, userId }) => {
  const { t } = useTranslation();

  const { data: useFlowerUsersResponse, isLoading, isError } = useFlowerUsers();

  if (
    isLoading ||
    isError ||
    !useFlowerUsersResponse?.users ||
    !useFlowerUsersResponse?.invitations
  ) {
    return (
      <CustomPlaceholder
        isEmpty={
          !isLoading &&
          !isError &&
          !useFlowerUsersResponse?.users &&
          !useFlowerUsersResponse?.invitations
        }
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`flowerUser.Error`)}
        emptyDataMessage={t(`flowerUser.EmptyUser`)}
        loaderSize="page"
      />
    );
  }

  const { users, invitations } = useFlowerUsersResponse;

  return (
    <div className="overflow-hidden overflow-y-auto">
      <div className="mb-4">
        <Table>
          <THead>
            <TR>
              <TH isFirstColumn>{t(`flowerUser.Email`)}</TH>
              <TH>{t(`flowerUser.Name`)}</TH>
              <TH>{t(`flowerUser.Status`)}</TH>
              <TH>
                <></>
              </TH>
            </TR>
          </THead>
          <TBody>
            {users.map(({ email, id, name }: FlowerUserDTO) => (
              <TR key={email}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>
                  <Badge
                    color="green"
                    text={t(`badgeAccountStatus.Account created`)}
                  />
                </TD>
                <TD alignRight>
                  {userId != id && isFlower(userRole) && (
                    <DeleteFlowerUser userId={id} />
                  )}
                </TD>
              </TR>
            ))}
            {invitations.map(({ email, id, name }: FlowerInvitationDTO) => (
              <TR key={id}>
                <TD addLeftMargin>{email}</TD>
                <TD>{name}</TD>
                <TD>
                  <Badge color="blue" text={t(`badgeAccountStatus.Invited`)} />
                </TD>
                <TD alignRight>
                  {isFlower(userRole) && (
                    <DeleteFlowerUserInvitation invitationId={id} />
                  )}
                </TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </div>

      {isFlower(userRole) && <AddFlowerUser />}
    </div>
  );
};
