import { FC } from "react";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "../CustomPlaceholder";
import { useAsset } from "../../api/asset/asset.endpoints";
import { Battery0Icon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { BatteryDetails } from "./BatteryDetails";

interface Props {
  userRole: Role;
  assetOwnerId?: number;
  assetId?: number;
  showEditAsset?: boolean;
}

export const Battery: FC<Props> = ({ userRole, assetId }) => {
  const { t } = useTranslation();

  // Get asset
  const {
    data: asset,
    isLoading: isLoadingAsset,
    isError: isErrorAsset,
  } = useAsset(assetId);

  const renderPlaceholder = () => (
    <div className="my-8">
      <CustomPlaceholder
        isError={isErrorAsset}
        isLoading={isLoadingAsset}
        isEmpty={!isLoadingAsset && !isErrorAsset && !asset}
        icon={<Battery0Icon />}
        errorMessage={t(`batteryOverview.UnableToFetchTheBattery`)}
        emptyDataMessage={t(`batteryOverview.NoBattery`)}
        loaderSize="medium"
      />
    </div>
  );

  if (isLoadingAsset || !asset || isErrorAsset) {
    return renderPlaceholder();
  }

  return <BatteryDetails userRole={userRole} asset={asset} />;
};
