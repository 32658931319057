import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";

interface Item {
  value: string | number;
  label: string;
}
interface Props {
  items: Item[];
  name: string;
  label: string;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any;
  errorMessage?: string;
  isDisabled?: boolean;
}

const Dropdown: FC<Props> = ({
  items,
  name,
  label,
  register,
  validation,
  errorMessage,
  isDisabled,
}) => {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        disabled={isDisabled}
        id="location"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-flowerSky-600 focus:border-flowerSky-600 sm:text-sm rounded-md"
        {...register(name, validation && validation)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {!!errorMessage && (
        <div className="flex flex-row items-center mt-2">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
          <p className="ml-1 text-sm text-red-600">{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
