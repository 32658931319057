import { SmoothUp } from "components/animations/SnapUp";
import { DashboardGreetingHeader } from "components/shared/DashboardGreetingHeader";
import userStore, {
  getDistributorId,
  getInstallerId,
  getUserName,
  getUserRole,
} from "store/user.store";
import { DashboardStatistics } from "../../../components/statistics/DashboardStatistics";
import { LatestAssetOwnersWithAssetList } from "components/assetOwner/LatestAssetOwnersWithAssetList";
import { LatestInstallerList } from "components/installer/LatestInstallerList";
import { LatestAssetOwners } from "components/assetOwner/LatestAssetOwners";
import { BreadCrumb, menuStore } from "../../../store/menu.store";
import { useEffect } from "react";
import { RouteLabel } from "../../../routing/routeLabel.enum";
import { RouteUrl } from "../../../routing/routeUrl.enum";
import { isDistributor, isFlower, isInstaller } from "../../../helpers/role";

export const DashboardPage = () => {
  const { user } = userStore();
  const userName = userStore(getUserName);
  const distributorId = userStore(getDistributorId);
  const installerId = userStore(getInstallerId);
  const userRole = userStore(getUserRole);

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, []);

  return (
    <SmoothUp>
      <DashboardGreetingHeader name={userName} />
      <DashboardStatistics
        installerId={isInstaller(userRole) ? user?.installerId : undefined}
        distributorId={
          isDistributor(userRole) ? user?.distributorId : undefined
        }
      />
      <div className="flex flex-col flex-wrap w-full h-full">
        {isFlower(userRole) && <LatestAssetOwnersWithAssetList />}
        {isDistributor(userRole) && (
          <LatestInstallerList distributorId={distributorId} />
        )}
        {isInstaller(userRole) && (
          <LatestAssetOwners installerId={installerId} />
        )}
      </div>
    </SmoothUp>
  );
};

const getBreadCrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Dashboard,
    to: `${RouteUrl.App}/${RouteUrl.Dashboard}`,
  },
];
