import { useDistributor } from "api/distributor/distributor.endpoints";
import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BreadCrumb, menuStore } from "store/menu.store";
import { RouteUrl } from "routing/routeUrl.enum";
import { getDistributorListBreadcrumb } from "../distributorList/DistributorListPage";
import { DashboardStatistics } from "../../../components/statistics/DashboardStatistics";
import { LatestInstallerList } from "components/installer/LatestInstallerList";
import { DistributorUsersTab } from "../../../components/distributor/DistributorUsersTab";
import userStore, { getUserRole } from "store/user.store";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { DistributorAccountDetails } from "components/distributor/DistributorAccountDetails";
import { Installers } from "../../../components/installer/Installers";
import TabContainer from "../../../components/tab/TabContainer";

const DASHBOARD_TAB = "dashboard";
const ORGANIZATION_TAB = "organization";
const INSTALLERS_TAB = "installers";
const USERS_TAB = "users";

export default function DistributorPage() {
  const { t } = useTranslation();

  const params = useParams();
  const distributorId: string = params?.distributorId || "-1";
  const userRole = userStore(getUserRole);

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  const currentTab = searchParams.get("tab") || DASHBOARD_TAB;
  const { setBreadCrumbs } = menuStore();

  // Get distributor
  const {
    data: distributor,
    isLoading,
    isError,
  } = useDistributor(+distributorId);

  useEffect(() => {
    if (distributor) {
      setBreadCrumbs([
        getDistributorListBreadcrumb(),
        getDistributorBreadcrumb({
          id: distributor.id,
          name: distributor.name,
        }),
      ]);
    }
  }, [distributor]);

  if (isLoading || isError || !distributor) {
    return (
      <CustomPlaceholder
        isEmpty={!isLoading && !isError && !distributor}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`distributor.Error`)}
        emptyDataMessage={t(`distributor.Empty`)}
        loaderSize="page"
      />
    );
  }

  return (
    <TabPageWrapper
      tabs={[
        {
          param: DASHBOARD_TAB,
          label: t("distributor.DashboardTitle"),
          content: (
            <TabContainer key={DASHBOARD_TAB}>
              <div className="overflow-auto">
                <DashboardStatistics distributorId={distributor.id} />
                <LatestInstallerList distributorId={distributor.id} />
              </div>
            </TabContainer>
          ),

          isSelected: currentTab === DASHBOARD_TAB,
        },
        {
          param: ORGANIZATION_TAB,
          label: t("distributor.OrganizationTitle"),
          content: (
            <TabContainer key={ORGANIZATION_TAB}>
              <DistributorAccountDetails
                distributor={distributor}
                userRole={userRole}
              />
            </TabContainer>
          ),
          isSelected: currentTab === ORGANIZATION_TAB,
        },
        {
          param: INSTALLERS_TAB,
          label: t("distributor.InstallersTitle"),
          content: (
            <TabContainer key={INSTALLERS_TAB}>
              <div className="flex flex-col h-full pb-4 md:pb-8">
                <Installers
                  userRole={userRole}
                  distributorId={distributor.id}
                />
              </div>
            </TabContainer>
          ),
          isSelected: currentTab === INSTALLERS_TAB,
        },
        {
          param: USERS_TAB,
          label: t("distributor.UsersTitle"),
          content: (
            <TabContainer key={USERS_TAB}>
              <DistributorUsersTab
                distributorId={distributor.id}
                userRole={userRole}
                className={"flex flex-col overflow-x-hidden overflow-auto"}
              />
            </TabContainer>
          ),
          isSelected: currentTab === USERS_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
}

export const getDistributorBreadcrumb = ({
  id,
  name,
}: {
  id: number;
  name: string;
}): BreadCrumb => ({
  label: name,
  to: `${RouteUrl.App}/${RouteUrl.Distributors}/${id}`,
  iconType: "distributor",
});
