import { classNames } from "helpers/classNames";
import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
  addLeftMargin?: boolean;
  alignRight?: boolean;
}

export const TD: FC<
  Props &
    React.DetailedHTMLProps<
      React.TableHTMLAttributes<HTMLTableCellElement>,
      HTMLTableCellElement
    >
> = ({
  children,
  className,
  addLeftMargin = false,
  alignRight = false,
  ...rest
}) => (
  <td
    className={classNames(
      "truncate max-w-56 text-sm text-gray-900 py-4",
      addLeftMargin
        ? "pl-4 pr-3 sm:pl-6"
        : alignRight
          ? "pr-4 sm:pr-6 text-right"
          : "px-3",
      className ?? false,
    )}
    {...rest}
  >
    {children}
  </td>
);
