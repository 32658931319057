import { RefObject, useCallback, useEffect, useState } from "react";

export const useAutoScrollbarWidth = (
  containerRef: RefObject<HTMLDivElement>,
): number => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const updateScrollbarWidth = useCallback(() => {
    if (containerRef.current) {
      setScrollbarWidth(
        containerRef.current.offsetWidth - containerRef.current.clientWidth,
      );
    }
  }, [containerRef]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      const resizeObserver = new ResizeObserver(updateScrollbarWidth);
      resizeObserver.observe(containerElement);

      return () => resizeObserver.disconnect();
    }
  }, [updateScrollbarWidth, containerRef]);

  return scrollbarWidth;
};
