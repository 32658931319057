import { FC } from "react";
import { ChildrenProps } from "types/childrenProps.type";

export const Link: FC<
  ChildrenProps &
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >
> = ({ children, ...rest }) => (
  <a
    className="text-sm font-medium text-flowerblue-600 hover:text-flowerblue-500"
    {...rest}
  >
    {children}
  </a>
);
