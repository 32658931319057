import { CreatePasswordField } from "components/input/CreatePasswordField";
import { FC, useState } from "react";
import { Button } from "pages/sign-up-flow/components/Button";
import { GetAssetOwnerInvitationResponseDTO } from "../../../api/assetOwnerInvitation/assetOwnerInvitation.dto";
import { isPasswordStrongEnough } from "../../../helpers/validation";
import { useTranslation } from "react-i18next";

type Props = {
  onPasswordConfirmed: (arg: string) => void;
  invitation: GetAssetOwnerInvitationResponseDTO;
};
export const ChoosePassword: FC<Props> = ({
  onPasswordConfirmed,
  invitation,
}) => {
  const { t } = useTranslation();

  const [passwordIntermediate, setPasswordIntermediate] = useState("");

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 bg-white px-6 py-8"
      autoComplete="off"
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("password.Choose a password")}
            </h3>
          </div>

          {/* For prompting user to store email/password in browser */}
          <input hidden type="email" value={invitation.email} readOnly />
          <CreatePasswordField
            setPasswordIntermediate={setPasswordIntermediate}
          />
          <Button
            handleClick={() => onPasswordConfirmed(passwordIntermediate)}
            type="submit"
            disabled={!isPasswordStrongEnough(passwordIntermediate)}
          />
        </div>
      </div>
    </form>
  );
};
