import React, { Dispatch, FC, SetStateAction } from "react";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";
import { List } from "../listBeta/List";
import { useTranslation } from "react-i18next";
import { MapPinIcon, TruckIcon } from "@heroicons/react/24/outline";
import { ElementDef } from "../listBeta/LI";
import { InstallerItemDTO } from "../../api/installer/installer.dto";
import { InstallerStatus } from "../../enums/installerStatus.enum";
import { Badge } from "../tags/Badge";

interface Props {
  paginationQuery: InfiniteQueryResult<InstallerItemDTO>;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  onInstallerClick: (installerId: number, distributorId: number) => void;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const InstallerList: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onInstallerClick,
  createColumnFilter,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: InstallerItemDTO) => {
    if (row) onInstallerClick(row.id, row.distributorId);
  };

  // As Table component has columns, elements should contain left and right elements that will be displayed in the list
  // I defined this function to make the List component more generic
  const elements: ElementDef<InstallerItemDTO> = (
    element: InstallerItemDTO,
  ) => {
    return {
      left: [
        {
          key: "name",
          cell: () => (
            <div className="flex items-center text-sm font-medium truncate text-gray-500">
              <TruckIcon
                className="h-5 w-5 mr-2 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="flex flex-col items-start  w-full truncate text-sm">
                <span className="w-full truncate">{element.name}</span>
                <span className="w-full truncate text-gray-300">
                  {element.organizationNumber}
                </span>
              </div>
            </div>
          ),
        },
        {
          key: "city",
          cell: () =>
            element.city ? (
              <div className="flex items-center text-sm font-medium truncate text-gray-500">
                <MapPinIcon
                  className="h-5 w-5 mr-2 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="w-full truncate">{element.city}</span>
              </div>
            ) : undefined,
        },
      ],
      right: [
        {
          key: "flowerHubStatus",
          cell: () => (
            <Badge
              text={element.status}
              color={
                element.status === InstallerStatus.Pending ? "blue" : "green"
              }
            />
          ),
        },
      ],
    };
  };

  return (
    <List
      elements={elements}
      paginationQuery={paginationQuery}
      onSelectRow={handleRowClick}
      setPaginationUrl={setPaginationUrl}
      title={`${t("installerList.Installers")}`}
      searchPlaceholder={`${t("installerList.Search")}`}
      createColumnFilter={createColumnFilter}
    />
  );
};
