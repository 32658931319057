import { Disclosure, Menu, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Row } from "@tanstack/react-table";
import { FunnelIcon } from "@heroicons/react/24/outline";

export interface Filter {
  id: string;
  name: string;
  options: string[];
}

interface FiltersProps {
  filters: Filter[];
  columnFilters: { id: string; value: string[] }[];
  setColumnFilters: (option: string, column: string) => void;
}

type FilterTags<T, K extends keyof T> = {
  data: T[];
  columnName: K;
  firstNestedProperty?: string;
  secondNestedProperty?: string;
};

type NestedCombinedFilterTags<T, K extends keyof T> = {
  data: T[];
  firstProperty: string;
  secondProperty: string;
  columnName: K;
};

const Filters: FC<FiltersProps> = ({
  filters,
  columnFilters,
  setColumnFilters,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center border border-flowerPeach-900 font-medium px-4 py-2 text-sm leading-4 rounded-md shadow-sm text-flowerPeach-900 bg-flowerPeach-500 hover:bg-flowerPeach-900 hover:border-flowerPeach-900 hover:text-white">
          <FunnelIcon className="h-5 w-5 mr-1" /> Filters
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {filters.map((section: Filter) => {
            const filterStatuses =
              columnFilters.find((f) => f.id === section.id)?.value || [];
            return (
              <Disclosure
                as="div"
                key={section.id}
                className="border-gray-200 px-4 py-3"
              >
                {({ open }) => (
                  <>
                    <h3 className="-mx-2 -my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                        <span className="text-sm font-medium text-gray-900">
                          {section.name}
                        </span>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="space-y-6">
                        {section.options.map(
                          (option: string, optionIdx: number) => (
                            <div key={option} className="flex items-center">
                              <input
                                id={`filter-${option}-${optionIdx}`}
                                type="checkbox"
                                checked={filterStatuses.includes(option)}
                                className="h-4 w-4 rounded border-gray-300 text-sm text-indigo-600 focus:ring-indigo-500 cursor-pointer"
                                onChange={() =>
                                  setColumnFilters(option, section.id)
                                }
                              />
                              <label
                                htmlFor={`filter-${option}-${optionIdx}`}
                                className="ml-3 min-w-0 flex-1 text-sm text-gray-500 cursor-pointer"
                              >
                                {option}
                              </label>
                            </div>
                          ),
                        )}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default Filters;

export function getUniqueFilterTags<T extends object, K extends keyof T>(
  props: FilterTags<T, K>,
): string[] {
  const { data, columnName, firstNestedProperty, secondNestedProperty } = props;
  const uniqueFilterTags: Set<string> = new Set();
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((item: any) => {
    if (item[columnName]) {
      if (firstNestedProperty) {
        if (secondNestedProperty)
          uniqueFilterTags.add(
            item[columnName][firstNestedProperty][secondNestedProperty],
          );
        else uniqueFilterTags.add(item[columnName][firstNestedProperty]);
      } else {
        uniqueFilterTags.add(item[columnName]);
      }
    }
  });

  return Array.from(uniqueFilterTags);
}

export function getNestedCombinedFilterTags<
  T extends object,
  K extends keyof T,
>(props: NestedCombinedFilterTags<T, K>): string[] {
  const { data, columnName, firstProperty, secondProperty } = props;
  const uniqueFilterTags: Set<string> = new Set();
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.forEach((item: any) => {
    if (columnName && item[columnName])
      uniqueFilterTags.add(
        `${item[columnName][firstProperty]} ${item[columnName][secondProperty]}`,
      );
  });

  return Array.from(uniqueFilterTags);
}

export const filterFunction = <T extends object>(
  row: Row<T>,
  columnId: string,
  filterStatuses: string[],
) => {
  if (filterStatuses.length === 0) return true;
  const status: string = row.getValue(columnId);
  return filterStatuses.includes(status);
};
