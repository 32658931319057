export enum InverterType {
  Standard = 10,
  Hybrid = 20,
  Undefined = -1,
}

export const getInverterTypeName = (type: InverterType) => {
  switch (type) {
    case InverterType.Standard:
      return "Standard";
    case InverterType.Hybrid:
      return "Hybrid";
    case InverterType.Undefined:
      return "Okänd";
    default:
      throw Error(`Missing implementation for InverterType ${type}`);
  }
};
