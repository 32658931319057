import React from "react";

interface KeyValueTileProp {
  keyword: string;
  value: number | string;
}

const KeyValueTile: React.FC<KeyValueTileProp> = ({ keyword, value }) => {
  return (
    <div key={keyword} className="flex flex-col bg-white p-4">
      <dt className="text-sm font-base leading-6 text-gray-600">{keyword}</dt>
      <dd className="order-first text-3xl font-base tracking-tight text-gray-900">
        {value}
      </dd>
    </div>
  );
};

export default KeyValueTile;
