const REFRESH_TOKEN_EXPIRATION_DATE_KEY = "refreshTokenExpirationDate";

export const getRefreshTokenExpirationDate = () =>
  localStorage.getItem(REFRESH_TOKEN_EXPIRATION_DATE_KEY);

export const setRefreshTokenExpirationDate = (dateInMilliseconds: string) =>
  localStorage.setItem(REFRESH_TOKEN_EXPIRATION_DATE_KEY, dateInMilliseconds);

export const clearRefreshTokenExpirationDate = () =>
  localStorage.removeItem(REFRESH_TOKEN_EXPIRATION_DATE_KEY);

export const refreshTokenHasExpired = () => {
  const refreshTokenExpirationDate = getRefreshTokenExpirationDate();
  if (!refreshTokenExpirationDate) {
    return true;
  }
  return +refreshTokenExpirationDate < new Date().getTime();
};
