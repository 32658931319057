import React, { FC } from "react";
import { classNames } from "helpers/classNames";
import { useTranslation } from "react-i18next";

interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
}

const DangerButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  fullWidth = false,
  isLoading = false,
  className,
  loadingText = "Loading",
  ...rest
}) => {
  const { t } = useTranslation();

  const getClassForSize = (size: "small" | "medium" | "large") => {
    const styling =
      "inline-flex items-center border font-medium border-flowerRed-500 rounded-md shadow-sm text-flowerRed-500 hover:text-white bg-flowerRed-300 hover:bg-flowerRed-500";
    switch (size) {
      case "small":
        return classNames(
          "px-2.5 py-1.5 text-xs",
          styling,
          fullWidth && "w-full",
        );
      case "large":
        return classNames(
          "items-center px-4 py-2 text-sm",
          styling,
          fullWidth && "w-full",
        );
      case "medium":
      default:
        return classNames(
          "px-4 py-2 text-sm leading-4",
          styling,
          fullWidth && "w-full",
        );
    }
  };

  return (
    <button
      type="button"
      className={classNames(getClassForSize(size), className ?? false)}
      {...rest}
    >
      {isLoading && (
        <svg
          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {isLoading ? t(loadingText) : <>{children}</>}
    </button>
  );
};

export default DangerButton;
