import { classNames } from "helpers/classNames";
import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  className?: string;
  disableFocus?: boolean;
}

const SecondaryButton: FC<
  Props &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
> = ({
  children,
  size = "medium",
  className = "",
  disableFocus = false,
  ...rest
}) => {
  const getClassForSize = (size: "small" | "medium" | "large") => {
    const styling =
      "inline-flex items-center rounded rounded-md bg-flowerPeach-500 font-medium text-flowerPeach-900 shadow-sm border border-flowerPeach-900 hover:bg-flowerPeach-900 hover:text-white cursor-pointer";
    switch (size) {
      case "small":
        return classNames(styling, "px-2.5 py-1.5 text-xs");
      case "large":
        return classNames(styling, "px-4 py-2 text-sm");
      case "medium":
      default:
        return classNames(styling, "px-3 py-2 text-sm leading-4");
    }
  };
  return (
    <button
      type="button"
      className={classNames(getClassForSize(size), className)}
      tabIndex={disableFocus ? -1 : 0}
      {...rest}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
