export enum ZavannInvoiceStatus {
  Obetald = "1",
  Överbetald = "2",
  Delbetald = "3",
  Betald = "4",
  Krediterad = "5",
  Försenad = "6",
  EnergiKonto = "8",
  FordranÖverlåten = "9",
  Återbetald = "5000002",
}

export const getZavannInvoiceStatusName = (status: ZavannInvoiceStatus) => {
  switch (status) {
    case ZavannInvoiceStatus.Obetald:
      return "Obetald";
    case ZavannInvoiceStatus.Överbetald:
      return "Överbetald";
    case ZavannInvoiceStatus.Delbetald:
      return "Delbetald";
    case ZavannInvoiceStatus.Betald:
      return "Betald";
    case ZavannInvoiceStatus.Krediterad:
      return "Krediterad";
    case ZavannInvoiceStatus.Försenad:
      return "Försenad";
    case ZavannInvoiceStatus.EnergiKonto:
      return "EnergiKonto";
    case ZavannInvoiceStatus.FordranÖverlåten:
      return "FordranÖverlåten";
    case ZavannInvoiceStatus.Återbetald:
      return "Återbetald";
    default:
      throw Error(
        `getZavannInvoiceStatusName is missing implementation for ${status}`,
      );
  }
};

export const getStatusStyle = (status: ZavannInvoiceStatus) => {
  switch (status) {
    case ZavannInvoiceStatus.Betald:
    case ZavannInvoiceStatus.EnergiKonto:
    case ZavannInvoiceStatus.Återbetald:
      return "bg-green-200 text-green-600";
    case ZavannInvoiceStatus.Krediterad:
    case ZavannInvoiceStatus.Överbetald:
      return "bg-yellow-100 text-yellow-800";
    case ZavannInvoiceStatus.Försenad:
    case ZavannInvoiceStatus.FordranÖverlåten:
      return "bg-red-100 text-red-800";
    case ZavannInvoiceStatus.Obetald:
    case ZavannInvoiceStatus.Delbetald:
      return "bg-gray-100 text-gray-800";
    default:
      throw Error(
        `getZavannInvoiceStatusName is missing implementation for ${status}`,
      );
  }
};
