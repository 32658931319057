import React from "react";
import { classNames } from "../../helpers/classNames";
import { Row } from "@tanstack/react-table";
import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import { TD } from "./TD";

interface Props<T> {
  virtualRow: VirtualItem;
  rows: Row<T>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  onSelectRow?: (data: T) => void;
}

export const TRB = <T,>({
  virtualRow,
  rowVirtualizer,
  rows,
  onSelectRow,
}: Props<T>): React.ReactElement => {
  const row = rows[virtualRow.index];
  return (
    <div
      data-index={virtualRow.index} //needed for dynamic row height measurement
      ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
      className={classNames(
        `flex w-full pl-2 absolute text-black text-sm font-normal h-12 leading-relaxed items-center ${virtualRow.index % 2 === 0 ? "bg-[#f3f3f2]" : "bg-white"}`,
        onSelectRow ? "cursor-pointer hover:opacity-75" : "",
      )}
      style={{
        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
      }}
      onClick={() => onSelectRow && onSelectRow(row.original)}
    >
      {row.getVisibleCells().map((cell) => (
        <TD key={cell.id} cell={cell} />
      ))}
    </div>
  );
};
