import { useAssetOwnersPagination } from "api/assetOwner/assetOwner.endpoints";
import { FC, useState } from "react";
import { useInstaller } from "api/installer/installer.endpoints";
import { AssetOwnersEmptyState } from "components/assetOwner/AssetOwnersEmptyState";
import { AddAssetOwner } from "./AddAssetOwner";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { AssetOwnerTable } from "./AssetOwnerTable";
import { isInstaller } from "../../helpers/role";
import { AssetOwnerList } from "./AssetOwnerList";
import { useNavigate } from "react-router-dom";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { GetAllAssetOwnerResponseDTO } from "../../api/assetOwner/assetOwner.dto";
import { getAssetOwnerPageRoute } from "../../helpers/assetOwner";
import { isMobile } from "react-device-detect";
import { isEmptyArray } from "../../helpers/array";
import {
  FilterDTO,
  FilterWithLabelDTO,
} from "../../api/pagination/pagination.type";

interface Props {
  userRole: Role;
  distributorId?: number;
  installerId?: number;
}

export const AssetOwners: FC<Props> = ({
  userRole,
  distributorId,
  installerId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pagination query string state is defined to store the query string in the parent component
  const [queryString, setQueryString] = useState<string>("");

  // Infinite query to get asset owners with pagination
  const paginationQuery: UseInfiniteQueryResult<
    InfiniteData<GetAllAssetOwnerResponseDTO>
  > = useAssetOwnersPagination(queryString, {
    installerId,
    distributorId,
  });

  // Extract relevant information for this component from the pagination query
  const {
    data: assetOwners,
    isLoading: isLoadingAssetOwners,
    isError: isErrorAssetOwners,
    isRefetching: isRefetchingAssetOwners,
  } = paginationQuery;

  const flatAssetOwner = assetOwners?.pages.flatMap((page) => page.items);

  const onAssetOwnerClick = (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => {
    navigate(
      getAssetOwnerPageRoute(
        userRole,
        assetOwnerId,
        installerId,
        distributorId,
      ),
    );
  };

  // Get installer data
  const {
    data: installer,
    isLoading: isLoadingInstaller,
    isError: isErrorInstaller,
  } = useInstaller(installerId);

  // Convert the filter options to the enum values for the installer can invite filter
  const createColumnFilter = (filters: FilterDTO[]): FilterWithLabelDTO[] =>
    filters.map((filter) => {
      return {
        id: filter.id,
        options: filter.options.map((option) => {
          return {
            label: option,
            value: option,
          };
        }),
      };
    });

  const isLoading = isLoadingAssetOwners || isLoadingInstaller;
  const isError = isErrorAssetOwners || isErrorInstaller;

  if (isLoading || isError) {
    return (
      <div className="flex h-full items-center lg:items-start lg:pt-8">
        <CustomPlaceholder
          isEmpty={false}
          isLoading={isLoading}
          isError={isError}
          icon={<UserIcon />}
          errorMessage={t(`assetOwnerList.Error`)}
          loaderSize="page"
        />
      </div>
    );
  }

  if (
    isEmptyArray(flatAssetOwner) &&
    !isRefetchingAssetOwners &&
    queryString === ""
  ) {
    return isInstaller(userRole) && installer ? (
      <AssetOwnersEmptyState
        canInviteAssetOwners={installer.canInviteAssetOwners}
        installerId={installer.id}
      />
    ) : (
      <div className="flex h-full items-center lg:items-start lg:pt-8">
        <CustomPlaceholder
          isEmpty={true}
          emptyDataMessage={t(`assetOwnerList.Empty`)}
          isLoading={false}
          isError={false}
          icon={<UserIcon />}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {isInstaller(userRole) && installer?.canInviteAssetOwners && (
        <AddAssetOwner
          label={t(`assetOwnerList.Add asset owner`)}
          installerId={installer.id}
        />
      )}
      {isMobile ? (
        <AssetOwnerList
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetOwnerClick={onAssetOwnerClick}
          createColumnFilter={createColumnFilter}
        />
      ) : (
        <AssetOwnerTable
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetOwnerClick={onAssetOwnerClick}
          userRole={userRole}
          createColumnFilter={createColumnFilter}
        />
      )}
    </div>
  );
};
