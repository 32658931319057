export enum CompensationStatus {
  Qualified = "Qualified",
  Unqualified = "Unqualified",
}

export enum CompensationStatusMessage {
  FlowerHubNotInstalled = "FlowerHubNotInstalled",
  Qualified = "Qualified",
  BothSitesNotActive = "BothSitesNotActive",
  ConsumptionSiteNotActive = "ConsumptionSiteNotActive",
  ProductionSiteNotActive = "ProductionSiteNotActive",
  NotAZavannCustomer = "NotAZavannCustomer",
}
