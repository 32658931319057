import { FC, ReactNode } from "react";
import { Header } from "./Header";

interface Props {
  children: ReactNode;
}

export const Content: FC<Props> = ({ children }) => (
  <main className="flex flex-col w-full h-full overflow-hidden">
    <Header />
    {children}
  </main>
);
