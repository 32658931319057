import api from "api/axiosInstance";
import {
  GetBillingAdjustmentStatisticsResponseDTO,
  GetQualifiedAssetOwnerItemResponseDTO,
} from "./bessCompensation.dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ENDPOINT = "/bess-compensation";

export enum BessCompensationQueryKeys {
  GetBillingAdjustmentStatistics = "get-statistics",
  GetQualifiedAssetOwners = "get-qualified-asset-owners",
}

export const useBillingAdjustmentStatistics = () => {
  return useQuery({
    queryKey: [BessCompensationQueryKeys.GetBillingAdjustmentStatistics],
    queryFn: async (): Promise<GetBillingAdjustmentStatisticsResponseDTO> =>
      (await api.get(`${ENDPOINT}/billing-adjustment-statistics`)).data,
  });
};

export const useQualifiedAssetOwners = () => {
  return useQuery({
    queryKey: [BessCompensationQueryKeys.GetQualifiedAssetOwners],
    queryFn: async (): Promise<GetQualifiedAssetOwnerItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}`)).data,
  });
};

export const usePostBillingAdjustments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (): Promise<void> =>
      (await api.post(`${ENDPOINT}/billing-adjustments`)).data,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          BessCompensationQueryKeys.GetQualifiedAssetOwners,
          BessCompensationQueryKeys.GetBillingAdjustmentStatistics,
        ],
      });
    },
  });
};
