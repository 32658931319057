import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateAssetOwner } from "api/assetOwner/assetOwner.endpoints";
import { CreateAssetOwnerRequestDTO } from "api/assetOwner/assetOwner.dto";
import Modal from "components/Modal";
import { AddAssetOwnerForm } from "./AddAssetOwnerForm";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/SecondaryButton";
import { getEmptyCreateAssetOwnerDTO } from "api/assetOwner/assetOwner.helper";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  installerId: number;
  centered?: boolean;
}

export const AddAssetOwner: FC<Props> = ({
  label,
  installerId,
  centered = false,
}) => {
  const { t } = useTranslation();

  const [isAddAssetOwnerModalOpen, setIsAddAssetOwnerModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: createAssetOwner,
    isPending: isCreating,
    isSuccess,
  } = useCreateAssetOwner();

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateAssetOwnerRequestDTO>({
    defaultValues: getEmptyCreateAssetOwnerDTO(installerId),
  });

  // submit
  const onSubmit = handleSubmit((data: CreateAssetOwnerRequestDTO) =>
    createAssetOwner(data),
  );

  // modal close
  const closeModal = () => {
    setTimeout(() => reset(), 300);
    setIsAddAssetOwnerModalOpen(false);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  const renderAddAssetOwnerButton = () => {
    return (
      <div
        className={`pb-4 flex ${centered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton onClick={() => setIsAddAssetOwnerModalOpen(true)}>
          <UserPlusIcon className="h-5 w-5 mr-1" /> {label}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddAssetOwnerButton()}

      <Modal
        isOpen={isAddAssetOwnerModalOpen}
        onClose={closeModal}
        title={label}
        rightButtonActionHandler={onSubmit}
        isLoading={isCreating}
        loadingText={t(`assetOwnerAccountDetails.SendingInvitation`)}
      >
        <AddAssetOwnerForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
