import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import {
  OperationalStatusFlowerCloudIcon,
  OperationalStatusFlowerHubIcon,
  OperationalStatusInverterIcon,
} from "styles/Icons";
import { Trans } from "react-i18next";
import { debuggingGuide } from "constants/externalLinks";
import { isMobile } from "react-device-detect";
import { classNames } from "helpers/classNames";

export enum OperationalStatusAnimationTypes {
  ConnectingAnimation = "connecting",
  ConnectedAnimation = "connected",
  ErrorAnimation = "error",
  UnhealthyAnimation = "unhealthy",
}
export interface OperationStatusGridProps {
  leftAnimationType: OperationalStatusAnimationTypes;
  rightAnimationType: OperationalStatusAnimationTypes;
  leftText?: string;
  rightText?: string;
  leftActionText?: string;
  rightActionText?: string;
}

const OperationStatusGrid: React.FC<OperationStatusGridProps> = ({
  leftAnimationType,
  rightAnimationType,
  leftText,
  rightText,
  leftActionText,
  rightActionText,
}) => {
  return (
    <div className="w-full flex justify-center my-10">
      <div
        className={classNames(
          isMobile ? "grid-cols-1" : "grid-cols-5 gap-6",
          "grid  max-w-4xl",
        )}
      >
        {/* Inverter Section */}
        <div className="flex flex-col items-center text-center">
          <img
            src={OperationalStatusInverterIcon}
            alt="Inverter"
            className={isMobile ? "w-24" : "w-32"}
          />
        </div>

        {/* First connector and Text Section */}
        {isMobile ? (
          <div className="grid grid-cols-3 items-center w-full my-4">
            <div className="text-right">
              <p className="text-sm font-medium">{leftText ?? " "}</p>
            </div>

            <div className="flex justify-center">
              <Player
                autoplay
                loop
                src={`/operational-status/mobile-${leftAnimationType}.json`}
                style={{
                  width: "40px",
                  height: "100px",
                }}
              ></Player>
            </div>

            <div className="text-left">
              {leftActionText && (
                <div className="rounded-md bg-blue-100 p-2 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <LightBulbIcon
                        aria-hidden="true"
                        className="h-4 w-4 text-blue-500"
                      />
                    </div>
                    <div className="ml-1 flex-1 md:flex">
                      <p className="text-xs text-blue-700 text-left whitespace-pre-line">
                        <Trans
                          i18nKey={leftActionText}
                          components={{
                            DebuggingGuideLink: (
                              <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={debuggingGuide}
                                style={{ textDecoration: "underline" }}
                              />
                            ),
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col  items-center justify-start text-center pt-4 mb-8">
            <Player
              autoplay
              loop
              src={`/operational-status/${isMobile ? "mobile-" : ""}${leftAnimationType}.json`}
              style={{
                width: "160px",
                height: "40px",
                marginTop: "20px",
              }}
            ></Player>
            <p className="text-sm font-medium mt-4">{leftText ?? " "}</p>

            {leftActionText && (
              <div className="rounded-md bg-blue-100 p-2 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <LightBulbIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-blue-500"
                    />
                  </div>
                  <div className="ml-1 flex-1 md:flex">
                    <p className="text-xs text-blue-700 text-left whitespace-pre-line">
                      <Trans
                        i18nKey={leftActionText}
                        components={{
                          DebuggingGuideLink: (
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              href={debuggingGuide}
                              style={{ textDecoration: "underline" }}
                            />
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Flower Hub Section */}
        <div className="flex flex-col items-center text-center">
          <img
            src={OperationalStatusFlowerHubIcon}
            alt="Flower Hub Device"
            className={isMobile ? "w-24" : "w-32"}
          />
        </div>

        {/* Second connector and Text Section */}
        {isMobile ? (
          <div className="grid grid-cols-3 items-center w-full my-4">
            <div className="text-right">
              <p className="text-sm font-medium">{rightText ?? " "}</p>
            </div>

            <div className="flex justify-center">
              <Player
                autoplay
                loop
                src={`/operational-status/mobile-${rightAnimationType}.json`}
                style={{
                  width: "40px",
                  height: "100px",
                }}
              ></Player>
            </div>

            <div className="text-left">
              {rightActionText && (
                <div className="rounded-md bg-blue-100 p-2">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <LightBulbIcon
                        aria-hidden="true"
                        className="h-4 w-4 text-blue-500"
                      />
                    </div>
                    <div className="ml-1 flex-1 md:flex">
                      <p className="text-xs text-blue-700 text-left whitespace-pre-line">
                        <Trans
                          i18nKey={rightActionText}
                          components={{
                            DebuggingGuideLink: (
                              <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={debuggingGuide}
                                style={{ textDecoration: "underline" }}
                              />
                            ),
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex-col items-center justify-start text-center pt-4 mb-8">
            <Player
              autoplay
              loop
              src={`/operational-status/${isMobile ? "mobile-" : ""}${rightAnimationType}.json`}
              style={{
                width: "160px",
                height: "40px",
                marginTop: "20px",
              }}
            ></Player>
            <p className="text-sm font-medium mt-4">{rightText ?? " "}</p>

            {rightActionText && (
              <div className="rounded-md bg-blue-100 p-2  mt-4 ">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <LightBulbIcon
                      aria-hidden="true"
                      className="h-4 w-4 text-blue-500"
                    />
                  </div>
                  <div className="ml-1 flex-1 md:flex">
                    <p className="text-xs text-blue-700 text-left whitespace-pre-line">
                      <Trans
                        i18nKey={rightActionText}
                        components={{
                          DebuggingGuideLink: (
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              href={debuggingGuide}
                              style={{ textDecoration: "underline" }}
                            />
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Cloud Section */}
        <div className="flex flex-col items-center text-center">
          <img
            src={OperationalStatusFlowerCloudIcon}
            alt="Cloud"
            className={isMobile ? "w-24" : "w-32"}
          />
        </div>
      </div>
    </div>
  );
};

export default OperationStatusGrid;
