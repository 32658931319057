import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BadgeSize } from "components/tags/Badge";
import { Tooltip } from "components/tooltip/Tooltip";
import { getStatusColor } from "helpers/installationProgress";
import { FlowerHubStatusDTO } from "api/flowerHub/flowerHub.dto";
import { FlowerHubStatus } from "enums/flowerHubStatus.enum";

interface Props {
  flowerHubStatus: FlowerHubStatusDTO;
  size?: BadgeSize;
}

export const FlowerHubStatusTag: FC<Props> = ({
  flowerHubStatus,
  size = "small",
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <Tooltip
        message={
          flowerHubStatus.status === FlowerHubStatus.Updating ||
          flowerHubStatus.status === FlowerHubStatus.UpdateFailed
            ? t([
                `flowerHubProgress.listView.operational.${flowerHubStatus.message}`,
                "flowerHubProgress.listView.operational.FallbackMessage",
              ])
            : t([
                `flowerHubProgress.listView.installation.${flowerHubStatus.message}`,
                `flowerHubProgress.listView.operational.${flowerHubStatus.message}`,
                "flowerHubProgress.listView.installation.FallbackMessage",
              ])
        }
        color={getStatusColor(flowerHubStatus.status)}
        isPreWrapText
      >
        <Badge
          color={getStatusColor(flowerHubStatus.status)}
          text={flowerHubStatus.status}
          size={size}
        />
      </Tooltip>
    </div>
  );
};
