import { DIGIT_REGEX } from "constants/regex";
import { FC, ChangeEvent, KeyboardEvent } from "react";

interface OtpInputProps {
  value: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>, index: number) => void;
  error: string | null;
}

const OtpInput: FC<OtpInputProps> = ({ value, onChange, onKeyDown, error }) => {
  return (
    <>
      <div className="mt-8 flex justify-center space-x-2">
        {value.map((val, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            value={val}
            onChange={(e) => onChange(e, index)}
            onKeyDown={(e) => onKeyDown(e, index)}
            maxLength={1}
            className={`w-12 h-12 text-center text-xl border rounded-md ${
              error && !value.every((v) => DIGIT_REGEX.test(v))
                ? "border-red-500"
                : "border-gray-300"
            }`}
          />
        ))}
      </div>
      {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
    </>
  );
};

export default OtpInput;
