import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { GetQualifiedAssetOwnerItemResponseDTO } from "api/bessCompensation/bessCompensation.dto";
import SecondaryButton from "components/buttons/SecondaryButton";
import { saveAs } from "file-saver";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

type BillingAdjustmentExcelExportProps = {
  assetOwners: GetQualifiedAssetOwnerItemResponseDTO[];
  invoiceMonth: string;
};

const BillingAdjustmentExcelExport: FC<BillingAdjustmentExcelExportProps> = ({
  assetOwners,
  invoiceMonth,
}) => {
  const { t } = useTranslation();
  const transformData = (data: GetQualifiedAssetOwnerItemResponseDTO[]) => {
    return data.map((assetOwner) => ({
      id: assetOwner.id,
      firstName: assetOwner.firstName,
      lastName: assetOwner.lastName,
      installerId: assetOwner.installerId,
      distributorId: assetOwner.distributorId,
      zavannCustomerId: assetOwner.zavannCustomerId,
      zavannSiteId: assetOwner.zavannSiteId,
      availablePower: assetOwner.asset.minAvailablePower,
      assetModelCompensation: assetOwner.asset.assetModelCompensation,
      dateByWhichBothSitesWereActive: assetOwner.dateByWhichBothSitesWereActive,
      amount: assetOwner.compensation.amount,
    }));
  };

  const generateDataSheets = () => {
    const sentAdjustments = transformData(
      assetOwners.filter(
        (assetOwner) => assetOwner.compensation.billingAdjustmentsHasPosted,
      ),
    );
    const unsentAdjustments = transformData(
      assetOwners.filter(
        (assetOwner) => !assetOwner.compensation.billingAdjustmentsHasPosted,
      ),
    );
    const bankAccountsRegistered = transformData(
      assetOwners.filter(
        (assetOwner) => assetOwner.compensation.isPayoutInformationRegistered,
      ),
    );
    const bankAccountsMissing = transformData(
      assetOwners.filter(
        (assetOwner) => !assetOwner.compensation.isPayoutInformationRegistered,
      ),
    );

    return {
      "Sent Billing Adjustments": sentAdjustments,
      "Unsent Billing Adjustments": unsentAdjustments,
      "Bank Accounts Registered": bankAccountsRegistered,
      "Bank Accounts Missing": bankAccountsMissing,
    };
  };

  const exportToExcel = () => {
    const dataSheets = generateDataSheets();
    const workbook = XLSX.utils.book_new();

    Object.entries(dataSheets).forEach(([sheetName, data]) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${invoiceMonth}_summary.xlsx`);
  };

  return (
    <SecondaryButton onClick={() => exportToExcel()} className="mr-3">
      <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
      {t("billingAdjustment.Summary")}
    </SecondaryButton>
  );
};

export default BillingAdjustmentExcelExport;
