import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import { CreateSystemNotificationDTO } from "./systemNotification.dto";
import { SystemNotification } from "./systemNotification.type";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ENDPOINT = "/system-notification";

export enum SystemNotificationQueryKeys {
  GetAll = "system-notification",
  GetActiveZavann = "active-zavann",
  GetActiveFlower = "active-flower",
}

export const useSystemNotifications = () =>
  useQuery({
    queryKey: [SystemNotificationQueryKeys.GetAll],
    queryFn: async (): Promise<SystemNotification[]> =>
      (await api.get(ENDPOINT)).data,
  });

export const useActiveZavannSystemNotifications = () =>
  useQuery({
    queryKey: [SystemNotificationQueryKeys.GetActiveZavann],
    queryFn: async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveZavann}`,
        )
      ).data,
  });

export const useActiveZavannSystemNotificationsByCode = (code: string) =>
  useQuery({
    queryKey: [SystemNotificationQueryKeys.GetActiveZavann, code],
    queryFn: async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveZavann}/${code}`,
        )
      ).data,
  });

export const useCreateSystemNotification = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateSystemNotificationDTO,
    ): Promise<SystemNotification> => (await api.post(ENDPOINT, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SystemNotificationQueryKeys.GetAll],
      });
      notification.info(`System notification created`);
    },
    onError: () => {
      notification.error();
    },
    ...options,
  });
};

export const useDeleteSystemNotification = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SystemNotificationQueryKeys.GetAll],
      });
      notification.info(`System notification deleted`);
    },
    onError: () => {
      notification.error("Something went wrong, please try again");
    },
    ...settings,
  });
};

export const useActiveFlowerSystemNotifications = () =>
  useQuery({
    queryKey: [SystemNotificationQueryKeys.GetActiveFlower],
    queryFn: async (): Promise<SystemNotification> =>
      (
        await api.get(
          `${ENDPOINT}/${SystemNotificationQueryKeys.GetActiveFlower}`,
        )
      ).data,
  });
