import { BadgeColor } from "components/tags/Badge";
import {
  FlowerHubInstallationProgressTitle,
  FlowerHubInstallationStatusMessageCode,
  FlowerHubStatus,
} from "enums/flowerHubStatus.enum";

type ProgressInfo = {
  percentage: number;
  title?: FlowerHubInstallationProgressTitle;
};

export function getProgressInfo(
  message: FlowerHubInstallationStatusMessageCode,
): ProgressInfo {
  const progressMap: Record<
    FlowerHubInstallationStatusMessageCode,
    ProgressInfo
  > = {
    [FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub]: {
      percentage: 5,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningStarting]:
      {
        percentage: 10,
        title: FlowerHubInstallationProgressTitle.ConfigurationStarting,
      },
    [FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningFailed]:
      {
        percentage: 20,
        title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
      },
    [FlowerHubInstallationStatusMessageCode.FlowerHubWaitingForCertificateProvisioning]:
      {
        percentage: 30,
        title: FlowerHubInstallationProgressTitle.ConfigurationStarting,
      },
    [FlowerHubInstallationStatusMessageCode.WaitingToCreateIoTThing]: {
      percentage: 35,
      title: FlowerHubInstallationProgressTitle.ConfiguringFlowerHub,
    },
    [FlowerHubInstallationStatusMessageCode.IoTThingCreationFailed]: {
      percentage: 35,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.WaitingToCreateCoreDevice]: {
      percentage: 45,
      title: FlowerHubInstallationProgressTitle.ConfiguringFlowerHub,
    },
    [FlowerHubInstallationStatusMessageCode.CoreDeviceCreationFailed]: {
      percentage: 45,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.WaitingToStartDeployment]: {
      percentage: 50,
      title: FlowerHubInstallationProgressTitle.ConfiguringFlowerHub,
    },
    [FlowerHubInstallationStatusMessageCode.GreengrassDeploymentSucceeded]: {
      percentage: 100,
      title: FlowerHubInstallationProgressTitle.ConfigurationCompleted,
    },
    [FlowerHubInstallationStatusMessageCode.GreengrassDeploymentInProgress]: {
      percentage: 70,
      title: FlowerHubInstallationProgressTitle.ConfiguringFlowerHub,
    },
    [FlowerHubInstallationStatusMessageCode.GreengrassDeploymentFailed]: {
      percentage: 60,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.UnableToFindGreengrassDeployment]: {
      percentage: 60,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.PendingDeploymentDueToMissingBiddingRegion]:
      {
        percentage: 70,
        title: FlowerHubInstallationProgressTitle.ConfigurationPending,
      },
    [FlowerHubInstallationStatusMessageCode.GreengrassDeploymentTimeout]: {
      percentage: 80,
      title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
    },
    [FlowerHubInstallationStatusMessageCode.GreengrassDeploymentUnknownStatus]:
      {
        percentage: 60,
        title: FlowerHubInstallationProgressTitle.ConfigurationFailed,
      },
    [FlowerHubInstallationStatusMessageCode.FirstInstallationCompleted]: {
      percentage: 100,
      title: FlowerHubInstallationProgressTitle.ConfigurationCompleted,
    },
  };

  return progressMap[message];
}

export function getStatusColor(status: FlowerHubStatus) {
  const progressMap: Record<FlowerHubStatus, BadgeColor> = {
    [FlowerHubStatus.Connected]: "green",
    [FlowerHubStatus.Connecting]: "blue",
    [FlowerHubStatus.Unhealthy]: "yellow",
    [FlowerHubStatus.ConnectionFailed]: "red",
    [FlowerHubStatus.UpdateFailed]: "red",
    [FlowerHubStatus.Updating]: "blue",
    [FlowerHubStatus.DeviceError]: "red",
    [FlowerHubStatus.ConfigurationCompleted]: "blue",
    [FlowerHubStatus.ConfigurationFailed]: "red",
    [FlowerHubStatus.ConfigurationPending]: "yellow",
    [FlowerHubStatus.Configuring]: "blue",
    [FlowerHubStatus.Unknown]: "gray",
  };

  return progressMap[status];
}

export function getCurrentStepIndex(
  message: FlowerHubInstallationStatusMessageCode,
  firstDeploymentCompletedAt?: Date,
): number {
  if (firstDeploymentCompletedAt) return 5;
  switch (message) {
    // Certificate Provisioning
    case FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub:
    case FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningStarting:
    case FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningFailed:
    case FlowerHubInstallationStatusMessageCode.FlowerHubWaitingForCertificateProvisioning:
      return 1;

    // IoT Stack Creating
    case FlowerHubInstallationStatusMessageCode.WaitingToCreateIoTThing:
    case FlowerHubInstallationStatusMessageCode.IoTThingCreationFailed:
      return 2;

    // Core Device Creating
    case FlowerHubInstallationStatusMessageCode.WaitingToCreateCoreDevice:
    case FlowerHubInstallationStatusMessageCode.CoreDeviceCreationFailed:
      return 3;

    // First Deployment
    case FlowerHubInstallationStatusMessageCode.WaitingToStartDeployment:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentInProgress:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentSucceeded:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentFailed:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentTimeout:
    case FlowerHubInstallationStatusMessageCode.PendingDeploymentDueToMissingBiddingRegion:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentUnknownStatus:
    case FlowerHubInstallationStatusMessageCode.UnableToFindGreengrassDeployment:
      return 4;

    default:
      return 5;
  }
}

export function isInstallationFailureMessageCode(
  message: FlowerHubInstallationStatusMessageCode,
): boolean {
  switch (message) {
    case FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub:
    case FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningFailed:
    case FlowerHubInstallationStatusMessageCode.IoTThingCreationFailed:
    case FlowerHubInstallationStatusMessageCode.CoreDeviceCreationFailed:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentFailed:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentTimeout:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentUnknownStatus:
    case FlowerHubInstallationStatusMessageCode.UnableToFindGreengrassDeployment:
      return true;

    default:
      return false;
  }
}

export function mapInstallationFailureActionByMessageCode(
  message: FlowerHubInstallationStatusMessageCode,
): string | undefined {
  switch (message) {
    case FlowerHubInstallationStatusMessageCode.NotReceivedSignalFromFlowerHub:
    case FlowerHubInstallationStatusMessageCode.FlowerHubCertificateProvisioningFailed:
      return "IE1";
    case FlowerHubInstallationStatusMessageCode.IoTThingCreationFailed:
      return "IE2";
    case FlowerHubInstallationStatusMessageCode.CoreDeviceCreationFailed:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentTimeout:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentUnknownStatus:
    case FlowerHubInstallationStatusMessageCode.UnableToFindGreengrassDeployment:
    case FlowerHubInstallationStatusMessageCode.GreengrassDeploymentFailed:
      return "IE3";
    case FlowerHubInstallationStatusMessageCode.PendingDeploymentDueToMissingBiddingRegion:
      return "IE4";

    default:
      return undefined;
  }
}
