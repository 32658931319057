import React, { FC } from "react";
import { AddressDTO } from "api/shared/address.dto";

interface Props {
  address: AddressDTO;
}

export const Address: FC<Props> = ({ address }) => {
  const { street, postalCode, city } = address;
  return (
    <div className="space-y-1">
      {!!street && <p>{street}</p>}
      <p>
        {!!postalCode && <span>{postalCode} </span>}
        {!!city && <span>{city}</span>}
      </p>
    </div>
  );
};
