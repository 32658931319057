import { isEmptyArray } from "helpers/array";
import { FC, useMemo } from "react";
import CustomPlaceholder from "components/CustomPlaceholder";
import {
  ArrowTopRightOnSquareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useQualifiedAssetOwners } from "api/bessCompensation/bessCompensation.endpoints";
import { GetQualifiedAssetOwnerItemResponseDTO } from "api/bessCompensation/bessCompensation.dto";
import { Filter, filterFunction } from "components/filters/Filters";
import { BillingAdjustmentStatusTag } from "components/tags/BillingAdjustmentStatusTag";
import {
  PayoutInformationStatusTypes,
  PostBillingAdjustmentsStatusTypes,
} from "enums/postBillingAdjustmentStatus.enums";
import { TableOptions } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { Role } from "enums/role.enum";

import { PayoutInformationStatusTag } from "components/tags/PayoutInformationStatusTag";

import { PostBillingAdjustment } from "./postBillingAdjustment/PostBillingAdjustment";
import BillingAdjustmentExcelExport from "./BillingAdjustmentExcelExport";
import { currentInvoicedMonth } from "pages/app/billing-adjustment/BillingAdjustmentPage.helper";
import { getAssetOwnerPageRoute } from "helpers/assetOwner";
import { TanStackTable } from "../table/TanStackTable";

export const QualifiedAssetOwnerList: FC = () => {
  const { t } = useTranslation();

  // get qualified assetOwner list
  const { data: assetOwners, isLoading, isError } = useQualifiedAssetOwners();

  const columns: TableOptions<GetQualifiedAssetOwnerItemResponseDTO>["columns"] =
    useMemo(
      () => [
        {
          header: t(`billingAdjustment.Name`),
          accessorKey: "name",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            `${row.firstName} ${row.lastName}`,
          filterFn: "includesString",
        },
        {
          header: t(`billingAdjustment.Customer id`),
          accessorKey: "zavannCustomerId",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            row?.zavannCustomerId,
          filterFn: "includesString",
        },
        {
          header: t(`billingAdjustment.Available Power`),
          accessorKey: "minAvailablePower",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            `${row?.asset?.minAvailablePower} kW`,
          filterFn: "includesString",
        },
        {
          header: t(`billingAdjustment.Model compensation`),
          accessorKey: "assetModelCompensation",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            `${row?.asset?.assetModelCompensation} SEK`,
          filterFn: "includesString",
        },
        {
          header: t(`billingAdjustment.Compensation`),
          accessorKey: "amount",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            `${row?.compensation?.amount} SEK`,
          filterFn: "includesString",
        },
        {
          header: t(`billingAdjustment.Billing adjustment`),
          accessorKey: "billingAdjustmentStatus",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            row?.compensation?.billingAdjustmentsHasPosted
              ? PostBillingAdjustmentsStatusTypes.Sent
              : PostBillingAdjustmentsStatusTypes.NotSent,
          cell: (row) => <BillingAdjustmentStatusTag status={row.getValue()} />,
          filterFn: filterFunction,
        },
        {
          header: t(`billingAdjustment.Bank account`),
          accessorKey: "payoutInformationStatus",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            row?.compensation?.isPayoutInformationRegistered
              ? PayoutInformationStatusTypes.Registered
              : PayoutInformationStatusTypes.NotRegistered,
          cell: (row) => <PayoutInformationStatusTag status={row.getValue()} />,
          filterFn: filterFunction,
        },
        {
          header: t(`billingAdjustment.View details`),
          accessorKey: "id",
          accessorFn: (row: GetQualifiedAssetOwnerItemResponseDTO) =>
            getAssetOwnerPageRoute(
              Role.Flower,
              row?.id,
              row?.installerId,
              row?.distributorId,
            ),
          cell: (row) => (
            <Link
              to={row.getValue()}
              target="blank"
              className="w-full flex justify-center"
            >
              <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </Link>
          ),
        },
      ],
      [assetOwners],
    );

  const filters: Filter[] = useMemo(
    () => [
      {
        id: "billingAdjustmentStatus",
        name: t(`billingAdjustment.Billing adjustment`),
        options: [
          PostBillingAdjustmentsStatusTypes.Sent,
          PostBillingAdjustmentsStatusTypes.NotSent,
        ],
      },
      {
        id: "payoutInformationStatus",
        name: t(`billingAdjustment.Bank account`),
        options: [
          PayoutInformationStatusTypes.Registered,
          PayoutInformationStatusTypes.NotRegistered,
        ],
      },
    ],
    [],
  );

  if (isLoading || isError || !assetOwners || assetOwners.length === 0) {
    return (
      <CustomPlaceholder
        isEmpty={isEmptyArray(assetOwners)}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`billingAdjustment.QualifiedAssetOwnerListError`)}
        emptyDataMessage={t(`assetOwnerList.QualifiedAssetOwnerListEmpty`)}
        loaderSize="page"
      />
    );
  }
  return (
    <div className="block">
      <div className=" relative top-11 w-fit min-w-60 rounded flex justify-between items-end">
        <BillingAdjustmentExcelExport
          assetOwners={assetOwners}
          invoiceMonth={currentInvoicedMonth}
        />
        <PostBillingAdjustment assetOwners={assetOwners} />
      </div>

      <TanStackTable columns={columns} data={assetOwners} filters={filters} />
    </div>
  );
};
