export enum RouteUrl {
  AllRoutes = "*",
  Home = "/",
  Login = "/login",
  App = "/app",
  Distributors = "distributors",
  Installers = "installers",
  Assets = "assets",
  AssetOwners = "asset-owners",
  SignUpFlower = "sign-up-flower",
  SignUpDistributor = "sign-up-distributor",
  SignUpInstaller = "sign-up-installer",
  SignUpAssetOwner = "sign-up-asset-owner",
  ForgotPassword = "forgot-password",
  ResetPassword = "reset-password",
  Dashboard = "dashboard",
  Organization = "organization",
  Settings = "settings",
  Battery = "battery",
  ConsumptionAndRevenue = "consumption-and-revenue",
  InvoiceList = "invoices",
  SignUpFlow = "sign-up-flow",
  Profile = "profile",
  ManualAction = "manual-action",
  BillingAdjustment = "billing-adjustment",
}
