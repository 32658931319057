import { classNames } from "../../helpers/classNames";
import React from "react";
import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";

export type ElementDef<T> = (row: T) => {
  left: {
    key: string;
    cell: () => React.ReactElement | undefined;
  }[];
  right: {
    key: string;
    cell: () => React.ReactElement | undefined;
  }[];
};

export interface Identifiable {
  id: string | number;
}

interface Props<T> {
  virtualRow: VirtualItem;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  row: T & Identifiable;
  elements: ElementDef<T>;
  onSelectRow?: (data: T) => void;
}

export const LI = <T,>({
  virtualRow,
  row,
  elements,
  onSelectRow,
  rowVirtualizer,
}: Props<T>) => {
  return (
    <li
      className="absolute top-0 left-0 w-full"
      key={virtualRow.index}
      style={{
        height: `${virtualRow.size}px`,
        transform: `translateY(${virtualRow.start}px)`,
      }}
      data-index={virtualRow.index} //needed for dynamic row height measurement
      ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
    >
      <div
        key={row.id}
        className={classNames(
          `list-none flex h-full w-full absolute py-4 px-3 text-black text-sm font-normal leading-relaxed justify-between items-center ${virtualRow.index % 2 === 0 ? "bg-[#f3f3f2]" : "bg-white"}`,
          onSelectRow ? "cursor-pointer hover:opacity-75" : "",
        )}
        onClick={() => onSelectRow && onSelectRow(row)}
      >
        <div className="flex flex-col items-start justify-center truncate gap-3">
          {elements(row).left.map((elem) => {
            return elem.cell() ? (
              <div className="w-full truncate" key={elem.key}>
                {elem.cell()}
              </div>
            ) : null;
          })}
        </div>
        <div className="flex flex-col items-end justify-center gap-3">
          {elements(row).right.map((elem) => {
            return elem.cell() ? (
              <div className="w-full truncate" key={elem.key}>
                {elem.cell()}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </li>
  );
};
