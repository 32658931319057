import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { AssetOwnerItemDTO } from "../../api/assetOwner/assetOwner.dto";
import { AssetOwnerStatusTag } from "../tags/AssetOwnerStatusTag";
import { AssetOwnerStatus } from "../../enums/assetOwnerStatus.enum";
import { FlowerHubStatusTag } from "../tags/FlowerHubStatusTag";
import { CompensationEligibilityStatusTag } from "../tags/CompensationEligibilityStatusTag";
import {
  CompensationStatus,
  CompensationStatusMessage,
} from "../../enums/assetOwnerCompensationStatus.enum";
import { Role } from "../../enums/role.enum";
import { Table } from "../tableBeta/Table";
import { isFlower } from "../../helpers/role";
import { useTranslation } from "react-i18next";
import {
  FilterDTO,
  FilterWithLabelDTO,
  InfiniteQueryResult,
} from "../../api/pagination/pagination.type";

interface Props {
  paginationQuery: InfiniteQueryResult<AssetOwnerItemDTO>;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  onAssetOwnerClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
  userRole: Role;
  createColumnFilter: (filter: FilterDTO[]) => FilterWithLabelDTO[];
}

export const AssetOwnerTable: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onAssetOwnerClick,
  userRole,
  createColumnFilter,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: AssetOwnerItemDTO) => {
    if (row) onAssetOwnerClick(row.id, row.installerId, row.distributorId);
  };

  // Table columns definition
  const columns: ColumnDef<AssetOwnerItemDTO>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: `${t("assetOwnerList.columnHeader.name")}`,
        accessorFn: (row: AssetOwnerItemDTO) =>
          `${row.firstName} ${row.lastName}`,
      },
      {
        accessorKey: "accountStatus",
        header: `${t("assetOwnerList.columnHeader.account")}`,
        cell: (row) => (
          <AssetOwnerStatusTag status={row.getValue() as AssetOwnerStatus} />
        ),
        enableSorting: false,
      },
      {
        accessorKey: "address",
        header: `${t("assetOwnerList.columnHeader.address")}`,
        cell: (row) => {
          if (!row.row.original?.address) {
            return null;
          }
          return (
            <div className="flex flex-col truncate leading-5">
              <span className="truncate">
                {row.row.original?.address.street}
              </span>
              <span className="truncate">
                {row.row.original?.address.postalCode}{" "}
                {row.row.original?.address.city}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "assetModel",
        header: `${t("assetOwnerList.columnHeader.assetModel")}`,
        cell: (row) => {
          return (
            <div className="flex flex-col truncate leading-5">
              <span className="truncate">
                {row.row.original?.asset?.assetModel.manufacturer}
              </span>
              <span className="truncate">
                {row.row.original?.asset?.assetModel.name}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "serialNumber",
        header: `${t("assetOwnerList.columnHeader.serialNumber")}`,
        accessorFn: (row: AssetOwnerItemDTO) => row?.asset?.serialNumber || "",
      },
      {
        accessorKey: "flowerHub",
        header: `${t("assetOwnerList.columnHeader.flowerHub")}`,
        cell: (row) => {
          if (!row.row.original?.asset?.flowerHubStatus) {
            // if asset owner is not associate with an asset , cell will be empty
            return null;
          }
          return (
            <FlowerHubStatusTag
              flowerHubStatus={row.row.original?.asset?.flowerHubStatus}
            />
          );
        },
        enableSorting: false,
      },
      {
        accessorKey: "compensation",
        header: `${t("assetOwnerList.columnHeader.compensation")}`,
        cell: (row) => {
          return (
            <CompensationEligibilityStatusTag
              status={
                row.row.original?.compensation?.status as CompensationStatus
              }
              message={
                row.row.original?.compensation
                  ?.message as CompensationStatusMessage
              }
            />
          );
        },
        enableSorting: false,
      },
    ],
    [],
  );

  // Column visibility based on user role
  const columnVisibility = {
    assetModel: isFlower(userRole),
    address: !isFlower(userRole),
  };

  return (
    <Table
      columns={columns}
      paginationQuery={paginationQuery}
      onSelectRow={handleRowClick}
      columnVisibility={columnVisibility}
      setPaginationUrl={setPaginationUrl}
      title={`${t("assetOwnerList.AssetOwners")}`}
      searchPlaceholder={`${t("assetOwnerList.SearchForAssetOwners")}`}
      createColumnFilter={createColumnFilter}
    />
  );
};
