import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItems,
  Transition,
} from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import {
  FilterValues,
  FilterWithLabelDTO,
  OptionDTO,
} from "../../api/pagination/pagination.type";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { createFilterParams } from "../../api/pagination/pagination.helper";
import useDebounce from "../../hooks/useDebounce";

interface Props {
  setPaginationUrl: React.Dispatch<React.SetStateAction<string>>;
  filters: FilterWithLabelDTO[];
}

export const FilterMenu: FC<Props> = ({ filters, setPaginationUrl }) => {
  const { t } = useTranslation();

  // State to store the column filters
  const [columnFilters, setColumnFilters] = useState<FilterValues[]>([]);

  // Debounce the column filters state

  // Convert columnFilters to a string for debouncing
  const debouncedColumnFiltersString = useDebounce(
    JSON.stringify(columnFilters),
    1000,
  );

  // Parse the debounced string back to an array
  const debouncedColumnFilters = JSON.parse(debouncedColumnFiltersString);

  // Function to update the column filters
  const changeColumnFilter = (option: string, column: string) => {
    setColumnFilters((prev: FilterValues[]) => {
      const existingFilter = prev.find((filter) => filter.id === column);
      if (!existingFilter) {
        // If the filter doesn't exist, create a new one
        return [
          ...prev,
          {
            id: column,
            value: [option],
          },
        ];
      } else {
        // If the filter already exists, update its value
        const isActive = existingFilter.value.includes(option);
        const updatedFilter = {
          ...existingFilter,
          value: isActive
            ? existingFilter.value.filter((s: string) => s !== option)
            : [...existingFilter.value, option],
        };
        // Replace the existing filter in the array
        return prev.map((f) => (f.id === column ? updatedFilter : f));
      }
    });
  };

  // Update the pagination URL when the column filters change
  useEffect(() => {
    const filterParams = createFilterParams(debouncedColumnFilters);
    setPaginationUrl((queryString) => {
      const parsedQueryString = qs.parse(queryString);
      return qs.stringify(
        {
          ...parsedQueryString,
          ...filterParams,
        },
        { arrayFormat: "repeat" },
      );
    });
  }, [debouncedColumnFilters]);

  const allSectionsEmpty = filters.every(
    (section) => section.options.length === 0,
  );

  return (
    <Menu as="div" className="relative flex">
      <MenuButton className="right-0 flex items-center font-medium px-3 py-1">
        <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-700 cursor-pointer flex items-center leading-4" />
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 mt-10 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          {allSectionsEmpty ? (
            <div className="px-4 py-3 text-sm text-gray-500 flex justify-center">
              {t("No filters available.")}
            </div>
          ) : (
            filters.map((section: FilterWithLabelDTO) => {
              const filterStatuses =
                columnFilters.find((f) => f.id === section.id)?.value || [];
              if (section.options.length === 0) return null;
              return (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-gray-200 px-4 py-3"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-mx-2 -my-3 flow-root">
                        <DisclosureButton className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                          <span className="text-sm font-medium text-gray-900">
                            {t(`columnFilterGroup.${section.id}`)}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </DisclosureButton>
                      </h3>
                      <DisclosurePanel className="pt-4">
                        <div className="space-y-4">
                          {section.options.map(
                            (option: OptionDTO, optionIdx: number) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${option.value}-${optionIdx}`}
                                  type="checkbox"
                                  checked={filterStatuses.includes(
                                    option.value,
                                  )}
                                  className="h-4 w-4 rounded border-gray-300 text-sm text-indigo-600 focus:ring-0 focus:ring-offset-0 cursor-pointer"
                                  onChange={() =>
                                    changeColumnFilter(option.value, section.id)
                                  }
                                />
                                <label
                                  htmlFor={`filter-${option.value}-${optionIdx}`}
                                  className="ml-3 min-w-0 flex-1 text-sm text-gray-500 cursor-pointer"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ),
                          )}
                        </div>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              );
            })
          )}
        </MenuItems>
      </Transition>
    </Menu>
  );
};
