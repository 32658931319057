import { dateToChartName } from "helpers/date";
import { ConsumptionMonth } from "types/consumptionMonth.type";
import {
  InvoiceItemDTO,
  InvoiceDataInGraph,
  GetInvoiceItemResponseDTO,
} from "types/invoice.type";

export type ConsumptionChartItem = {
  name: string;
  kWh: string;
  SEK: number;
  flower: number;
};

export const datePreviousMonth = (invoicedMonth: string): string => {
  const [year, month, day] = invoicedMonth.split("-").map(Number);
  const prevYear = month === 1 ? year - 1 : year;
  const prevMonth = month === 1 ? 12 : month - 1;
  const resultingDate = [prevYear, prevMonth, day]
    .map((dateComponent) => {
      if (dateComponent.toString().length === 1) {
        return `0${dateComponent}`;
      }
      return dateComponent;
    })
    .join("-");

  return resultingDate;
};

// invoiceMonth is always beginning at the first day of the month for both consumption and invoice data,
// i.e. YYYY-MM-01
// An invoice relates to the data of the previous month,
// i.e. the invoice with invoiceMonth 2023-01-01 is related to the consumption with invoiceMonth 2022-12-01
export const sameInvoicedMonth = (
  invoiceMonth: string,
  consumptionMonth: string,
): boolean => {
  return datePreviousMonth(invoiceMonth) === consumptionMonth;
};

export const flowerCompensation = (
  invoice: GetInvoiceItemResponseDTO,
): string => {
  const flowerCompensationItemId = "100005";
  const subGroupInvoices = invoice.sub_group_invoices;

  const compensation =
    subGroupInvoices
      ?.flatMap((invoice: InvoiceItemDTO) => invoice.invoice_lines)
      ?.find((invoiceLine) => invoiceLine.item_id === flowerCompensationItemId)
      ?.amount ?? "0";

  return compensation;
};

const negateSEK = (amount: number): number => {
  return -1 * Number(amount);
};

const aggregateMultipleInvoicesForSameMonth = (
  invoices: GetInvoiceItemResponseDTO[],
): InvoiceDataInGraph => {
  const combinedDataOfAllInvoices: InvoiceDataInGraph = {
    totalInvoiceAmount: 0,
    totalFlowerCompensationAmount: 0,
  };

  invoices.forEach((invoice) => {
    combinedDataOfAllInvoices.totalInvoiceAmount += Number(
      invoice.total_amount,
    );
    combinedDataOfAllInvoices.totalFlowerCompensationAmount += Number(
      flowerCompensation(invoice),
    );
  });
  return combinedDataOfAllInvoices;
};

export const getChartData = (
  months?: ConsumptionMonth[],
  invoices?: GetInvoiceItemResponseDTO[],
): ConsumptionChartItem[] =>
  (months ?? [])
    .filter((month) =>
      (invoices ?? []).some((inv) =>
        sameInvoicedMonth(inv.invoiced_month, month.invoiced_month),
      ),
    )
    .map((month) => {
      const invoicesForSameMonth = (invoices ?? []).filter((invoice) =>
        sameInvoicedMonth(invoice.invoiced_month, month.invoiced_month),
      );

      if (!invoicesForSameMonth) {
        // Will never be able to get here due to the filter logic above but TypeScript does not catch that
        throw Error("Missing invoice for month");
      }

      const InvoicesForSameMonthAggregated =
        aggregateMultipleInvoicesForSameMonth(invoicesForSameMonth);

      return {
        name: dateToChartName(month.invoiced_month),
        kWh: month.volume,
        SEK: negateSEK(InvoicesForSameMonthAggregated.totalInvoiceAmount),
        flower: negateSEK(
          InvoicesForSameMonthAggregated.totalFlowerCompensationAmount,
        ),
      };
    });

const yAxisMaxCalc = (maxValue: number, yMaxMultiple: number): number => {
  const yMaxRoundedToMultiple =
    Math.ceil(maxValue / yMaxMultiple) * yMaxMultiple;

  // This is to avoid the max value of the graph being close to to the max value of the y axis
  // since this not visually appealing
  return maxValue > 0.9 * yMaxRoundedToMultiple
    ? yMaxRoundedToMultiple + yMaxMultiple / 2
    : yMaxRoundedToMultiple;
};

export const getChartDomainMoney = (chartData: ConsumptionChartItem[]) => {
  const max_money = Math.max(
    ...chartData.map((item) => Number(item.flower)),
    ...chartData.map((item) => Number(item.SEK)),
  );

  return [0, yAxisMaxCalc(max_money, 1000)];
};

export const getChartDomainPower = (chartData: ConsumptionChartItem[]) => {
  const max_energy = Math.max(...chartData.map((item) => Number(item.kWh)));

  return [0, yAxisMaxCalc(max_energy, 1000)];
};
