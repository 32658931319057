import { ModalContentWrapper } from "components/ModalContentWrapper";
import { useTranslation } from "react-i18next";

export const DeleteInstallerConfirmation = () => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          {t(`installer.Are you sure that you want to delete this installer?`)}
        </p>
      </div>
    </ModalContentWrapper>
  );
};
