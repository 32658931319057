import { SmoothUp } from "components/animations/SnapUp";
import { LargeLoader } from "components/loaders/LargeLoader";

export const PageLoader = () => (
  // a component that centers in the middle of the page
  <div className="flex justify-center items-center w-full bg-grey-100 z-50 h-screen">
    <SmoothUp>
      <LargeLoader />
    </SmoothUp>
  </div>
);
