import { classNames } from "helpers/classNames";
import { FC, ReactNode } from "react";

interface ContentCardProps {
  title: string;
  color: string;
  icon: ReactNode;
  content: ReactNode;
}

export const ContentCard: FC<ContentCardProps> = ({
  title,
  color,
  icon,
  content,
}) => {
  return (
    <div
      className={classNames(
        "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 px-20",
      )}
    >
      <div className="flex justify-content items-center">
        <span
          className={classNames(
            `bg-${color}-50`,
            `text-${color}-700`,
            "inline-flex rounded-lg p-3 ring-4 ring-white w-12 h-12 mr-4 ",
          )}
        >
          {icon}
        </span>
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          <span aria-hidden="true" className="absolute inset-0" />
          {title}
        </h3>
      </div>
      <div className="mt-4">{content}</div>
    </div>
  );
};
