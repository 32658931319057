import { FC, useState } from "react";
import {
  AssetModel,
  AssetModelConfiguration,
  Manufacturer,
  manufacturers,
  fuses,
  Fuse,
} from "components/installationFlow/bessTypes";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { isAssetInformationComplete } from "../installFlowerHub.helpers";
import { ActionButton } from "../../buttons/ActionButton";
import { DECIMAL_REGEX } from "constants/regex";

export interface AssetInformation {
  manufacturer?: Manufacturer;
  assetModel?: AssetModel;
  configuration?: AssetModelConfiguration;
  inverterPowerCapacity?: number;
}

type Props = {
  changeStep: (step: number) => void;
  assetInformation: AssetInformation;
  setAssetInformation: (assetInformation: AssetInformation) => void;
  fuse?: Fuse;
  setFuse: (fuse: Fuse) => void;
};

export const AssetInformationStep: FC<Props> = ({
  changeStep,
  assetInformation,
  setAssetInformation,
  fuse,
  setFuse,
}) => {
  const { t } = useTranslation();
  const [inverterCapacityErrorMessage, setInverterCapacityErrorMessage] =
    useState<string | undefined>(undefined);

  const setManufacturer = (manufacturer: Manufacturer) => {
    if (manufacturer === assetInformation.manufacturer) {
      return;
    } else {
      setAssetInformation({
        ...assetInformation,
        manufacturer: manufacturer,
        assetModel: undefined,
        configuration: undefined,
      });
    }
  };

  const setAssetModel = (assetModel: AssetModel) => {
    if (assetModel === assetInformation.assetModel) {
      return;
    } else {
      setAssetInformation({
        ...assetInformation,
        manufacturer: assetInformation.manufacturer,
        assetModel: assetModel,
        configuration: undefined,
      });
    }
  };

  const onInverterCapacityChange = (inverterPowerCapacityInput: string) => {
    if (inverterPowerCapacityInput === "") {
      setInverterCapacityErrorMessage(undefined);
      setAssetInformation({
        ...assetInformation,
        inverterPowerCapacity: undefined,
      });
      return;
    }

    if (inverterPowerCapacityInput.match(DECIMAL_REGEX)) {
      setInverterCapacityErrorMessage(undefined);
      setAssetInformation({
        ...assetInformation,
        inverterPowerCapacity: +inverterPowerCapacityInput,
      });
    } else {
      setInverterCapacityErrorMessage(
        t("batteryOverview.Must be a valid capacity"),
      );
      setAssetInformation({
        ...assetInformation,
        inverterPowerCapacity: undefined,
      });
    }
  };

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold text-center">
        {t("batteryOverview.Property information")}
      </h2>

      {/* Dropdowns */}
      <div className="space-y-6">
        <AssetInformationStepDropdown
          label={t(`batteryOverview.MainFuseSize`)}
          items={[
            {
              label: t(`batteryOverview.PickAFuseSize`),
              value: null,
              key: "None",
            },
            ...fuses.map((item) => ({
              label: "" + item.ampere,
              value: item,
              key: "" + item.ampere,
            })),
          ]}
          selectedItem={
            fuse
              ? {
                  label: "" + fuse.ampere,
                  value: fuse,
                  key: "" + fuse.ampere,
                }
              : {
                  label: t(`batteryOverview.PickAFuseSize`),
                  value: null,
                  key: "None",
                }
          }
          onItemSelect={(item) => setFuse(item.value)}
        />
        <AssetInformationStepDropdown
          label={t(`batteryOverview.InverterManufacturer`)}
          items={[
            {
              label: t(`batteryOverview.PickAManufacturer`),
              value: null,
              key: "None",
            },
            ...manufacturers.map((item) => ({
              label: item.label,
              value: item,
              key: item.dbEntry,
            })),
          ]}
          selectedItem={
            assetInformation.manufacturer
              ? {
                  label: assetInformation.manufacturer.label,
                  value: assetInformation.manufacturer,
                  key: assetInformation.manufacturer.dbEntry,
                }
              : {
                  label: t(`batteryOverview.PickAManufacturer`),
                  value: null,
                  key: "None",
                }
          }
          onItemSelect={(item) => setManufacturer(item.value)}
        />
        <AssetInformationStepDropdown
          label={t(`batteryOverview.Asset model`)}
          items={[
            {
              label: t(`batteryOverview.PickAModel`),
              value: null,
              key: "None",
            },
            ...(assetInformation.manufacturer?.assetModels.map((item) => ({
              label: item.label,
              value: item,
              key: "" + item.dbEntryId,
            })) ?? []),
          ]}
          isDisabled={!assetInformation.manufacturer}
          selectedItem={
            assetInformation.assetModel
              ? {
                  label: assetInformation.assetModel.label,
                  value: assetInformation.assetModel,
                  key: "" + assetInformation.assetModel.dbEntryId,
                }
              : {
                  label: t(`batteryOverview.PickAModel`),
                  value: null,
                  key: "None",
                }
          }
          onItemSelect={(item) => setAssetModel(item.value)}
        />
        <AssetInformationStepDropdown
          label={t(`batteryOverview.Battery energy capacity (kWh)`)}
          items={[
            {
              label: t(`batteryOverview.Select battery energy capacity`),
              value: null,
              key: "None",
            },
            ...(assetInformation.assetModel?.modelConfigurations.map(
              (item) => ({
                label: "" + item.ratedEnergy,
                value: item,
                key: "" + item.ratedEnergy,
              }),
            ) ?? []),
          ]}
          isDisabled={!assetInformation.assetModel}
          selectedItem={
            assetInformation.configuration
              ? {
                  label: "" + assetInformation.configuration.ratedEnergy,
                  value: assetInformation.configuration,
                  key: "" + assetInformation.configuration.ratedEnergy,
                }
              : {
                  label: t(`batteryOverview.Select battery energy capacity`),
                  value: null,
                  key: "None",
                }
          }
          onItemSelect={(item) =>
            setAssetInformation({
              ...assetInformation,
              configuration: item.value,
            })
          }
        />

        {/* Static power capacity info */}
        <StaticInfo
          label={t("batteryOverview.Battery power capacity (kW)")}
          value={assetInformation.configuration?.ratedPower}
          helpText={t(
            "batteryOverview.Pre-defined, depends on above configuration",
          )}
        />

        {/* Inverter power capacity input */}
        <div>
          <label
            htmlFor="inverterRatedPower"
            className="block text-sm font-medium text-gray-700"
          >
            {t("batteryOverview.InverterPowerCapacityKw")}
          </label>
          <input
            type="number"
            className="mt-1 block border-gray-200 rounded-md"
            min={0}
            value={assetInformation.inverterPowerCapacity ?? ""}
            onChange={(e) => onInverterCapacityChange(e.target.value)}
          />
          {inverterCapacityErrorMessage && (
            <p className="mt-2 text-sm text-red-600">
              {inverterCapacityErrorMessage}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={() => changeStep(1)}
          label={t("Back")}
          variant="black"
        />
        <ActionButton
          onClick={() => changeStep(3)}
          label={t("Next")}
          variant="primary"
          disabled={!isAssetInformationComplete(assetInformation, fuse)}
        />
      </div>
    </div>
  );
};

interface AssetInformationStepDropdownItem {
  //[LINT_TODO] This will fix with asset model configuration changes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  key: string;
  label: string;
}
interface AssetInformationStepDropdownProps {
  label: string;
  items: AssetInformationStepDropdownItem[];
  selectedItem: AssetInformationStepDropdownItem;
  onItemSelect: (item: AssetInformationStepDropdownItem) => void;
  isDisabled?: boolean;
}

const AssetInformationStepDropdown: FC<AssetInformationStepDropdownProps> = ({
  label,
  items,
  selectedItem,
  onItemSelect,
  isDisabled,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItem = items.find((item) => item.key === e.target.value);
    selectedItem = newItem ? newItem : selectedItem;
    onItemSelect(selectedItem);
  };
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <select
        className="block w-3/4 text-base text-gray-800 border-gray-500 focus:outline-none focus:ring-flowerblue-600 focus:border-flowerblue-600 sm:text-sm rounded-md disabled:text-gray-400 disabled:border-gray-200"
        value={selectedItem.key}
        onChange={handleChange}
        disabled={isDisabled}
      >
        {
          // We use key because you cannot have an object value in option
          items.map((item) => (
            <option key={item.key} value={item.key}>
              {item.label}
            </option>
          ))
        }
      </select>
    </div>
  );
};

// Static information display component
const StaticInfo: FC<{ label: string; value?: number; helpText: string }> = ({
  label,
  value,
  helpText,
}) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      type="text"
      className="border-gray-200 mt-1 block rounded-md "
      disabled
      value={value ?? "-"}
    />
    <div className="flex items-center mt-1">
      <InformationCircleIcon
        className="h-5 w-5 mr-1 text-blue-500"
        aria-hidden="true"
      />
      <p className="text-sm text-gray-600">{helpText}</p>
    </div>
  </div>
);
