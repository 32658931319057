import { FC } from "react";

type Props = {
  onClick: () => void;
  text: string;
  style: "primary" | "secondary";
};

export const SimpleButton: FC<Props> = ({ onClick, text, style }) => {
  const colors =
    style === "primary"
      ? "border border-transparent bg-flowerSky-600 hover:bg-flowerSky-700 focus:ring-flowerSky-600 text-white"
      : "border border-gray-300 bg-white hover:bg-gray-50 focus:ring-flowerblue-600 text-gray-700";
  const classNames =
    colors +
    " w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm mt-8";
  return (
    <button type={"button"} className={classNames} onClick={onClick}>
      {text}
    </button>
  );
};
