import { FC } from "react";

type ButtonProps = {
  label?: string;
  handleClick: () => void;
  isLoading?: boolean;
  type?: "button" | "submit";
  disabled?: boolean;
};
export const Button: FC<ButtonProps> = ({
  label = "Nästa",
  handleClick,
  isLoading = false,
  type = "button",
  disabled = false,
}) => (
  <button
    type={type}
    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-flowerSky-500 disabled:bg-gray-400 text-base font-medium text-white hover:bg-flowerSky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowerSky-300 sm:col-start-2 sm:text-sm mt-8 sm:mt-12"
    onClick={handleClick}
    disabled={disabled}
  >
    {isLoading && (
      <svg
        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    )}
    {label}
  </button>
);
