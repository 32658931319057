export enum StepStatus {
  Current = "current",
  Complete = "complete",
  Upcoming = "upcoming",
}

export enum FlowerHubInstallationProgressTitle {
  ConfigurationFailed = "Configuration failed",
  ConfiguringFlowerHub = "Configuring Flower Hub",
  ConfigurationStarting = "Configuration starting",
  ConfigurationPending = "Configuration pending",
  ConfigurationCompleted = "Configuration completed",
}

export enum FlowerHubInstallationStatusMessageCode {
  //Flower hub installation and requirement related message codes
  NotReceivedSignalFromFlowerHub = "NotReceivedSignalFromFlowerHub",
  FlowerHubCertificateProvisioningStarting = "FlowerHubCertificateProvisioningStarting",
  FlowerHubCertificateProvisioningFailed = "FlowerHubCertificateProvisioningFailed",
  FlowerHubWaitingForCertificateProvisioning = "FlowerHubWaitingForCertificateProvisioning",

  // Green grass 'thing' related message codes
  WaitingToCreateIoTThing = "WaitingToCreateIoTThing",
  IoTThingCreationFailed = "IoTThingCreationFailed",

  // Green grass 'core device' related message codes
  WaitingToCreateCoreDevice = "WaitingToCreateCoreDevice",
  CoreDeviceCreationFailed = "CoreDeviceCreationFailed",

  // Green grass 'deployments' related message codes
  WaitingToStartDeployment = "WaitingToStartDeployment",
  GreengrassDeploymentSucceeded = "GreengrassDeploymentSucceeded",
  GreengrassDeploymentInProgress = "GreengrassDeploymentInProgress",
  GreengrassDeploymentFailed = "GreengrassDeploymentFailed",
  GreengrassDeploymentUnknownStatus = "GreengrassDeploymentUnknownStatus",
  PendingDeploymentDueToMissingBiddingRegion = "PendingDeploymentDueToMissingBiddingRegion",
  UnableToFindGreengrassDeployment = "UnableToFindGreengrassDeployment",
  GreengrassDeploymentTimeout = "GreengrassDeploymentTimeout",

  FirstInstallationCompleted = "FirstInstallationCompleted",
}

export enum FlowerHubOperationalStatusMessageCode {
  GreengrassDeploymentInProgress = "GreengrassDeploymentInProgress",
  GreengrassDeploymentFailed = "GreengrassDeploymentFailed",
  GreengrassDeploymentUnknownStatus = "GreengrassDeploymentUnknownStatus",

  InverterDongleScanningAndComponentsAreRunning = "InverterDongleScanningAndComponentsAreRunning",
  InverterDongleScanningAndComponentsAreUnhealthy = "InverterDongleScanningAndComponentsAreUnhealthy",
  InverterDongleScanningAndComponentsAreBroken = "InverterDongleScanningAndComponentsAreBroken",

  InverterDongleFoundAndComponentsAreRunning = "InverterDongleFoundAndComponentsAreRunning",
  InverterDongleFoundAndComponentsAreUnhealthy = "InverterDongleFoundAndComponentsAreUnhealthy",
  InverterDongleFoundAndComponentsAreBroken = "InverterDongleFoundAndComponentsAreBroken",

  InverterDongleNotFoundAndComponentsAreRunning = "InverterDongleNotFoundAndComponentsAreRunning",
  InverterDongleNotFoundAndComponentsAreUnhealthy = "InverterDongleNotFoundAndComponentsAreUnhealthy",
  InverterDongleNotFoundAndComponentsAreBroken = "InverterDongleNotFoundAndComponentsAreBroken",
}

export enum FlowerHubStatus {
  Connecting = "Connecting",
  DeviceError = "Device error",
  Connected = "Connected",
  Unhealthy = "Unhealthy",
  ConnectionFailed = "Connection failed",
  Updating = "Updating",
  UpdateFailed = "Update failed",
  ConfigurationCompleted = "Configuration completed",
  ConfigurationFailed = "Configuration failed",
  Configuring = "Configuring",
  ConfigurationPending = "Configuration pending",
  Unknown = "Unknown",
}
