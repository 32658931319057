import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modal";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { CreateFlowerInvitationRequestDTO } from "api/flowerInvitation/flowerInvitation.dto";
import { useCreateFlowerInvitation } from "api/flowerInvitation/flowerInvitation.endpoints";
import { AddFlowerUserForm } from "./AddFlowerUserForm";
import SecondaryButton from "components/buttons/SecondaryButton";

export const AddFlowerUser = () => {
  const { t } = useTranslation();

  // state
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  // api
  const {
    mutate: inviteUser,
    isPending: isInvitingUser,
    isSuccess: isInviteUserSuccess,
  } = useCreateFlowerInvitation();

  // form
  const {
    register: registerInvitation,
    handleSubmit: handleInvitationSubmit,
    reset: resetInvitation,
    formState: { errors: invitationErrors },
  } = useForm<CreateFlowerInvitationRequestDTO>();

  // submit
  const onInvitationSubmit = handleInvitationSubmit(
    (data: CreateFlowerInvitationRequestDTO) => inviteUser(data),
  );

  // modal close
  const onInvitationModalClose = () => {
    setIsInviteUserModalOpen(false);
    setTimeout(() => resetInvitation(), 300);
  };

  // after submit success
  useEffect(() => {
    if (isInviteUserSuccess) {
      onInvitationModalClose();
    }
  }, [isInviteUserSuccess]);

  const renderAddFlowerUserButton = () => {
    return (
      <div className={`pb-2 flex justify-end`}>
        <SecondaryButton onClick={() => setIsInviteUserModalOpen(true)}>
          <UserPlusIcon className="h-5 w-5 mr-1" />
          {t(`flowerUser.Add flower user`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddFlowerUserButton()}

      <Modal
        isOpen={isInviteUserModalOpen}
        onClose={onInvitationModalClose}
        title={t(`flowerUser.Invite user`)}
        rightButtonText={t(`flowerUser.Send invitation`)}
        rightButtonActionHandler={onInvitationSubmit}
        isLoading={isInvitingUser}
        loadingText={t(`flowerUser.Sending invitation...`)}
      >
        <AddFlowerUserForm
          register={registerInvitation}
          errors={invitationErrors}
        />
      </Modal>
    </>
  );
};
