import { useNotification } from "hooks/useNotification";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../axiosInstance";
import { InstallerQueryKeys } from "api/installer/installer.endpoints";
import {
  CreateInstallerInvitationRequestDTO,
  CreateInstallerInvitationResponseDTO,
  UpdateInstallerInvitationRequestDTO,
  UpdateInstallerInvitationResponseDTO,
} from "./installerInvitation.dto";
import { SignupByCodeRequestDTO } from "../shared/signUpByCode.dto";
import { ErrorResponse } from "types/errorResponse.type";

const ENDPOINT = "/installer-invitation";

export const useInstallerSignUp = () => {
  return useMutation({
    mutationFn: async (payload: SignupByCodeRequestDTO): Promise<void> =>
      (await api.post(`${ENDPOINT}/sign-up-by-code`, payload)).data,
  });
};

export const useInviteInstallerUser = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateInstallerInvitationRequestDTO,
    ): Promise<CreateInstallerInvitationResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [InstallerQueryKeys.GetOne] });
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetUsers, data.installerId],
      });
      notification.info("Invitation sent");
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
    ...settings,
  });
};

export const useResendInvitation = (settings = {}) => {
  const notification = useNotification();

  return useMutation({
    mutationFn: async (oldInvitationId: number): Promise<void> =>
      (await api.post(`${ENDPOINT}/${oldInvitationId}`)).data,
    onError: () => {
      notification.error();
    },
    ...settings,
  });
};

export const useUpdateInstallerInvitation = (invitationId: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerInvitationRequestDTO,
    ): Promise<UpdateInstallerInvitationResponseDTO> =>
      (await api.put(`${ENDPOINT}/${invitationId}`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetUsers, data.installerId],
      });
      notification.info(`${data.name} updated`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useDeleteInstallerInvitation = (settings = {}) => {
  const notification = useNotification();
  return useMutation({
    mutationFn: async (id: number) => await api.delete(`${ENDPOINT}/${id}`),
    onError: () => {
      notification.error();
    },
    ...settings,
  });
};
