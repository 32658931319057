import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/date";
import { SiteForElectricityAgreementDTO } from "../../api/assetOwner/assetOwner.dto";
import { Item, Label, List } from "components/descriptionList";
import { ElectricityAgreementTag } from "../tags/ElectricityAgreementTag";
import CustomPlaceholder from "components/CustomPlaceholder";
import { useAssetOwnerElectricityAgreement } from "api/assetOwner/assetOwner.endpoints";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { LatestInvoices } from "../invoices/LatestInvoices";
import { Invoices } from "../invoices/Invoices";
import { Role } from "enums/role.enum";
import { EditZavannSiteIds } from "./EditZavannSiteIds";
import { isAssetOwner, isFlower } from "../../helpers/role";
import { Shadow } from "../Shadow";

interface Props {
  assetOwnerId: number;
  userRole: Role;
  className?: string;
}

export const ElectricityAgreement: FC<Props> = ({
  assetOwnerId,
  userRole,
  className = "",
}) => {
  const { t } = useTranslation();

  // Get electricity agreement
  const {
    data: electricityAgreement,
    isLoading: isLoadingElectricityAgreement,
    isError: isErrorElectricityAgreement,
  } = useAssetOwnerElectricityAgreement(assetOwnerId);

  const renderElectricityAgreementPlaceholder = () => (
    <div className="my-8">
      <CustomPlaceholder
        isError={isErrorElectricityAgreement}
        isLoading={isLoadingElectricityAgreement}
        isEmpty={
          !isLoadingElectricityAgreement &&
          !isErrorElectricityAgreement &&
          !electricityAgreement
        }
        icon={<DocumentTextIcon />}
        errorMessage={t(
          `electricityAgreement.UnableToFetchElectricityAgreement`,
        )}
        emptyDataMessage={t(`electricityAgreement.NoElectricityAgreement`)}
        loaderSize="medium"
      />
    </div>
  );

  if (
    isLoadingElectricityAgreement ||
    !electricityAgreement ||
    isErrorElectricityAgreement
  ) {
    return renderElectricityAgreementPlaceholder();
  }

  const shouldShowEditZavannSiteIdsOption = (): boolean => {
    return (
      isFlower(userRole) &&
      !!electricityAgreement?.consumption &&
      !!electricityAgreement?.production
    );
  };

  return (
    <div className={className}>
      <div>
        <Shadow>
          <List>
            {electricityAgreement.consumption && (
              <AgreementItem
                label={t(`electricityAgreement.Consumption`)}
                agreement={electricityAgreement.consumption}
                userRole={userRole}
              />
            )}
            {electricityAgreement.production && (
              <AgreementItem
                label={t(`electricityAgreement.Production`)}
                agreement={electricityAgreement.production}
                userRole={userRole}
              />
            )}
            {shouldShowEditZavannSiteIdsOption() && (
              <EditZavannSiteIds
                assetOwnerId={assetOwnerId}
                electricityAgreement={electricityAgreement}
              />
            )}
          </List>
        </Shadow>
      </div>
      {isFlower(userRole) && (
        <Invoices assetOwnerId={assetOwnerId} loaderSize="medium" />
      )}
      {isAssetOwner(userRole) && <LatestInvoices assetOwnerId={assetOwnerId} />}
    </div>
  );
};

interface AgreementItemProp {
  label: string;
  agreement: SiteForElectricityAgreementDTO;
  userRole: Role;
}

const AgreementItem = ({ label, agreement, userRole }: AgreementItemProp) => {
  return (
    <Item className="relative">
      <div className="mt-1 text-base sm:mt-0 text-gray-900 flex justify-between items-center">
        <Label>{label}</Label>
        {!isAssetOwner(userRole) && (
          <ElectricityAgreementTag
            stateCategory={agreement.stateCategory}
            stateId={agreement.stateId}
            stateReasonId={agreement.stateReasonId}
          />
        )}
      </div>
      <div
        className={
          isAssetOwner(userRole)
            ? "flex justify-start align-middle items-center text-sm"
            : "flex justify-end align-middle items-center text-sm"
        }
      >
        {isFlower(userRole) && (
          <>
            <span className="font-medium text-gray-500">Site Id: </span>
            <span className="ml-4">
              {agreement.siteId ? agreement.siteId : " - "}
            </span>
          </>
        )}
        {isAssetOwner(userRole) && (
          <ElectricityAgreementTag
            stateCategory={agreement.stateCategory}
            stateId={agreement.stateId}
            stateReasonId={agreement.stateReasonId}
          />
        )}
      </div>
      <div className="mt-1 text-base text-gray-900 sm:mt-0">
        {agreement.startDate && (
          <DateItem
            label="electricityAgreement.Start"
            date={agreement.startDate}
          />
        )}
        {agreement.terminationDate && (
          <DateItem
            label="electricityAgreement.End"
            date={agreement.terminationDate}
          />
        )}
      </div>
    </Item>
  );
};

interface DateItemProp {
  label: string;
  date: Date;
}

const DateItem = ({ label, date }: DateItemProp) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end align-middle items-center text-sm">
      <span className="font-medium text-gray-500">{`${t(label)}:`}</span>
      <span className="ml-4">{formatDate(date)}</span>
    </div>
  );
};
