import { UpdateAssetOwnerInvitationRequestDTO } from "api/assetOwner/assetOwner.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { TextInput } from "components/input/TextInput";
import { emailValidator, noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<UpdateAssetOwnerInvitationRequestDTO>;
  errors: Partial<FieldErrorsImpl<UpdateAssetOwnerInvitationRequestDTO>>;
}

export const EditAssetOwnerInvitationForm: FC<Props> = ({
  register,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`assetOwnerAccountDetails.Email`)}
        name="mainEmail"
        type="email"
        register={register}
        registerOptions={emailValidator}
        errorMessage={errors?.mainEmail?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.First name`)}
        name="firstName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.firstName?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.Last name`)}
        name="lastName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.lastName?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.Address`)}
        name="address.street"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.street?.message}
      />
      <PostalCodeInput
        label={t(`assetOwnerAccountDetails.Postal code`)}
        name="address.postalCode"
        register={register}
        errorMessage={errors.address?.postalCode?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.City`)}
        name="address.city"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.city?.message}
      />
    </ModalContentWrapper>
  );
};
