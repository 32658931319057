import { BillingAdjustmentStatistics } from "components/billingAdjustment/BillingAdjustmentStatistics";
import { currentInvoicedMonth } from "./BillingAdjustmentPage.helper";
import { QualifiedAssetOwnerList } from "components/billingAdjustment/QualifiedAssetOwnerList";
import { BreadCrumb, menuStore } from "../../../store/menu.store";
import { RouteLabel } from "../../../routing/routeLabel.enum";
import { RouteUrl } from "../../../routing/routeUrl.enum";
import { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper";

export default function BillingAdjustmentPage() {
  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, []);

  return (
    <PageWrapper>
      <div className="bg-gradient-to-r from-slate-50 flex items-center justify-between gap-x-6 px-6 py-2.5 sm:pr-3.5 lg:pl-8 ">
        <p className="text-base font-semibold text-flowerGray-800">
          {currentInvoicedMonth}
        </p>
      </div>
      <BillingAdjustmentStatistics />
      <QualifiedAssetOwnerList />
    </PageWrapper>
  );
}

const getBreadCrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Dashboard,
    to: `${RouteUrl.App}/${RouteUrl.BillingAdjustment}`,
  },
];
