import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { useInstallerSignUp } from "api/installerInvitation/installerInvitation.endpoints";
import { SignUp } from "components/SignUp";
import { SmoothUp } from "components/animations/SnapUp";
import { SignupByCodeRequestDTO } from "../../api/shared/signUpByCode.dto";
import { isPasswordStrongEnough } from "../../helpers/validation";

function SignUpInstallerPage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code") ?? "";
  const navigate = useNavigate();

  const {
    mutate: signUp,
    isPending: isLoading,
    isSuccess: isSignUpSuccess,
    isError,
  } = useInstallerSignUp();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SignupByCodeRequestDTO>();

  const password = watch("password");

  const onSubmit = handleSubmit((data: SignupByCodeRequestDTO) => {
    signUp({ ...data, code });
  });

  useEffect(() => {
    if (isSignUpSuccess) {
      navigate(RouteUrl.App);
    }
  }, [isSignUpSuccess]);

  return (
    <SmoothUp>
      <SignUp
        register={register}
        onSubmit={onSubmit}
        isLoading={isLoading}
        errors={errors}
        isError={isError}
        isPasswordValid={isPasswordStrongEnough(password)}
        installerTerms
      />
    </SmoothUp>
  );
}

export default SignUpInstallerPage;
