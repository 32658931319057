import React from "react";

interface TableColumn {
  label: string;
}

interface TableProps<T> {
  columns: TableColumn[];
  data: T[];
  renderRow: (item: T) => React.ReactNode;
}

const ContentTable = <T extends object>({
  columns,
  data,
  renderRow,
}: TableProps<T>) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full text-left table-auto min-w-max">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="p-4 border-b border-slate-300 bg-slate-50"
              >
                <p className="block text-sm font-normal leading-none text-slate-500">
                  {column.label}
                </p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="hover:bg-slate-50">
              {renderRow(item)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContentTable;
