import { GetAssetModelItemResponseDTO } from "api/assetModel/assetModel.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextArea } from "components/input/TextArea";
import { TextInput } from "components/input/TextInput";
import { noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<GetAssetModelItemResponseDTO>;
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      manufacturer: string;
      description: string;
    }>
  >;
}
export const EditAssetModelForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`assetModel.Model`)}
        name="name"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.name?.message}
      />
      <TextInput
        label={t(`assetModel.Manufacturer`)}
        name="manufacturer"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.manufacturer?.message}
      />
      <TextInput
        label={t(`assetModel.Compensation`)}
        name="compensation"
        type="number"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors?.manufacturer?.message}
      />
      <TextArea
        label={t(`assetModel.Description`)}
        name="description"
        register={register}
      />
    </ModalContentWrapper>
  );
};
