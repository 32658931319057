import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateInstallerRequestDTO,
  GetAllInstallerResponseDTO,
  GetInstallerResponseDTO,
  GetInstallersByUserRoleQueryParams,
  GetInstallerUsersResponseDTO,
  UpdateInstallerCanInviteAssetOwnersRequestDTO,
  UpdateInstallerRequestDTO,
  UpdateInstallerResponseDTO,
} from "./installer.dto";
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ErrorResponse } from "../../types/errorResponse.type";
import { fetchPages } from "../pagination/pagination.endpoint";
import qs from "qs";

const ENDPOINT = "/installer";

export enum InstallerQueryKeys {
  GetOne = "installer-get-one",
  GetAllPagination = "installer-get-all-pagination",
  GetUsers = "installer-get-installer-users",
}

export const useInstallersPagination = (
  queryString: string,
  optionalParams?: GetInstallersByUserRoleQueryParams,
) => {
  const { distributorId } = optionalParams || {};

  // Create optional query string
  const extraQueryString = qs.stringify({
    distributorId: distributorId ? distributorId : undefined,
  });

  return useInfiniteQuery({
    queryKey: [
      InstallerQueryKeys.GetAllPagination,
      { queryString, distributorId },
    ],
    queryFn: async ({ pageParam }): Promise<GetAllInstallerResponseDTO> =>
      (
        await fetchPages({
          url: `${ENDPOINT}`,
          pageParam,
          queryString,
          extraQueryString,
        })
      ).data,
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasNextPage ? pages.length : undefined,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    gcTime: 2000,
  });
};

export const useInstallerUsers = (id: number) =>
  useQuery({
    queryKey: [InstallerQueryKeys.GetUsers, id],
    queryFn: async (): Promise<GetInstallerUsersResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/user`)).data,
    initialData: { users: [], invitations: [] },
  });

export const useInstaller = (id?: number) =>
  useQuery({
    queryKey: [InstallerQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetInstallerResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    enabled: !!id,
  });

export const useCreateInstaller = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateInstallerRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetAllPagination],
      });
      notification.info(`${data.name} created`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useUpdateInstaller = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetOne, data.id],
      });
      notification.info(`${data.name} updated`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useUpdateInstallerCanInviteAssetOwners = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerCanInviteAssetOwnersRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}/canInviteAssetOwners`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetOne, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetAllPagination],
      });
      notification.info(`${data.name} updated`);
    },
    onError: () => {
      notification.error();
    },
  });
};
